import React, { ReactNode } from "react";
import { TableCell, Typography } from "@mui/material";

type TextCellProps = {
  children: ReactNode;
}

const TextCell = ({ children }: TextCellProps) => {
  return (
    <TableCell
      align="center"
      sx={{ maxWidth: "250px" }}
    >
      <Typography>{children}</Typography>
    </TableCell>
  );
};

export default TextCell;