import React, { Box, Container } from "@mui/material";
import ProgrammingDescPriceTool from "./ProgrammingDescPriceTool";
import Header from "../../../components/Header";

const index = () => {
  return (
    <Box m="20px">
      <Header
        title="PROGRAMMING DESCRIPTION & PRICING TOOL"
        subtitle="Generate a description and pricing for unique combinations of Applications Engineering programming services."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
          <ProgrammingDescPriceTool />
      </Container>
    </Box>
  );
};

export default index;
