import React, { useState } from "react";
import { LeadTime } from "./types";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { default as TableCell } from "../../components/StyledTableCell";
import StyledTextField from "../../components/StyledTextField";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import TextCell from "./Components/TextCell";
import StateChip from "./Components/StateChip";

function formatDate(dateString: string | null) {
  const options: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };
  const dateObj = dateString ? new Date(dateString) : new Date();
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObj);
  return `${formattedDate.split(", ")[0]}`;
}

function getStateChip(state: "normal" | "longer_than_usual") {
  switch (state) {
    case "normal":
      return <StateChip label="Normal" color="success" />;
    case "longer_than_usual":
      return <StateChip label="Longer Than Usual" color="warning" />;
    default:
      return <></>;
  }
}

type LeadTimesProps = {
  leadTimes: LeadTime[];
  configGranted: boolean;
  onSelectedLeadTime: (value: LeadTime | null | ((prevValue: LeadTime | null) => LeadTime)) => void;
}

const LeadTimes = ({leadTimes, configGranted, onSelectedLeadTime }: LeadTimesProps) => {
  const colors = useTheme().colors;
  const [filter, setFilter] = useState("");

  const handleAddNew = () => {
    onSelectedLeadTime({
      created_at: "",
      display_order: 0,
      id: 0,
      item_code: null,
      last_modified: null,
      lead_time: null,
      modified_by: "",
      name: null,
      notes: null,
      state: null,
      new: true,
      profile: { full_name: null }
    });
  };

  const filteredLeadTimes = leadTimes.filter((leadTime) => {
    const name = leadTime.name?.toLowerCase() ?? "";
    const itemCodes = leadTime.item_code?.toLowerCase() ?? "";
    const doesInclude =
      name.includes(filter.toLowerCase()) || itemCodes.includes(filter.toLowerCase());
    return doesInclude;
  });  

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Box>
          <StyledTextField
            size="small"
            label="Filter"
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TextCell>Name</TextCell>
              <TextCell>Item Code(s)</TextCell>
              <TextCell>Lead Time</TextCell>
              <TextCell>State</TextCell>
              <TextCell>Notes</TextCell>
              <TextCell>Modified By</TextCell>
              {configGranted && (
                <TextCell>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddNew}
                  >
                    Add
                  </Button>
                </TextCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeadTimes.map((leadTime) => (
              <TableRow key={leadTime.id}>
                <TextCell>{leadTime.name}</TextCell>
                <TextCell>{leadTime.item_code}</TextCell>
                <TextCell>{leadTime.lead_time}</TextCell>
                <TableCell>{getStateChip(leadTime.state as "normal" | "longer_than_usual")}</TableCell>
                <TextCell>{leadTime.notes}</TextCell>
                <TextCell>
                  {
                    `${leadTime.profile.full_name} - ${ leadTime.last_modified ? 
                    formatDate(leadTime.last_modified) : formatDate(leadTime.created_at)}`
                  }
                </TextCell>
                {configGranted && (
                  <TextCell>
                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      startIcon={<SettingsIcon />}
                      onClick={() => onSelectedLeadTime(leadTime)}
                    >
                      Edit
                    </Button>
                  </TextCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LeadTimes;
