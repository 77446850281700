import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Divider, MenuItem, Switch, Typography, useTheme } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Enums } from '../../../../../database/database.types';
import { FamilyRecord, LedTapeRecord } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import StyledNumberInput from '../../../../../components/StyledNumberInput';
import { NearestInt } from '../../utils';
import { SelectField, TextField } from '../../components/TextField';
import { DisplayAlert } from '../../types';

type BasicLedTapeDetailsProps = {
  record: LedTapeRecord;
  displayAlert: DisplayAlert;
  reloadData: () => Promise<void>;
}

const BasicLedTapeDetails = ({ record, displayAlert, reloadData }: BasicLedTapeDetailsProps) => {

  const colors = useTheme().colors;

  const [activeInput, setActiveInput] = useState<boolean>(record.active);
  const [publicAccessInput, setPublicAccessInput] = useState<boolean>(record.publicly_accessible);
  const [families, setFamilies] = useState<FamilyRecord[]>([]);
  const [familyInput, setFamilyInput] = useState<number>(record.family_id);
  const [nameInput, setNameInput] = useState<string>(record.name);
  const [locationInput, setLocationInput] = useState<Enums<'Location'>>(record.location);
  const [voltageInput, setVoltageInput] = useState<Enums<'Input Voltage'>>(record.input_voltage);
  const [conductorsInput, setConductorsInput] = useState<number>(record.conductors);
  const [forceNoDiodeImagingInput, setForceNoDiodeImagingInput] = useState<boolean>(record.force_no_diode_imaging);
  const [specSheetUrlInput, setSpecSheetUrlInput] = useState<string>(record.spec_sheet_url);

  const queryFamilies = useCallback(async () => {
    const { data, error } = await supabase_products.from('families').select('*').order('name', { ascending: true });
    if(error) console.error(error);
    setFamilies(data ?? []);
  }, []);

  useEffect(() => {
    if(families.length !== 0) return;
    queryFamilies();
  }, []);

  const updatedRecord: LedTapeRecord = {
    ...record,
    active: activeInput,
    publicly_accessible: publicAccessInput,
    family_id: familyInput,
    name: nameInput,
    location: locationInput,
    input_voltage: voltageInput,
    conductors: conductorsInput,
    force_no_diode_imaging: forceNoDiodeImagingInput,
    spec_sheet_url: specSheetUrlInput
  };

  const updatedKeys: (keyof LedTapeRecord)[] = [];
  let updated = false;

  for (const key in record) {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      // Type assertion to access the property values
      const value1 = record[key as keyof LedTapeRecord];
      const value2 = updatedRecord[key as keyof LedTapeRecord];
      if (value1 !== value2) {
        updatedKeys.push(key as keyof LedTapeRecord);
        updated = true;
      }
    }
  }

  const handleUpdateActive = (newValue: boolean) => {
    if(!newValue) setPublicAccessInput(false);
    setActiveInput(newValue);
  }

  const handleUpdateLocation = (newValue: string) => {
    if(newValue !== 'Indoor' && newValue !== 'Outdoor') return;
    setLocationInput(newValue);
  }

  const handleUpdateInputVoltage = (newValue: string) => {
    if(
      newValue !== '12V DC' &&
      newValue !== '24V DC' &&
      newValue !== '120V AC' &&
      newValue !== '277V AC'
    ) return;
    setVoltageInput(newValue);
  }

  const handleSave = async () => {
    if(!updated) return;
    const { error } = await supabase_products
    .from('led_tape').update(updatedRecord).eq('id', record.id);
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    reloadData().then(() => {
      displayAlert('Saved successfully!', 'success');
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Basic LED Tape Details</Typography>
        <Divider sx={{ marginBottom: '15px' }} />
        <Box display='flex' flexDirection='row' gap={2} mb={2}>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={activeInput} 
              onChange={(e) => handleUpdateActive(e.target.checked)}
            />
            {
              activeInput !== record.active ?
              <Typography sx={{ backgroundColor: colors.updatedValue.yellow }}>
                <b>Active</b>
              </Typography>
              :
              <Typography>Active</Typography>
            }
          </Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={publicAccessInput} 
              onChange={(e) => setPublicAccessInput(e.target.checked)}
              disabled={!activeInput}
            />
            {
              publicAccessInput !== record.publicly_accessible ?
              <Typography sx={{ backgroundColor: colors.updatedValue.yellow }}>
                <b>Publicly Accessible</b>
              </Typography>
              :
              <Typography>Publicly Accessible</Typography>
            }
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' gap={2} mb={2}>
          <SelectField 
            label='Family'
            value={families.length === 0 ? '' : familyInput}
            onChange={(e) => setFamilyInput(Number(e.target.value))}
            updated={updatedKeys.includes('family_id')}
            minWidth='100px'
          >
            {
              families.map(family => (
                <MenuItem key={family.id} value={family.id}>
                  {family.name}
                </MenuItem>
              ))
            }
          </SelectField>
          <TextField label='Slug' value={record.slug} updated={false} disabled />
          <TextField label='Type' value={record.led_type} updated={false} disabled />
        </Box>
        <Box display='flex' flexDirection='row' gap={2} mb={2}>
          <TextField 
            label='Name'
            value={nameInput} 
            onChange={(e) => setNameInput(e.target.value)}
            updated={updatedKeys.includes('name')}
            minWidth='300px'
          />
          <SelectField
            label='Location' 
            value={locationInput}
            onChange={(e) => handleUpdateLocation(e.target.value)}
            updated={updatedKeys.includes('location')}
            minWidth='100px'
          >
            <MenuItem value={'Indoor'}>Indoor</MenuItem>
            <MenuItem value={'Outdoor'}>Outdoor</MenuItem>
          </SelectField>
        </Box>
        <Box display='flex' flexDirection='row' gap={2} mb={2}>
          <SelectField 
            label='Input Voltage'
            value={voltageInput}
            onChange={(e) => handleUpdateInputVoltage(e.target.value)}
            updated={updatedKeys.includes('input_voltage')}
            minWidth='100px'
          >
            <MenuItem value={'12V DC'}>12V DC</MenuItem>
            <MenuItem value={'24V DC'}>24V DC</MenuItem>
            <MenuItem value={'120V AC'}>120V AC</MenuItem>
            <MenuItem value={'277V AC'}>277V AC</MenuItem>
          </SelectField>
          <StyledNumberInput 
            label='Conductors'
            value={conductorsInput} 
            type='number' 
            onChange={(e) => setConductorsInput(NearestInt(e.target.value))}
            sx={{ 
              maxWidth: '50px',
              '& .MuiInputBase-input': {
                backgroundColor: updatedKeys.includes('conductors') ? colors.updatedValue.yellow : ''
              }
            }}
            />
        </Box>
        <Box display='flex' flexDirection='row' ml={-1} mb={2} alignItems='flex-end'>
          <Switch
            checked={forceNoDiodeImagingInput} 
            onChange={(e) => setForceNoDiodeImagingInput(e.target.checked)}
          />
          {
            forceNoDiodeImagingInput !== record.force_no_diode_imaging ?
            <Typography sx={{ backgroundColor: colors.updatedValue.yellow }}>
              <b>Force No Diode Imaging</b>
            </Typography>
            :
            <Typography>Force No Diode Imaging</Typography>
          }
        </Box>
        <Box display='flex' flexDirection='row' gap={2} mb={2}>
          <TextField 
            label='Spec Sheet URL' 
            value={specSheetUrlInput}
            onChange={(e) => setSpecSheetUrlInput(e.target.value)}
            updated={updatedKeys.includes('spec_sheet_url')}
            minWidth='650px'
          />
        </Box>
        <Divider sx={{ marginY: '15px' }}/>
        <Button 
          color='secondary' 
          variant='contained' 
          startIcon={<SaveOutlinedIcon />}
          disabled={!updated}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

export default BasicLedTapeDetails;