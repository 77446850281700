import React, { useState } from 'react';
import { Dialog } from "@mui/material";
import NewLedTape from '../pages/MainView/forms/NewLedTape';
import NewChannel from '../pages/MainView/forms/NewChannel';
import NewChannelAssembly from '../pages/MainView/forms/NewChannelAssembly';

type addProductProps = {
  productType: 'LED Tape' | 'Channel Assembly' | 'Channel';
  onClose: () => void;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const AddProduct = ({ productType, onClose, displayAlert }: addProductProps) => {

  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="import-dialog-title"
      aria-describedby="import-dialog-description"
      maxWidth='md'
    >
      {
        productType === 'LED Tape' &&
        <NewLedTape onCancel={handleClose} displayAlert={displayAlert} />
      }
      {
        productType === 'Channel' &&
        <NewChannel onCancel={handleClose} displayAlert={displayAlert} />
      }
      {
        productType === 'Channel Assembly' &&
        <NewChannelAssembly onCancel={handleClose} displayAlert={displayAlert} />
      }
    </Dialog>
  );
};

export default AddProduct;