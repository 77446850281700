import { Chip } from "@mui/material";
import React from "react";

type EnabledChipProps = {
  enabled: boolean;
}

const EnabledChip = ({ enabled }: EnabledChipProps) => {
  return (
    <Chip
      label={enabled ? "Enabled" : "Disabled"}
      color={enabled ? "success" : "error"}
      variant={enabled ? "filled" : "outlined"}
      size="small"
    />
  );
};

export default EnabledChip;
