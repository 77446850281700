import { setQuerying, setStatus } from "../store/reducers/qbllSlice";
import { store } from "../store/store";
import { ItemSiteInventoryData, QBLLResponse, QBLLStatus, TxnInventoryData } from "./types";

const query = async <T,>(queryStr: string): Promise<QBLLResponse<T>> => {
  const fullQueryStr = `http://localhost:5207/api/${queryStr}`;
  store.dispatch(setQuerying({ querying: true, query: fullQueryStr }));
  setQueryState(true, fullQueryStr);
  try {
    const response = await fetch(fullQueryStr);
    const data = await response.json() as T;
    setQueryState(false, "");
    return { status: response.status, data: data, error: "" };
  } catch (error) {
    console.error("QBLL encountered an error while querying.");
    console.error(error);
    setQueryState(false, "");
    return { status: 500, data: null, error: JSON.stringify(error) };
  }
}

const Error_AlreadyQuerying = {
  status: 300,
  data: null,
  error: "Busy performing another query."
}

const isAlreadyQuerying = () => store.getState().qbll.querying;

const setQueryState = (querying: boolean, queryStr: string) => {
  store.dispatch(setQuerying({ querying: querying, query: queryStr }));
}

export const queryTxnInventoryData = async (txnType: "salesorder" | "quote", refNumber: string, includeSites: boolean): Promise<QBLLResponse<TxnInventoryData>> => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = `${txnType}/${refNumber}?includeLineItems=true&checkInventory=true&inventorySites=${includeSites}`;
  const response = await query<TxnInventoryData>(queryStr);
  return response;
}

export const queryItemSiteInventory = async(itemListId: string): Promise<QBLLResponse<ItemSiteInventoryData[]>> => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = `inventory/bysite?itemListId=${itemListId}`;
  const response = await query<ItemSiteInventoryData[]>(queryStr);
  return response;
}

export const refreshStatus = async () => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = "status";
  const response = await query<QBLLStatus>(queryStr);
  const badStatus: QBLLStatus = {
    connection: "Bad",
    version: null,
    appName: null,
    qbFileName: null,
    errorMsg: response.error
  };
  store.dispatch(setStatus(response.data ?? badStatus));
}
