import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tokens } from "../theme";

const ButtonCTA = styled(Button)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  return {
    backgroundColor:
      theme.palette.mode === "dark"
        ? colors.greenAccent[700]
        : colors.blueAccent[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? colors.greenAccent[500]
          : colors.blueAccent[500],
    },
  };
});

export const LoginButton = styled(ButtonCTA)(() => {
  return {
    margin: "10px",
    width: "250px",
  };
});

export default ButtonCTA;
