import React, { useState } from "react";
import { TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import CurrencyFormatter from "../../../global_functions/CurrencyFormatter";
import { default as TableCell } from "../../../components/StyledTableCell";
import { BaseComboRecord } from "../../../database/AppsDescGenToolTypes";

type BaseComboComponentProps = {
  baseCombo: BaseComboRecord | undefined;
}

const BaseComboComponent = ({ baseCombo }: BaseComboComponentProps) => {
  const colors = useTheme().colors;

  const [copiedDesc, setCopiedDesc] = useState(false);

  const price = baseCombo ? CurrencyFormatter(baseCombo.price) : 0;

  const handleOnClickDesc = () => {
    navigator.clipboard.writeText(baseCombo?.description ?? '');
    setCopiedDesc(true);
  };
  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography>Base</Typography>
      </TableCell>
      <TableCell>
        <Typography>{baseCombo?.name}</Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography>${price}</Typography>
      </TableCell>
      <TableCell>
        <Typography>${price}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          title={copiedDesc ? "Copied!" : "Click to copy"}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: {
              enter: 800,
              exit: 0,
            },
          }}
          onClose={handleOnCloseCopyTooltip}
        >
          <Typography
            onClick={handleOnClickDesc}
            whiteSpace="pre-line"
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: colors.blueAccent[500],
              },
            }}
          >
            {baseCombo?.description}
          </Typography>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default BaseComboComponent;
