import { ProfileRecord } from "./PublicTypes";
import { supabase } from "./supabaseClient";
import { queryPermissions } from "./Permissions";

export type Profile = ProfileRecord & {
  permissions: string[];
}

export async function queryProfile(userId: string): Promise<Profile | null>{
  const { data, error } = await supabase.from("profiles").select("*").eq("id", userId).single();
  if(!data){
    console.error(error);
    return data;
  }
  const permissions = await queryPermissions(userId);
  const profile = { ...data, permissions: permissions };
  return profile;
}