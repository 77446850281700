import { ChannelFinishRecord, ChannelLensRecord, ChannelMountingRecord, QbItemChannelAssemblyConflicts, QbItemChannelAssemblyDependencies } from "../../../database/Products";
import { supabase_products } from "../../../database/supabaseClient";
import { EnsureSingle } from "../../../database/Utils";


export const SelectLenses = async (channelAssemblyId: number): Promise<ChannelLensRecord[]> => {
  const { data, error } = await supabase_products
  .from('channel_lenses')
  .select('*')
  .eq('channel_assembly_id', channelAssemblyId)
  .order('display_order', { ascending: true });
  if(error) console.error(error);
  return data ?? [];
};

export const UpdateLensDisplayOrder = async (values: Pick<ChannelLensRecord, 'id' | 'display_order'>[]) => {
  try {
    const updatePromises = values.map(async (value) => {
      const { data, error } = await supabase_products
        .from('channel_lenses')
        .update({ display_order: value.display_order })
        .eq('id', value.id)
        .select('*').single();  
      if (error){
        console.error(`Error updating record ID ${value.id}:`, error);
        throw error;
      }
      return data;
    });
    await Promise.all(updatePromises);
    return true;
  } catch (error) {
    console.error('Error updating records:', error);
    return false;
  }
};

export const InsertLens = async (newRecord: NewValueRecord) => {
  const response = await supabase_products
  .from('channel_lenses')
  .insert({...newRecord})
  .select('*').single();
  return response;
}

export const UpdateLens = async (record: Pick<ValueRecord, 'id' | 'name' | 'sku_code' | 'easy_spec_code'>) => {
  const response = await supabase_products
  .from('channel_lenses')
  .update({
    name: record.name,
    sku_code: record.sku_code,
    easy_spec_code: record.easy_spec_code
  })
  .eq('id', record.id)
  .select('*').single();
  return response;
}

export const SelectMountings = async (channelAssemblyId: number): Promise<ChannelMountingRecord[]> => {
  const { data, error } = await supabase_products
  .from('channel_mountings')
  .select('*')
  .eq('channel_assembly_id', channelAssemblyId)
  .order('display_order', { ascending: true });
  if(error) console.error(error);
  return data ?? [];
};

export const UpdateMountingDisplayOrder = async (values: Pick<ChannelMountingRecord, 'id' | 'display_order'>[]) => {
  try {
    const updatePromises = values.map(async (value) => {
      const { data, error } = await supabase_products
        .from('channel_mountings')
        .update({ display_order: value.display_order })
        .eq('id', value.id)
        .select('*').single();  
      if (error){
        console.error(`Error updating record ID ${value.id}:`, error);
        throw error;
      }
      return data;
    });
    await Promise.all(updatePromises);
    return true;
  } catch (error) {
    console.error('Error updating records:', error);
    return false;
  }
};

export const InsertMounting = async (newRecord: NewValueRecord) => {
  const response = await supabase_products
  .from('channel_mountings')
  .insert({...newRecord})
  .select('*').single();
  return response;
}

export const UpdateMounting = async (record: Pick<ValueRecord, 'id' | 'name' | 'sku_code' | 'easy_spec_code'>) => {
  const response = await supabase_products
  .from('channel_mountings')
  .update({
    name: record.name,
    sku_code: record.sku_code,
    easy_spec_code: record.easy_spec_code
  })
  .eq('id', record.id)
  .select('*').single();
  return response;
}

export const SelectFinishes = async (channelAssemblyId: number): Promise<ChannelFinishRecord[]> => {
  const { data, error } = await supabase_products
  .from('channel_finishes')
  .select('*')
  .eq('channel_assembly_id', channelAssemblyId)
  .order('display_order', { ascending: true });
  if(error) console.error(error);
  return data ?? [];
};

export const UpdateFinishDisplayOrder = async (values: Pick<ChannelFinishRecord, 'id' | 'display_order'>[]) => {
  try {
    const updatePromises = values.map(async (value) => {
      const { data, error } = await supabase_products
        .from('channel_finishes')
        .update({ display_order: value.display_order })
        .eq('id', value.id)
        .select('*').single();  
      if (error){
        console.error(`Error updating record ID ${value.id}:`, error);
        throw error;
      }
      return data;
    });
    await Promise.all(updatePromises);
    return true;
  } catch (error) {
    console.error('Error updating records:', error);
    return false;
  }
};

export const InsertFinish = async (newRecord: NewValueRecord) => {
  const response = await supabase_products
  .from('channel_finishes')
  .insert({...newRecord})
  .select('*').single();
  return response;
}

export const UpdateFinish = async (record: Pick<ValueRecord, 'id' | 'name' | 'sku_code' | 'easy_spec_code'>) => {
  const response = await supabase_products
  .from('channel_finishes')
  .update({
    name: record.name,
    sku_code: record.sku_code,
    easy_spec_code: record.easy_spec_code
  })
  .eq('id', record.id)
  .select('*').single();
  return response;
}

export const SaveQbRelChannel = async (quantity: number, listId: string, channelId: number) => {
  const updatedRecord = { quantity: quantity };
  const { error } = await supabase_products
  .from('rel_qb_items_channel')
  .update({...updatedRecord})
  .eq('qb_item_list_id', listId)
  .eq('channel_id', channelId);
  if(error) console.error(error);
  return error;
};

export const DeleteQbRelChannel = async (listId: string, channelId: number) => {
  const { error } = await supabase_products
  .from('rel_qb_items_channel')
  .delete()
  .eq('qb_item_list_id', listId)
  .eq('channel_id', channelId);
  if(error) console.error(error);
  return error;
};

export const SaveQbRelLens = async (quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string, lensId: number) => {
  const updatedRecord = { quantity: quantity, dependencies: dependencies, conflicts: conflicts };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_lenses')
  .update({...updatedRecord})
  .eq('qb_item_list_id', listId)
  .eq('channel_lens_id', lensId);
  if(error) console.error(error);
  return error;
};

export const DeleteQbRelLens = async (listId: string, lensId: number) => {
  const { error } = await supabase_products
  .from('rel_qb_items_channel_lenses')
  .delete()
  .eq('qb_item_list_id', listId)
  .eq('channel_lens_id', lensId);
  if(error) console.error(error);
  return error;
};

export const SaveQbRelMounts = async (quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string, mountId: number) => {
  const updatedRecord = { quantity: quantity, dependencies: dependencies, conflicts: conflicts };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_mountings')
  .update({...updatedRecord})
  .eq('qb_item_list_id', listId)
  .eq('channel_mounting_id', mountId);
  if(error) console.error(error);
  return error;
};

export const DeleteQbRelMounts = async (listId: string, mountId: number) => {
  const { error } = await supabase_products
  .from('rel_qb_items_channel_mountings')
  .delete()
  .eq('qb_item_list_id', listId)
  .eq('channel_mounting_id', mountId);
  if(error) console.error(error);
  return error;
};

export const SaveQbRelFinish = async (quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string, finishId: number) => {
  const updatedRecord = { quantity: quantity, dependencies: dependencies, conflicts: conflicts };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_finishes')
  .update({...updatedRecord})
  .eq('qb_item_list_id', listId)
  .eq('channel_finish_id', finishId);
  if(error) console.error(error);
  return error;
};

export const DeleteQbRelFinish = async (listId: string, finishId: number) => {
  const { error } = await supabase_products
  .from('rel_qb_items_channel_finishes')
  .delete()
  .eq('qb_item_list_id', listId)
  .eq('channel_finish_id', finishId);
  if(error) console.error(error);
  return error;
};

export type QbRelData = {
  quantity: number;
  conflicts: QbItemChannelAssemblyConflicts;
  dependencies: QbItemChannelAssemblyDependencies;
  qbItem: {
      full_name: string;
      list_id: string;
  } | null;
}[];

export const GetQbRelChannel = async (channelId: number): Promise<QbRelData> => {
  const response = await supabase_products
    .from('rel_qb_items_channel')
    .select('quantity, qbItem:qb_items(*)')
    .eq('channel_id', channelId);
    if(response.error) console.error(response.error);
    const data = response.data ? 
      response.data.map(d => ({
        quantity: d.quantity,
        qbItem: EnsureSingle(d.qbItem),
        conflicts: null,
        dependencies: null,
      }))
      : [];
    return data;
}

export const CreateQbRelChannel = async (listId: string, channelId: number) => {
  const newRecord = {
    qb_item_list_id: listId,
    channel_id: channelId
  };
  const { error } = await supabase_products
  .from('rel_qb_items_channel')
  .insert({...newRecord});
  return error;
}

export const GetQbRelLens = async (lensId: number): Promise<QbRelData> => {
  const response = await supabase_products
    .from('rel_qb_items_channel_lenses')
    .select('quantity, dependencies, conflicts, qbItem:qb_items(*)')
    .eq('channel_lens_id', lensId)
    .order('id');
    if(response.error) console.error(response.error);
    const data = response.data ? 
      response.data.map(d => ({
        quantity: d.quantity,
        qbItem: EnsureSingle(d.qbItem),
        conflicts: d.conflicts as QbItemChannelAssemblyConflicts,
        dependencies: d.dependencies as QbItemChannelAssemblyDependencies,
      }))
      : [];
    return data;
}

export const CreateQbRelLens = async (listId: string, lensId: number) => {
  const newRecord = {
    qb_item_list_id: listId,
    channel_lens_id: lensId,
    quantity: 1
  };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_lenses')
  .insert({...newRecord});
  return error;
}

export const GetQbRelMount = async (mountId: number): Promise<QbRelData> => {
  const response = await supabase_products
    .from('rel_qb_items_channel_mountings')
    .select('quantity, dependencies, conflicts, qbItem:qb_items(*)')
    .eq('channel_mounting_id', mountId)
    .order('id');
  if(response.error) console.error(response.error);
  const data = response.data ? 
    response.data.map(d => ({
      quantity: d.quantity,
      qbItem: EnsureSingle(d.qbItem),
      conflicts: d.conflicts as QbItemChannelAssemblyConflicts,
      dependencies: d.dependencies as QbItemChannelAssemblyDependencies,
    }))
    : [];
  return data;
}

export const CreateQbRelMount = async (listId: string, mountId: number) => {
  const newRecord = {
    qb_item_list_id: listId,
    channel_mounting_id: mountId
  };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_mountings')
  .insert({...newRecord});
  return error;
}

export const GetQbRelFinish = async (finishId: number) => {
  const response = await supabase_products
    .from('rel_qb_items_channel_finishes')
    .select('quantity, dependencies, conflicts, qbItem:qb_items(*)')
    .eq('channel_finish_id', finishId)
    .order('id');
    if(response.error) console.error(response.error);
    const data = response.data ? 
      response.data.map(d => ({
        quantity: d.quantity,
        qbItem: EnsureSingle(d.qbItem),
        conflicts: d.conflicts as QbItemChannelAssemblyConflicts,
        dependencies: d.dependencies as QbItemChannelAssemblyDependencies,
      }))
      : [];
    return data;
}

export const CreateQbRelFinish = async (listId: string, finishId: number) => {
  const newRecord = {
    qb_item_list_id: listId,
    channel_finish_id: finishId
  };
  const { error } = await supabase_products
  .from('rel_qb_items_channel_finishes')
  .insert({...newRecord});
  return error;
}
