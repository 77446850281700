import { ChannelRecord } from "../../../database/Products";
import { EnsureSingle } from "../../../database/Utils";
import { supabase_products } from "../../../database/supabaseClient";
import { ChannelAssembly } from "./types";

export const getChannelData = async (): Promise<ChannelAssembly[]> => {
  const { data, error } = await supabase_products.from("channel_assemblies")
    .select("*, channel(*)")
    .eq('active', true)
    .order("name", { ascending: true });
  if(error) console.error(error);
  const transData = !data ? [] : data.map(d => {
    const channel = EnsureSingle(d.channel) as NonNullable<ChannelRecord>;
    return {
      ...d,
      channel: channel
    }
  });
  return transData ?? [];
}