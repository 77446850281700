import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import ConflictsTooltip from "./ConflictsTooltip";

export type Content = {
  headers: {
    attributeLabel: string;
    valuesLabel: string;
    align: 'left' | 'right' | 'center';
  };
  attributes: Attribute[];
}
export type Attribute = {
  name: string;
  label: string;
  values: { 
    highlight?: boolean;
    name: string;
    code: string;
    disabled?: boolean;
    disabledReason?: string;
  }[];
}

type AttributtesListProps = {
  content: Content;
  clickableValues?: boolean;
  onClickValue?: (attributeName: string, value: string) => void;
}

const AttributesList = ({ content, clickableValues, onClickValue }: AttributtesListProps) => {
  const colors = useTheme().colors;
  
  return (
    <Table size="small">
      <TableHead sx={{ background: colors.primary[400]}}>
        <TableRow>
          <TableCell align={content.headers.align}>
            <Typography><b>{content.headers.attributeLabel}</b></Typography>
          </TableCell>
          <TableCell align={content.headers.align}>
            <Typography><b>{content.headers.valuesLabel}</b></Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          content.attributes.map((attribute, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell sx={{ verticalAlign: "top" }}>
                <Typography>{attribute.label}</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                <Box maxWidth="300px" display="flex" flexDirection="column">
                  {
                    attribute.values.map((value, valueIndex) => (
                      <Box 
                        key={value.name + valueIndex} 
                        width="100%" 
                        display="flex" 
                        flexDirection="row" 
                        justifyContent="space-between"
                        sx={{
                          backgroundColor: value.highlight ? colors.greenAccent[800] : 'inherit',
                          paddingX: '5px',
                          '&:hover': clickableValues && !value.disabled ? {
                            color: colors.blueAccent[500],
                            cursor: 'pointer'
                          } : {}
                        }}
                        onClick={() => onClickValue && !value.disabled ? onClickValue(attribute.name, value.code) : {}}
                      >
                        {
                          value.disabled &&
                          <>
                            <ConflictsTooltip placement="right" title={
                              <Typography color='red'><b>{value.disabledReason ?? ''}</b></Typography>
                            }>
                              <Typography color={colors.grey[700]}>{value.name}</Typography>
                            </ConflictsTooltip>
                            <ConflictsTooltip placement="right" title={
                              <Typography color='red'><b>{value.disabledReason ?? ''}</b></Typography>
                            }>
                              <Typography color={colors.grey[700]}>{value.code}</Typography>
                            </ConflictsTooltip>
                          </>
                        }
                        {
                          !value.disabled &&
                          <>
                            <Typography>{value.name}</Typography>
                            <Typography>{value.code}</Typography>
                          </>
                        }
                      </Box>
                    ))
                  }
                </Box>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

export default AttributesList;