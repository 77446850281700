import { createClient } from "@supabase/supabase-js";
import { Database } from "./database.types";

const supabaseUrl: string = process.env.REACT_APP_WEB_TOOLS_SUPABASE_URL as string;
const supabaseAnonKey: string = process.env.REACT_APP_WEB_TOOLS_SUPABASE_ANON_KEY as string;

export const supabase = createClient<Database, "public">(supabaseUrl, supabaseAnonKey);
export const supabase_apps = createClient<Database, "apps_desc_gen_tool">(supabaseUrl, supabaseAnonKey, {
  db: {
    schema: "apps_desc_gen_tool"
  }
});
export const supabase_products = createClient<Database, "products">(supabaseUrl, supabaseAnonKey, {
  db: {
    schema: "products",
  }
});
