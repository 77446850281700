import React, { useState } from "react";
import { Box, FormControlLabel, Switch, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { default as TextInput } from "./ConfigTextInput";
import DataTable from "../../../../components/DataTable";
import ConfigServiceComponentValueForm, { ConfigServiceComponentValueFormProps } from "./ConfigServiceComponentValueForm";
import { ServiceComponentObj, insertRecord, updateRecord } from "../data";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { ControllerTypeRecord, ProjectTypeRecord, ServiceComponentRecord, ServiceComponentValueRecord } from "../../../../database/AppsDescGenToolTypes";

export type ConfigServiceComponentFormProps = {
  record: ServiceComponentObj;
  isNew: boolean;
  onCancel: () => void;
  onSave: (record: ServiceComponentRecord, updateDatabase: () => Promise<PostgrestSingleResponse<null> | undefined>) => Promise<void>;
  reloadData: () => Promise<void>;
  projectTypes: ProjectTypeRecord[];
  controllerTypes: ControllerTypeRecord[];
}

const ConfigServiceComponentForm = ({
  record: recordData,
  onCancel,
  onSave,
  reloadData,
  projectTypes,
  controllerTypes
}: ConfigServiceComponentFormProps) => {
  const cleanedRecordData = { ...recordData };
  const [record, setRecord] = useState<ServiceComponentObj>(cleanedRecordData);
  const [modified, setModified] = useState<boolean>(false);

  const isNew = Object.keys(recordData).length === 0;

  function updateField(fieldName: keyof(ServiceComponentObj), fieldValue: string | number | boolean) {
    const newRecord = {...record, [fieldName]: fieldValue};
    setRecord(newRecord);
    setModified(true);
  }

  const handleInsert = async () => {
    const { service_component_values:_ , ...newRecord } = record;
    return insertRecord(newRecord, "service_components");
  }

  const handleUpdate = async () => {
    const { service_component_values:_, ...updatedRecord } = record;
    return updateRecord(updatedRecord, "service_components");
  }

  return (
    <Box>
      <Box sx={{ m: "25px 0", width: "50%" }}>
        <Box marginBottom="20px" sx={{ "& > *": { marginRight: "10px" } }}>
          <Button
            variant="outlined"
            color="info"
            startIcon={<ArrowBackOutlinedIcon />}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveOutlinedIcon />}
            disabled={!modified}
            onClick={() => onSave(record, isNew ? handleInsert : handleUpdate)}
          >
            Save
          </Button>
        </Box>

        {record !== null && (
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Switch
                  checked={record.active ? true : false}
                  color="secondary"
                />
              }
              label={record.active ? "Enabled" : "Disabled"}
              onChange={(e, checked) => updateField("active", checked)}
              sx={{ marginBottom: "15px" }}
            />
            <TextInput
              label="Name"
              value={record.name}
              fieldName={"name"}
              updateValue={updateField}
            />
            <TextInput
              label="Default Price"
              value={record.default_price}
              fieldName={"default_price"}
              updateValue={updateField}
            />
            <TextInput
              label="Default Description"
              value={record.default_description ?? ""}
              fieldName={"default_description"}
              updateValue={updateField}
              helperText="Dynamic Values Example: {MULTIPLY_QUANTITY(n)}"
            />
            <TextInput
              label="Display Index"
              value={record.display_index}
              fieldName={"display_index"}
              updateValue={updateField}
            />
          </Box>
        )}
      </Box>
      {!isNew && (
        <DataTable<ServiceComponentValueRecord>
          title="VALUES"
          records={recordData?.service_component_values}
          idInjections={{ service_component_id: recordData.id }}
          displayColumns={[
            {
              display: "Is Default",
              field: "default",
              options: {
                showIfTrue: true,
                valueIfTrue: "Default",
                displayAsChip: true,
                colorIfTrue: "info",
              },
            },
            { display: "Name", field: "name" },
            { display: "Price", field: "price" },
            { display: "Description", field: "description" },
          ]}
          configForm={(customProps: ConfigServiceComponentValueFormProps) => (
            <ConfigServiceComponentValueForm {...customProps} projectTypes={projectTypes} controllerTypes={controllerTypes} />
          )}
          cancelConfig={onCancel}
          reloadData={reloadData}
          resetView={false}
        />
      )}
    </Box>
  );
};

export default ConfigServiceComponentForm;
