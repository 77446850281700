import React from "react";
import { TableCell, Typography } from "@mui/material";

type CellProps = {
  children: React.ReactNode;
}

const Cell = ({ children }: CellProps) => {
  return (
    <TableCell>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

export default Cell;
