import React from "react";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { LedTape, LedTapeOptionType, OptionValueWithConflicts } from "../../../../database/Products";
import { Mm2In } from "../../../../global_functions/UomConversion";
import AttributesList, { Content } from "../../../../components/AttributesList";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ProductImage from "./ProductImage";

type LedTapeSpecsProps = {
  ledTape: LedTape;
  options: OptionValueWithConflicts[];
  conflicts: { valueId: number, reason: string }[];
  productImage: string;
  altText: string;
  onChangeOption: (option: LedTapeOptionType, ezCode: string) => void;
}

const LedTapeSpecs = ( { ledTape, options, conflicts, productImage, altText, onChangeOption }: LedTapeSpecsProps) => {
  options = options.sort((a, b) => a.display_order - b.display_order);
  const optionsList: Content = {
    headers: {
      attributeLabel: 'Option',
      valuesLabel: 'Values (Label / Easy Spec Code)',
      align: 'left'
    },
    attributes: [
      {
        name: 'Color',
        label: 'Colors',
        values: 
          options.filter(opt => opt.type === 'Color')?.map(color => { 
            const colorConflicts = conflicts.find(c => c.valueId === color.id);
            return {
              name: color.display_name,
              highlight: ledTape.color?.value === color.value,
              code: color.easy_spec_code,
              disabled: !!colorConflicts,
              disabledReason: colorConflicts?.reason
            }
          }) ?? []        
      },
      {
        name: 'SDCM',
        label: 'SDCM',
        values: 
          options.filter(opt => opt.type === 'SDCM')?.map(sdcm => { 
            const sdcmConflicts = conflicts.find(c => c.valueId === sdcm.id);
            return {
              name: sdcm.display_name,
              highlight: ledTape.sdcm?.value === sdcm.value,
              code: sdcm.easy_spec_code,
              disabled: !!sdcmConflicts,
              disabledReason: sdcmConflicts?.reason
            }
          }) ?? []        
      },
      {
        name: 'Lumens',
        label: 'Lumens',
        values: 
          options.filter(opt => opt.type === 'Lumens')?.map(lumens => { 
            const lumensConflicts = conflicts.find(c => c.valueId === lumens.id);
            return {
              name: lumens.display_name,
              highlight: ledTape.lumens?.value === lumens.value,
              code: lumens.easy_spec_code,
              disabled: !!lumensConflicts,
              disabledReason: lumensConflicts?.reason
            }
          }) ?? []        
      },
      {
        name: 'Kelvin Temp',
        label: 'Kelvin Temps',
        values: 
          options.filter(opt => opt.type === 'Kelvin Temp')?.map(temp => { 
            const tempConflicts = conflicts.find(c => c.valueId === temp.id);
            return {
              name: temp.display_name,
              highlight: ledTape.kelvinTemp?.value === temp.value,
              code: temp.easy_spec_code,
              disabled: !!tempConflicts,
              disabledReason: tempConflicts?.reason
            }
          }) ?? []        
      },
    ]
  }

  optionsList.attributes = optionsList.attributes.filter(attr => attr.values.length > 0);
  const specs = ledTape.specs;
  
  return (
    <Card
      sx={{ 
        padding: '15px',
        width: '100%',
        maxWidth: '450px'
      }}
    >
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Typography variant="h5">
            {ledTape.name}
          </Typography>
          <Typography mb={2} fontSize={12}>
            LED Tape Light - {ledTape.led_type}
          </Typography>
        </Box>
        <Box>
          <ProductImage 
            fileName={productImage}
            altText={altText}
            height={50}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography>Base Specs</Typography>
        <Button 
          variant="text"
          endIcon={<OpenInNewOutlinedIcon />} 
          target="_blank" 
          disabled={ledTape.spec_sheet_url === null}
          href={ledTape.spec_sheet_url ?? ''}
        >
          Spec Sheet
        </Button>
      </Box>
      <Divider />
      <Box mt={1} mb={2} px={2}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography>Conductors</Typography>
          <Typography>{ledTape.conductors}</Typography>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography>Input Voltage</Typography>
          <Typography>{ledTape.input_voltage}V</Typography>
        </Box>
      </Box>
      <Typography>Specs for selected options: </Typography>
      <Divider />
      <Box mt={1} mb={2} px={2}>
        {
          ledTape.sdcm &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>SDCM</Typography>
            <Typography>{ledTape.sdcm.display_name}</Typography>
          </Box>
        }
        {
          ledTape.color &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Color</Typography>
            <Typography>{ledTape.color.display_name}</Typography>
          </Box>
        }
        {
          specs.wavelength_low_nm && specs.wavelength_high_nm &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Wavelength</Typography>
            <Typography>{specs.wavelength_low_nm} - {specs.wavelength_high_nm} nm</Typography>
          </Box>
        }
        {
          ledTape.kelvinTemp &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Kelvin Temp</Typography>
            <Typography>{ledTape.kelvinTemp.display_name}</Typography>
          </Box>
        }
        {
          specs.lumens_per_ft &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Lumens</Typography>
            <Typography>{ledTape.specs.lumens_per_ft} lm/ft</Typography>
          </Box>
        }
        {
          ledTape.specs.width_mm &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Width</Typography>
            <Typography>{ledTape.specs.width_mm} mm</Typography>
          </Box>
        }
        {
          ledTape.specs.height_mm &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Height</Typography>
            <Typography>{ledTape.specs.height_mm} mm</Typography>
          </Box>
        }
        {
          specs.max_run_ft &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Max Run</Typography>
            <Typography>{specs.max_run_ft} FT</Typography>
          </Box>
        }
        {
          specs.max_cut_intervals &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Max Cut Intervals</Typography>
            <Typography>{ledTape.specs.max_cut_intervals}</Typography>
          </Box>
        }
        {
          ledTape.specs.cut_interval_mm &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Cut Internval</Typography>
            <Typography>{ledTape.specs.cut_interval_mm} mm ({Mm2In(ledTape.specs.cut_interval_mm ?? 0)}")</Typography>
          </Box>
        }
        {
          specs.watts_per_ft &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Watts</Typography>
            <Typography>{specs.watts_per_ft} w/ft</Typography>
          </Box>
        }
        {
          specs.efficacy &&
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography>Efficacy</Typography>
            <Typography>{specs.efficacy} lm/W</Typography>
          </Box>
        }
      </Box>
      <AttributesList 
        content={optionsList}
        clickableValues={true}
        onClickValue={(attributeName: string, value: string) => onChangeOption(attributeName as LedTapeOptionType, value)}
      />
    </Card>
  );
};

export default LedTapeSpecs;