export const Ft2Mm = (feet: number) => {
  return In2Mm(feet * 12);
};

export const Ft2In = (feet: number) => {
  return Number((feet * 12));
};

export const In2Ft = (inches: number) => {
  return Number((inches / 12));
};

export const In2Mm = (inches: number) => {
  return Number((inches * 25.4));
}

export const Mm2In = (mm: number) => {
  return Number((mm / 25.4));
}

export const Mm2Ft = (mm: number) => {
  const inches = (mm / 25.4);
  return In2Ft(inches);
}

export const Mm2M = (mm: number) => {
  return (mm / 1000);
}

export const GetUomAbbreviation = (uom: string) => {
  switch (uom) {
    case "Length by the inch-50ft":
      return "In.";
    case "Count in each":
      return "Ea.";
    default:
      return "Ft.";
  }
};

export const GetUomWord = (uom: string, forceFeet = false) => {
  switch (uom) {
    case "Length by the inch-50ft":
      return forceFeet ? "Feet" : "Inches";
    case "Count in each":
      return "Each";
    default:
      return "Feet";
  }
};

export const GetFeet = (uom: string, length: number) => {
  if (uom === "Length by the inch-50ft") {
    return In2Ft(length);
  }
  return length;
};

export const GetUomConversionQuantity = (uom: string, uomo: string, value: number) => {
  if (uom === "foot" && uomo === "Length by the inch-50ft") {
    return Number((value / 12).toFixed(2));
  } else if (uom === "inch" && uomo === "Length by the foot") {
    return Number((value * 12).toFixed(2));
  }
  return value;
};
export const GetUomConversionPrice = (uom: string, uomo: string, value: number) => {
  if (uom === "foot" && uomo === "Length by the inch-50ft") {
    return Number((value * 12).toFixed(2));
  } else if (uom === "inch" && uomo === "Length by the foot") {
    return Number((value / 12).toFixed(2));
  }
  return value;
};
