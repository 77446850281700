import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface StyledTabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
  [key: string]: any;  // To allow for additional props not specified here
}

const StyledTabPanel: React.FC<StyledTabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

export default StyledTabPanel;
