import React, { useState } from "react"
import { LedTapeRecord } from "../../../../../../database/Products";
import { GetOptions } from "../../../utils";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import Option from "./components/Option";
import { default as TabPanel } from "../../../../../../components/StyledTabPanel";
import Conflicts from "./Conflicts";
import { DisplayAlert } from "../../../types";

type OptionsConfigProps = {
  record: LedTapeRecord;
  displayAlert: DisplayAlert;
}

const OptionsConfig = ({ record, displayAlert }: OptionsConfigProps) => {

  const availableOptions = [...GetOptions(record.led_type)];
  const [tabValue, setTabValue] = useState<number>(0);

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
    <Tabs
      value={tabValue}
      onChange={changeTab}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label="Config" value={0} />
      <Tab label="Conflicts" value={1} />
    </Tabs>
    <Divider />
    <TabPanel value={tabValue} index={0}>
      <Box display='flex' flexDirection='column' gap={2}>
        {
          availableOptions.map((option, i) => (
            <Option 
              key={i}
              ledTapeSlug={record.slug} 
              type={option}
              displayAlert={displayAlert}
            />
          ))
        }
      </Box>
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <Conflicts 
        ledTapeSlug={record.slug} 
        displayAlert={displayAlert}
      />
    </TabPanel>
    </>
  );
};

export default OptionsConfig;