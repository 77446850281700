import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { PostgrestError, PostgrestSingleResponse } from "@supabase/supabase-js";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { TextField } from "../../../components/TextField";
import { DisplayAlert } from "../../../types";

type OptionValueProps = {
  record?: ValueRecord;
  channelAssemblyId: number;
  nextDisplayOrder?: number;
  InsertRecord: (newRecord: NewValueRecord) => Promise<PostgrestSingleResponse<ValueRecord>>;
  UpdateRecord: (record: Pick<ValueRecord, 'id' | 'name' | 'sku_code' | 'easy_spec_code'>) => Promise<PostgrestSingleResponse<ValueRecord>>;
  onInserted?: () => void;
  onCancelNew?: () => void;
  displayAlert: DisplayAlert;
}

const OptionValue = ({record, channelAssemblyId, InsertRecord, UpdateRecord, nextDisplayOrder, onInserted, onCancelNew, displayAlert }: OptionValueProps) => {

  const [existingRecord, setExistingRecord] = useState<ValueRecord | undefined>(record);
  const [nameInput, setDisplayNameInput] = useState<string>(record?.name ?? '');
  const [skuCodeInput, setSkuCodeInput] = useState<string>(record?.sku_code ?? '');
  const [easySpecCodeInput, setEasySpecCodeInput] = useState<string>(record?.easy_spec_code ?? '');

  const canSave = (
    nameInput !== existingRecord?.name ||
    skuCodeInput !== existingRecord?.sku_code ||
    easySpecCodeInput !== existingRecord?.easy_spec_code
  );

  const handleSave = () => {
    if(!canSave) return;
    if(record) dbUpdate();
    else dbInsert();
  }

  const dbInsert = async () => {
    const newRecord: NewValueRecord = { 
      channel_assembly_id: channelAssemblyId,
      name: nameInput,
      sku_code: skuCodeInput,
      easy_spec_code: easySpecCodeInput,
      display_order: nextDisplayOrder ?? 0
    }
    const { data, error } = await InsertRecord(newRecord);
    processResponse(error);
    if(data && onInserted) onInserted();
  }

  const dbUpdate = async () => {
    if(!canSave || !record) return;
    const rec = {
      id: record.id, 
      name: nameInput, 
      sku_code: skuCodeInput, 
      easy_spec_code: easySpecCodeInput 
    };
    const { data, error } = await UpdateRecord(rec);
    processResponse(error);
    if(data) setExistingRecord(data);
  }

  const processResponse = (error: PostgrestError | null) => {
    if(error){
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    displayAlert('Saved successfully!', 'success');
  }

  return (
    <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
      <TextField
        label='Name'
        value={nameInput}
        onChange={(e) => setDisplayNameInput(e.target.value)}
        updated={nameInput !== existingRecord?.name}
      />
      <TextField
        label='SKU Code'
        value={skuCodeInput}
        onChange={(e) => setSkuCodeInput(e.target.value)}
        updated={skuCodeInput !== existingRecord?.sku_code}
      />
      <TextField
        label='Easy Spec Code'
        value={easySpecCodeInput}
        onChange={(e) => setEasySpecCodeInput(e.target.value)}
        updated={easySpecCodeInput !== existingRecord?.easy_spec_code}
      />
      {
        canSave &&
        <Button 
          startIcon={<SaveOutlinedIcon />} 
          color="secondary" 
          variant="contained"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      }
      {
        !existingRecord && onCancelNew &&
        <Button 
          startIcon={<CancelOutlinedIcon />} 
          color="error" 
          onClick={() => onCancelNew()}
        >
          Cancel
        </Button>
      }
    </Box>
  );
};

export default OptionValue;