import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, IconButton, MenuItem, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useEventCallback, useTheme } from "@mui/material";
import TotalRow from "./TotalRow";
import { LedTape } from "../../../../database/Products";
import RunRow from "./RunRow";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import { CalculateWattsForLedTapeStrip, CutDistribution, ForceRounding } from "../../../../global_functions/RunCalculations";
import { SelectField } from "../../../data/product-config/components/TextField";
import { CalculateRunLine, CreateNewEmptyRun, CreateNewRun, FilterRuns, GetCompiledBuildDetails, IsRunBlank } from "../utils";
import { Run } from "../types";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../../components/StrictModeDroppable";
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import DeepCopy from "../../../../utils/DeepCopy";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ToolInfoDialog from "./ToolInfoDialog";
import { Ft2In, Mm2In } from "../../../../global_functions/UomConversion";

const preventDefault = (event: KeyboardEvent) =>{
  const isCtrlC = event.ctrlKey && (event.key === 'c' || event.key === 'C');
  const isCtrlV = event.ctrlKey &&( event.key === 'v' || event.key === 'V');
  const isCtrlX = event.ctrlKey && (event.key === 'x' || event.key === 'X');
  const isCtrlA = event.ctrlKey && (event.key === 'a' || event.key === 'A');

  // Allow native hotkey actions like Ctrl + C, Ctrl + V, Ctrl + X, and Ctrl + A
  if (isCtrlC || isCtrlV || isCtrlX || isCtrlA) {
    return; // Don't prevent default for these actions
  }
  event.preventDefault();
  event.stopPropagation();
}

type RunsTableProps = {
  ledTape: LedTape | null;
}

const RunsTable = ({ ledTape }: RunsTableProps) => {
  const colors = useTheme().colors;

  const [wattsInDetails, setWattsInDetails] = useState<boolean>(false);
  const [rounding, setRounding] = useState<ForceRounding | 'Auto'>('Up');
  const [cutDistribution, setCutDistribution] = useState<CutDistribution>('Balanced');
  const [ignoreMaxRunLen, setIgnoreMaxRunLen] = useState<boolean>(false);
  const [runs, setRuns] = useState<Run[]>(ledTape ? [CreateNewEmptyRun(ledTape, cutDistribution, rounding, wattsInDetails)] : []);
  const [showInfoDialog, SetShowInfoDialog] = useState<boolean>(false);
  const [focusedRun, setFocusedRun] = useState<number>();
  const [focusedFieldIndex, setFocusedFieldIndex] = useState<number>(0);
  const textFieldRefs = useRef<Array<HTMLInputElement | null>>([]);

  const totalFootage = runs.reduce((prev, curr) => prev + curr.ledTapeRunLine.totalFootage, 0);
  const totalBuildDetails = GetCompiledBuildDetails(runs, ledTape?.conductors ?? 0);
  const totalLeads = runs.reduce((prev, curr) => prev + curr.ledTapeRunLine.leadsReq, 0);
  const totalChannel = runs.reduce((prev, curr) => prev + curr.ledTapeRunLine.channelReq, 0);
  const totalWattage = runs.reduce((prev, curr) => prev + curr.ledTapeRunLine.totalWatts, 0);  

  const handleKeyDown = useEventCallback((event: KeyboardEvent) => {
    if(!event.ctrlKey) return;
    preventDefault(event);
    const newRuns = DeepCopy<Run[]>(runs);
    const targetRunIndex = newRuns.findIndex(run => run.runId === focusedRun);
    const targetRun = newRuns[targetRunIndex];
    if(!targetRun) return;
    switch(event.key){
      case 'd': {
        const newRun = DeepCopy<Run>(targetRun);
        newRun.runId = Math.random() * 100;
        if(newRun.inFeed === 'fmc' && !newRun.fmc) newRun.inFeed = 'lead';
        if(newRun.fmc) newRun.inFeed = 'fmc';
        newRuns.splice(targetRunIndex + 1, 0, newRun);
        recalculateAllRuns(newRuns);
        setFocusedFieldIndex(Math.max((focusedFieldIndex + 3), 0))
        return;
      }
      case 'f': {
        if(targetRun.feetInput === 0 && targetRun.inchesInput === 0) return;
        const followingRun = newRuns[targetRunIndex + 1];
        if(targetRun.fmc){
          delete targetRun.fmc;
          if(followingRun) followingRun.inFeed = 'lead';
        }else{
          targetRun.fmc = { inches: 1.5 };
          followingRun.inFeed = 'fmc';
          let indexCounter = targetRunIndex + 1;
          let nextRun = followingRun;
          do{
            nextRun.qtyInput = targetRun.qtyInput;
            indexCounter++;
            nextRun = newRuns[indexCounter];
          }while(nextRun && nextRun.inFeed === 'fmc');
        }
        recalculateAllRuns(newRuns);
        return;
      }
      case 'ArrowUp': {
        setFocusedFieldIndex(Math.max((focusedFieldIndex - 3), 0))
        return;
      }
      case 'ArrowDown': {
        setFocusedFieldIndex(Math.min((focusedFieldIndex + 3), textFieldRefs.current.length - 1))
        return;
      }
      case 'ArrowRight': {
        setFocusedFieldIndex(Math.min((focusedFieldIndex + 1), textFieldRefs.current.length - 1))
        return;
      }
      case 'ArrowLeft': {
        setFocusedFieldIndex(Math.max((focusedFieldIndex - 1), 0))
        return;
      }
      default: { return; }
    }
  });

  useEffect(() => {
    const targetField = textFieldRefs.current[focusedFieldIndex];
    if (targetField) targetField.focus();
  }, [focusedFieldIndex]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if(!ledTape) return;
    if(runs.length === 0) {
      const newRun = CreateNewEmptyRun(ledTape, cutDistribution, rounding, wattsInDetails);
      setRuns([newRun]);
    }else{
      recalculateAllRuns();
    }
  }, [ledTape]);
  
  useEffect(() => {
    recalculateAllRuns();
  }, [cutDistribution, rounding, wattsInDetails, ignoreMaxRunLen]);

  const recalculateAllRuns = (newRuns?: Run[], updateState = true) => {
    const targetRuns = newRuns ?? runs;
    // Update all InFeeds
    targetRuns.forEach((run, index) => {
      const beginningRun = targetRuns[index - 1];
      const oldInFeed = run.inFeed;
      run.inFeed = !beginningRun || !beginningRun.fmc ? 'lead' : 'fmc';
      if(oldInFeed !== run.inFeed) run.qtyInput = beginningRun.qtyInput;
    });
    
    // Recalculate measurements, build details, etc.
    const updatedRuns = targetRuns.map(run => {
      run.ledTapeRunLine = recalculateRunLine(run);
      return run;
    });

    // Calculate length & wattage of FMC connected runs
    const fmcRuns: Run[][] = [];
    for(let i = 0; i < updatedRuns.length; i++){
      const run = updatedRuns[i];
      const prevRun = updatedRuns[i-1];
      const hasFmc = !!run.fmc;
      const priorHadFmc = !!prevRun?.fmc;
      if(hasFmc && priorHadFmc) fmcRuns[fmcRuns.length-1].push(run);
      if(!hasFmc && priorHadFmc) fmcRuns[fmcRuns.length-1].push(run);
      if(hasFmc && !priorHadFmc) fmcRuns.push([run]);
      if(!hasFmc && !priorHadFmc){
        delete run.totalFmcRunIntervals;
        delete run.totalFmcRunWatts;
      }
    }    
    fmcRuns.forEach(fmcRun => {
      const totalFmcRunIntervals = fmcRun.reduce((prev, curr) => curr.ledTapeRunLine.ledTapeRun.cutIntervals + prev, 0);
      const totalFmcRunMm = (ledTape?.specs.cut_interval_mm ?? 0) * totalFmcRunIntervals;
      const totalFmcRunWatts = (ledTape?.specs ? CalculateWattsForLedTapeStrip(totalFmcRunMm, ledTape.specs) : 0);
      fmcRun.forEach(run => {
        run.totalFmcRunIntervals = totalFmcRunIntervals
        run.totalFmcRunWatts = totalFmcRunWatts;
      });
    });

    if(updateState) setRuns(updatedRuns);
    return updatedRuns;
  }

  const handleQtyChange = (qty: number, run: Run) => {
    run.qtyInput = qty;
    run.ledTapeRunLine = recalculateRunLine(run);
    if(run.fmc){
      const index = runs.findIndex(r => r.runId === run.runId);
      if(index >= 0){
        const followingRun = runs[index + 1];
        if(followingRun) handleQtyChange(qty, followingRun);
      }
    }
    const updatedRuns = GetRunsWithNewRun(run);
    setRuns(updatedRuns);
  }

  const handleFeetChange = (feet: number, run: Run) => {
    run.feetInput = feet;
    run.ledTapeRunLine = recalculateRunLine(run);
    const updatedRuns = GetRunsWithNewRun(run);
    if(run.totalFmcRunIntervals) recalculateAllRuns(updatedRuns);
    else setRuns(updatedRuns);
  }
  
  const handleInchesChange = (inches: number, run: Run) => {
    run.inchesInput = inches;
    run.ledTapeRunLine = recalculateRunLine(run);
    const updatedRuns = GetRunsWithNewRun(run);
    if(run.totalFmcRunIntervals) recalculateAllRuns(updatedRuns);
    else setRuns(updatedRuns);
  }

  const recalculateRunLine = (run: Run) => {
    if(!ledTape) return run.ledTapeRunLine;
    const ledTapeRunLine = CalculateRunLine(
      ledTape,
      run.qtyInput, 
      run.feetInput, 
      run.inchesInput,
      run.inFeed,
      cutDistribution,
      rounding,
      (wattsInDetails && run.inFeed === 'lead' && !run.fmc),
      ignoreMaxRunLen
    );
    return ledTapeRunLine;
  }

  const GetRunsWithNewRun = (run: Run) => {
    const runIndex = runs.findIndex(r => r.runId === run.runId);
    const newRuns = [...runs];
    newRuns[runIndex] = run;
    if(!ledTape) return newRuns;
    const filteredRuns = FilterRuns(newRuns, ledTape, cutDistribution, rounding, wattsInDetails);
    return filteredRuns;
  }

  const handleCutDistributionChange = (value: string) => {
    switch(value){
      case 'Balanced': {
        setCutDistribution('Balanced');
        break;
      }
      case 'Even': {
        setCutDistribution('Even');
        break;
      }
      default: {
        setCutDistribution('Maximized');
        break;
      }
    }
  }

  const handleRoundingChange = (value: string) => {
    if(value === 'Up') setRounding('Up');
    if(value === 'Down') setRounding('Down');
    if(value === 'Auto') setRounding('Auto');
  }

  const handleReset = () => {
    if(!ledTape) {
      setRuns([]);
      return;
    }
    const newRun = CreateNewEmptyRun(ledTape, cutDistribution, rounding, wattsInDetails);
    setRuns([newRun]);
  }

  const handlePastFromExcel = async () => {
    if(!ledTape) return;
    const clipboardData = await navigator.clipboard.readText();
    const rows = clipboardData.split('\r\n').filter(row => row.trim() !== '');
    const inputArr = rows.map(row => row.split('\t').map(value => Number(value)));
    const updatedRuns = [...runs];
    const lastRun = updatedRuns[updatedRuns.length - 1];
    if(IsRunBlank(lastRun)) updatedRuns.pop();
    inputArr.forEach(row => {
      const qty = row[0];
      const feet = row[1];
      const inches = row[2];
      updatedRuns.push(CreateNewRun(ledTape, qty, feet, inches, cutDistribution, rounding, wattsInDetails));
    });
    updatedRuns.push(CreateNewEmptyRun(ledTape, cutDistribution, rounding, wattsInDetails));
    setRuns(updatedRuns);
  }

  const handleCopyForExcel = async () => {
    let content = '';
    runs.forEach(run => {
      if(IsRunBlank(run)) return;
      const r = DeepCopy<Run>(run);
      content += (r.qtyInput + '\t');
      content += (r.feetInput + '\t');
      content += (r.inchesInput + '\t');
      const runLine = r.ledTapeRunLine;
      content += (runLine.totalFootage.toFixed(2) + '\t');
      const delta = Mm2In(runLine.ledTapeRun.totalLengthMm) - (Ft2In(r.feetInput) + r.inchesInput);
      content += delta.toFixed(2) + '\t';
      const buildDetails = runLine.buildDetails.split('\n').join('  ');
      content += (buildDetails + '\t');
      content += (runLine.leadsReq + '\t');
      content += (runLine.channelReq + '\t');

      const isFirstInFmcRun = run.inFeed === 'lead' && !!run.fmc;
      let wattsPerLeadProcessed = 0;
      if(!run.totalFmcRunWatts && runLine.wattsPerLead && runLine.wattsPerLead.length === 1 && runLine.wattsPerLead[0].watts > 0){
        wattsPerLeadProcessed = Number(runLine.wattsPerLead[0].watts.toFixed(2));
      }else if(isFirstInFmcRun && !!run.totalFmcRunWatts){
        wattsPerLeadProcessed = Number((run.totalFmcRunWatts / run.qtyInput).toFixed(2));    
      }

      !run.totalFmcRunWatts && runLine.wattsPerLead && runLine.wattsPerLead.length > 1 && runLine.wattsPerLead?.forEach((wpl, index) => {
        content += `${wpl.watts.toFixed(2)}w (${wpl.tapeLengthFt} FT)`;
        if(index < (runLine.wattsPerLead?.length ?? 0) - 1) content += '  ';
      });      

      if(wattsPerLeadProcessed > 0) content += `${wattsPerLeadProcessed}w` + '\t';
      else content += '\t';
      
      content += !r.totalFmcRunWatts && runLine.totalWatts > 0 ? `${runLine.totalWatts.toFixed(2)}w` + '\r\n' : '';
      content += isFirstInFmcRun && !!run.totalFmcRunWatts ? `${(run.totalFmcRunWatts * run.qtyInput).toFixed(2)}w` + '\r\n' : '\r\n';
    });
    await navigator.clipboard.writeText(content);
  }

  const handleOnFocusedInput = (runId: number, fieldIndex: number) => {
    setFocusedRun(runId);
    if(focusedFieldIndex === fieldIndex) return;
    setFocusedFieldIndex(fieldIndex);
  }

  const handleOnBlurredInput = () => {
    setFocusedRun(0);
    if(focusedFieldIndex === null) return;
  }

  const onDragEnd = (result: DropResult) => {
    if(!ledTape) return;
    const { destination, source, draggableId } = result;
    if(!destination) return;
    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ){
      return;
    }
    const targetRun = runs.find(run => run.runId.toString() === draggableId);
    if(!targetRun) return;
    const splicedRuns = Array.from(runs);
    splicedRuns.splice(source.index, 1);
    splicedRuns.splice(destination.index, 0, targetRun);
    const recalculatedRuns = recalculateAllRuns(splicedRuns, false);
    setRuns(FilterRuns(recalculatedRuns, ledTape, cutDistribution, rounding, wattsInDetails));
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end' mb={2}>
        <Box display='flex' flexDirection='row' gap={2}>
          <SelectField 
            label='Rounding'
            value={rounding}
            onChange={(e) => handleRoundingChange(e.target.value)}
            variant="outlined"
          >
            <MenuItem value='Up'>
              <Box display='flex' gap={1}><ArrowUpwardOutlinedIcon /> Up</Box>
            </MenuItem>
            <MenuItem value='Down'>
              <Box display='flex' gap={1}><ArrowDownwardOutlinedIcon /> Down</Box>
            </MenuItem>
            <MenuItem value='Auto'>
              <Box display='flex' gap={1}><AutoAwesomeOutlinedIcon /> Auto</Box>
            </MenuItem>
          </SelectField>
          <SelectField 
            label='Distribution'
            value={cutDistribution}
            onChange={(e) => handleCutDistributionChange(e.target.value)}
            variant="outlined"
          >
            <MenuItem value='Balanced'>
              <Box display='flex' gap={1}><BalanceOutlinedIcon /> Balanced</Box>
            </MenuItem>
            <MenuItem value='Even'>
              <Box display='flex' gap={1}><CalendarViewWeekOutlinedIcon /> Even</Box>
            </MenuItem>
            <MenuItem value='Maximized'>
              <Box display='flex' gap={1}><ViewQuiltOutlinedIcon /> Maximized</Box>
            </MenuItem>
          </SelectField>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='row' alignItems='flex-end' gap={2}>
            <Button 
              color="success"
              onClick={() => handlePastFromExcel()}
              startIcon={<DownloadOutlinedIcon />}
            >
              Excel Paste
            </Button>
            <Button 
              color="success"
              onClick={() => handleCopyForExcel()}
              startIcon={<CopyAllOutlinedIcon />}
            >
              Excel Copy
            </Button>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='row' alignItems='flex-end' gap={2}>
            <Box>
              <Switch
                checked={ignoreMaxRunLen}
                onChange={(e) => setIgnoreMaxRunLen(e.target.checked)}
              />
              Ignore Max Run Length
            </Box>
          </Box>
        </Box>
        <Box>
        <IconButton onClick={() => SetShowInfoDialog(true)} sx={{ marginRight: 1 }}>
          <InfoOutlinedIcon />
        </IconButton>
          <Button
            color="error"
            startIcon={<RestartAltOutlinedIcon />}
            onClick={() => handleReset()}
          >
            Reset
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ overflow: 'visible' }}>
        <Table sx={{ maxWidth: "1450px" }} size="small">
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TableCell align="left"><b>Runs</b></TableCell>
              <TableCell align="left"><b>Requested Length</b></TableCell>
              <TableCell align="left"><b>Total FT</b></TableCell>
              <TableCell align="left"><b>Run Delta</b></TableCell>
              <TableCell align="left" sx={{ display: "flex" }}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <b>Build Details</b>
                  <div style={{ marginLeft: "25px" }}>
                    <Switch
                      checked={wattsInDetails}
                      onChange={(e) => { setWattsInDetails(e.target.checked); }}
                      tabIndex={1}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <b>Wattage</b>
                  </div>
                </Box>
              </TableCell>
              <TableCell align="left"><b>Leads</b></TableCell>
              <TableCell align="left"><b>Channel</b></TableCell>
              <TableCell align="left"><b>Load/Lead</b></TableCell>
              <TableCell align="left"><b>Total Load</b></TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="runsTable">
              {
                (provided) => (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      ledTape &&
                      runs.map((run, index) => (
                        <Draggable key={run.runId} draggableId={run.runId.toString()} index={index}>
                          {
                            (provided) => (
                              <>
                              {
                                ((index > 0) && run.fmc && !runs[index - 1]?.fmc && !runs[index - 2]?.fmc) &&
                                <TableRow sx={{ boxShadow: 'inset 0 0 8px 0px #0000001f' }}>
                                  <TableCell colSpan={9} height={20}>{""}</TableCell>
                                </TableRow>
                              }
                              <TableRow 
                                {...provided.draggableProps} 
                                ref={provided.innerRef}
                                sx={{
                                  bgcolor: 
                                    !ignoreMaxRunLen && 
                                    run.totalFmcRunIntervals && 
                                    (run.totalFmcRunIntervals > (ledTape.specs.max_cut_intervals ?? 0)) ?
                                    colors.redAccent[900] : ''
                                }}
                              >
                                <RunRow                                  
                                  run={run}
                                  onQtyUpdate={(qty: number) => handleQtyChange(qty, run)}
                                  onFeetUpdate={(feet: number) => handleFeetChange(feet, run)}
                                  onInchesUpdate={(inches: number) => handleInchesChange(inches, run)}
                                  dragHandleProps={provided.dragHandleProps}
                                  onFocused={(field: number) => handleOnFocusedInput(run.runId, (index * 3) + field)}
                                  onBlurred={handleOnBlurredInput}
                                  OnInputRef={(ref: HTMLInputElement | null, field: number) => (textFieldRefs.current[(index * 3) + field] = ref)}
                                />
                              </TableRow>                              
                              {
                                (!run.fmc && !!runs[index - 1]?.fmc) &&
                                <TableRow sx={{ boxShadow: 'inset 0 0 8px 0px #0000001f' }}>
                                  <TableCell colSpan={9} height={20}>{""}</TableCell>
                                </TableRow>
                              }
                              </>
                            )
                          }
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                    {
                      totalFootage > 0 &&
                      <TotalRow
                        totalFootage={totalFootage}
                        compiledBuildDetails={totalBuildDetails}
                        totalLeads={totalLeads}
                        totalChannel={totalChannel}
                        totalWattage={totalWattage}
                      />
                    }
                  </TableBody>
                )
              }
            </StrictModeDroppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <ToolInfoDialog open={showInfoDialog} onClose={() => SetShowInfoDialog(false)} />
    </Box>
  );
};

export default RunsTable;