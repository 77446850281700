import React from "react";
import { useDispatch } from "react-redux";
import { supabase } from "../../database/supabaseClient";
import { Box, IconButton, useTheme } from "@mui/material";
import { asyncToggleTheme } from "../../store/reducers/themeSlice";
import LightModeOutlinedicon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedicon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { AppDispatch } from "../../store/store";

const Topbar = () => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();

  async function signout() {
    const { error } = await supabase.auth.signOut();
    console.error(error);
  }

  return (
    <Box display="flex" justifyContent="end" p={2}>
      <Box display="flex">
        <IconButton onClick={() => dispatch(asyncToggleTheme())}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedicon />
          ) : (
            <LightModeOutlinedicon />
          )}
        </IconButton>

        <IconButton onClick={signout}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
