import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { getAllEzChannelAssemblies, getAllEzLedTape, insertEasySpecSheet, selectSpecSheets, updateEasySpecSheet, uploadEasySpecSheet } from "../data";
import { getEasySpecSheetUrl } from "../../../../database/Products";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SpecSheetUpload from "./SpecSheetUpload";
import { SpecSheet } from "../types";

type LEDTapeConfigProps = {
  ezLedTapeId: number;
  ezChannelAssemblyId?: never;
};

type ChannelAssemblyConfigProps = {
  ezChannelAssemblyId: number;
  ezLedTapeId?: never;
};

type SpecSheetConfigProps = (LEDTapeConfigProps | ChannelAssemblyConfigProps) & {
  displayAlert: (message: string, severity: "error" | "success") => void;
};

const SpecSheetConfig = ({ ezLedTapeId, ezChannelAssemblyId, displayAlert }: SpecSheetConfigProps) => {
  const colors = useTheme().colors;

  const [records, setRecords] = useState<SpecSheet[]>([]);
  const [allEzConfigs, setAllEzConfigs] = useState<{ id: number; name: string; enabled: boolean}[]>([]);
  const [editRecord, setEditRecord] = useState<SpecSheet | null>(null);

  const configFor = ezLedTapeId ? 'LED Tape' : 'Channel Assembly';
  const opposite = configFor === 'LED Tape' ? 'Channel Assembly' : 'LED Tape';

  const queryData = useCallback(async () => {
    Promise.all([
      selectSpecSheets(ezLedTapeId, ezChannelAssemblyId),
      configFor === 'LED Tape' ? getAllEzChannelAssemblies() : getAllEzLedTape()
    ]).then(value => {
      const [existingSpecSheets, allEzConfigs] = value;
      const newRecords: SpecSheet[] = [...existingSpecSheets];
      allEzConfigs.forEach(ezConfig => {
        const foundExisting = newRecords.find(ezSpecSheet => {
          if(configFor === 'LED Tape') return ezSpecSheet.ez_channe_assembly_id === ezConfig.id;
          else return ezSpecSheet.ez_led_tape_id === ezConfig.id;
        })
        if(foundExisting) return;
        newRecords.push({
          ledTape: ezLedTapeId ? '' : ezConfig.name,
          channelAssembly: ezChannelAssemblyId ? '' : ezConfig.name,
          ez_led_tape_id: ezLedTapeId ?? ezConfig.id,
          ez_channe_assembly_id: ezChannelAssemblyId ?? ezConfig.id,
          spec_sheet: ''
        });
      });
      setRecords(newRecords);
      setAllEzConfigs(allEzConfigs);
    });
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const handleDownloadImage = (fileName: string) => {
    getEasySpecSheetUrl(fileName).then(url => {
      if(!url) return;
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = url.slice(url.lastIndexOf('.'));
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  };

  const handleUpdateUploadClick = async (specSheet: SpecSheet, file: File) => {
    const fileName = await uploadEasySpecSheet(file);
    if(fileName === null) {
      displayAlert('Error uploading the image file.', 'error');
      return;
    }
    let success = false;
    if(specSheet.spec_sheet === ''){
      success = await insertEasySpecSheet(specSheet.ez_channe_assembly_id, specSheet.ez_led_tape_id, fileName);
    }else{
      success = await updateEasySpecSheet(specSheet.ez_channe_assembly_id, specSheet.ez_led_tape_id, fileName);      
    }
    if(success) {
      setEditRecord(null);
      queryData();
      displayAlert(`Successfully updated the spec sheet.`, 'success');
    }else{
      displayAlert(`Error updating the spec sheet.`, 'error');
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography>Spec Sheets</Typography>
        <Divider />
        {
          records.length > 0 &&
          <Box p="10px" maxWidth="750px">
            <Table size="small">
              <TableHead sx={{ bgcolor: colors.primary[400] }}>
                <TableRow>
                  <TableCell><Typography><b>{opposite}</b></Typography></TableCell>
                  <TableCell><Typography><b>Enabled</b></Typography></TableCell>
                  <TableCell><Typography><b>Spec Sheet</b></Typography></TableCell>
                  <TableCell><Typography><b>Action</b></Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  records.map((record, i) => (
                    <TableRow key={i} sx={{ bgcolor: record.spec_sheet === '' ? colors.redAccent[900] : 'none'}}>
                      {
                        configFor === 'LED Tape' ?
                        <TableCell><Typography>{ record.channelAssembly}</Typography></TableCell>
                        :
                        <TableCell><Typography>{ record.ledTape}</Typography></TableCell>
                      }
                      <TableCell>
                        <Typography>
                          {
                            configFor === 'LED Tape' ?
                            allEzConfigs.find(ezc => ezc.id === record.ez_channe_assembly_id)?.enabled ? 'YES' : 'NO'
                            :
                            allEzConfigs.find(ezc => ezc.id === record.ez_led_tape_id)?.enabled ? 'YES' : 'NO'
                          }
                        </Typography>
                      </TableCell>
                      {
                        record.spec_sheet === '' &&
                        <>
                          <TableCell>
                            <Box display="flex" flexDirection='row' gap={1} px="4px" color={colors.redAccent[500]}>
                              <CancelOutlinedIcon />
                              <Typography>Missing</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button 
                              variant="contained"
                              startIcon={<FileUploadOutlinedIcon />}
                              onClick={() => setEditRecord(record)}
                            >
                              Upload
                            </Button>
                          </TableCell>
                        </>
                      }
                      {
                        record.spec_sheet !== '' &&
                        <>
                          <TableCell>
                            <Button 
                              color="success" 
                              startIcon={<CheckCircleOutlinedIcon />}
                              onClick={() => handleDownloadImage(record.spec_sheet)}
                            >
                              Download
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined" 
                              color="info" 
                              size="small" 
                              startIcon={<FileUploadOutlinedIcon />}
                              onClick={() => setEditRecord(record)}
                            >
                              Update
                            </Button>
                          </TableCell>
                        </>
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Box>
        }
      </CardContent>
      <Dialog
        open={editRecord !== null}
        onClose={() => setEditRecord(null)}
      >
        <DialogTitle>{editRecord?.spec_sheet !== undefined && editRecord.spec_sheet !== '' ? 'Update' : 'Upload'} Spec Sheet</DialogTitle>
        <DialogContent sx={{ minWidth: '500px'}}>
          {
            editRecord &&
            <SpecSheetUpload specSheet={editRecord} handleUpload={handleUpdateUploadClick} />
          }
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default SpecSheetConfig;