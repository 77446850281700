import React, { useState, useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import EditButton from "../../../components/EditButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import UserPermissions from "./UserPermissions";
import { Profile } from "../../../database/Profile";
import { PermissionRecord, UserPermissionRecord } from "../../../database/PublicTypes";

type CellProps = {
  align?: "center" | "right" | "left" | "inherit" | "justify";
  sx?: object;
  children?: React.ReactNode;
}

const Cell = ({ align, sx, children }: CellProps) => {
  return (
    <TableCell align={align} sx={{ ...sx }}>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

type UsersProps = {
  profiles: Profile[];
  editProfileId: string | null | undefined;
  onProfileSelected: (profileId: string | null) => void;
  permissions: PermissionRecord[];
  userPermissions: UserPermissionRecord[];
  reloadData: () => Promise<void>;
}

const Users = ({ profiles, editProfileId, onProfileSelected, permissions, userPermissions, reloadData }: UsersProps) => {
  const colors = useTheme().colors;

  const [editing, setEditing] = useState(editProfileId);

  useEffect(() => {
    if (onProfileSelected) onProfileSelected(null);
  }, [editing, onProfileSelected]);

  const handleOnClickBack = () => {
    setEditing(null);
    onProfileSelected(null);
  }

  const editingProfile = profiles.find(p => p.id === editing);

  return !editingProfile ? (
    <Box>
      <TableContainer sx={{ marginBottom: "50px" }}>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <Cell >
                <strong>Full Name</strong>
              </Cell>
              <Cell>
                <strong>Permissions</strong>
              </Cell>
              <Cell></Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => (
                <TableRow key={profile.id} hover>
                  <Cell>{profile.full_name}</Cell>
                  <Cell>
                    {profile.permissions.map((permission) =>
                      permission + ", "
                    )}
                  </Cell>
                  <Cell align="right">
                    <EditButton onClickEdit={() => setEditing(profile.id)} />
                  </Cell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Box sx={{ m: "25px 0", width: "50%" }}>
      <Box marginBottom="20px" sx={{ "& > *": { marginRight: "10px" } }}>
        <Button
          variant="outlined"
          color="info"
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => handleOnClickBack()}
        >
          Back
        </Button>
      </Box>
      <h3>{editingProfile.full_name}</h3>
      <UserPermissions
        profile={editingProfile}
        permissionRecords={permissions}
        userPermissionRecords={userPermissions}
        reloadData={reloadData}
      />
    </Box>
  );
};

export default Users;
