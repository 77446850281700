import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StyledNumberInput from "../../../components/StyledNumberInput";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { parse } from "js2xmlparser";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

type CellProps = {
  children: React.ReactNode;
  minWidth?: string;
}

const Cell = ({ children, minWidth = "125px" }: CellProps) => {
  return (
    <TableCell sx={{ minWidth: minWidth, verticalAlign: "top" }}>
      <Typography whiteSpace="pre-line">{children}</Typography>
    </TableCell>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type XmlPreviewProps = {
  quoteData: QuoteData;
  quoteVersion: number;
  onChangeVersion: (newValue: string) => void;
  zeroedPricing: boolean;
  onChangeZeroedPricing: (newValue: boolean) => void;
  blankDescription: boolean;
  onChangeBlankDescription: (newValue: boolean) => void;
  warnings: Warning[] | undefined;
}

const XmlPreview = ({
  quoteData,
  quoteVersion,
  onChangeVersion,
  zeroedPricing,
  onChangeZeroedPricing,
  blankDescription,
  onChangeBlankDescription,
  warnings,
}: XmlPreviewProps) => {
  const colors = useTheme().colors;

  const scrollToItems = useRef<(HTMLTableRowElement | null)[]>([]);
  const [highlighting, setHighlighting] = useState(
    Array(quoteData["QUOTELINE"].length).fill(false)
  );

  const quoteXml = parse("QUOTE", { DATA: { ...quoteData } });

  function ExportAsXml() {
    const element = document.createElement("a");
    const file = new Blob([quoteXml], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = `KELV-QT_${quoteData["QUOTEHDR"]["QTENUMBER"]}.xml`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const scrollToLineItem = (index: number) => {
    scrollToItems.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // Start highlighting
    setHighlighting((prev) => {
      const newArr = [...prev];
      newArr[index] = true;
      return newArr;
    });
    // Stop highlighting after 2 seconds
    setTimeout(() => {
      setHighlighting((prev) => {
        const newArr = [...prev];
        newArr[index] = false;
        return newArr;
      });
    }, 2000);
  };

  return (
    <Box>
      <Box display='flex' flexDirection='row' alignItems='flex-end'>
        <StyledNumberInput
          label="Version"
          type="number"
          size="small"
          value={quoteVersion}
          onChange={(e) => onChangeVersion(e.target.value)}
        />
        <FormControlLabel
          value="top"
          control={
            <Switch
              color="secondary"
              checked={zeroedPricing}
              onChange={(e) => onChangeZeroedPricing(e.target.checked)}
            />
          }
          label="Zero-Out Pricing"
          sx={{ margin: "0 30px" }}
        />
        <FormControlLabel
          value="top"
          control={
            <Switch
              color="secondary"
              checked={blankDescription}
              onChange={(e) => onChangeBlankDescription(e.target.checked)}
            />
          }
          label="Blank Description"
          sx={{ margin: "0 30px" }}
        />
        <Button
          variant="contained"
          color="success"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => ExportAsXml()}
          sx={{ marginLeft: "10px" }}
        >
          Export as XML
        </Button>
      </Box>

      {warnings?.map((warning) => (
        <Box
          display="flex"
          margin={1}
          key={warning.line}
          alignItems="center"
        >
          <ErrorOutlinedIcon color="error" />
          <Button
            color="info"
            onClick={() => scrollToLineItem(warning.line - 1)}
          >
            Line #{warning.line}: {warning.description}
          </Button>
        </Box>
      ))}

      <Accordion
        defaultExpanded={true}
        sx={{ marginBottom: 1, marginTop: 1 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "25%", flexShrink: 0 }}>
            Quote Preview
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Preview the data as it will appear in HL Stearns quotation system.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            marginBottom={3}
          >
            <Box>
              <Paper
                sx={{ minWidth: "300px", maxWidth: "350px", marginRight: 3 }}
              >
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ background: colors.primary[400] }}>
                      <TableRow>
                        <Cell>Header Label</Cell>
                        <Cell>Value</Cell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <Cell>Quote Number</Cell>
                        <Cell>{quoteData.QUOTEHDR["QTENUMBER"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Quote Version</Cell>
                        <Cell>{quoteData.QUOTEHDR["QTEVERSION"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Entry Date</Cell>
                        <Cell>{quoteData.QUOTEHDR["ENTRYDATE"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Entry Time</Cell>
                        <Cell>{quoteData.QUOTEHDR["ENTRYTIME"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Good Through</Cell>
                        <Cell>{quoteData.QUOTEHDR["GOODTHRUDATE"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Job Name</Cell>
                        <Cell>{quoteData.QUOTEHDR["JOBNAME"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Job City</Cell>
                        <Cell>{quoteData.QUOTEHDR["JOBCITY"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Job State/Province</Cell>
                        <Cell>{quoteData.QUOTEHDR["JOBSTATEPROVINCE"]}</Cell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>

            <Box>
              <Paper sx={{ minWidth: "300px", maxWidth: "350px" }}>
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ background: colors.primary[400] }}>
                      <TableRow>
                        <Cell>Customer Label</Cell>
                        <Cell>Value</Cell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <Cell>Name</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTNAME"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Address 1</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTADDRLINE1"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Address 2</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTADDRLINE2"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>Address 3</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTADDRLINE3"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>City</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTCITY"]}</Cell>
                      </TableRow>
                      <TableRow>
                        <Cell>State/Province</Cell>
                        <Cell>{quoteData.QUOTECUST["CUSTSTATEPROVINCE"]}</Cell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>

          <Box>
            <Paper>
              <TableContainer>
                <Table size="small">
                  <TableHead sx={{ background: colors.primary[400] }}>
                    <TableRow>
                      <Cell minWidth="75px">Line</Cell>
                      <Cell>Type</Cell>
                      <Cell>Description</Cell>
                      <Cell>Line Comment</Cell>
                      <Cell>Quantity</Cell>
                      <Cell>Unit Price</Cell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quoteData["QUOTELINE"].map((lineItem, index) => (
                      <StyledTableRow
                        key={index}
                        ref={(el) => (scrollToItems.current[index] = el)}
                        sx={{
                          bgcolor: highlighting[index]
                            ? `${colors.tableRowHighlight.bad}!important`
                            : "transparent",
                        }}
                      >
                        <Cell minWidth="75px">{lineItem["LINENUMBER"]}</Cell>
                        <Cell>{lineItem["TYPE"]}</Cell>
                        <Cell minWidth="220px">{lineItem["DESCRIPTION"]}</Cell>
                        <Cell>{lineItem["LINECOMMENT"]}</Cell>
                        <Cell>{lineItem["QUANTITY"]}</Cell>
                        <Cell>
                          {lineItem["DSPEACH"] === undefined
                            ? ""
                            : `$${lineItem["DSPEACH"]}`}
                        </Cell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ marginTop: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "25%", flexShrink: 0 }}>
            XML Preview
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Previw the raw XML data.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            padding={1}
            borderRadius={2}
          >
            <pre className="language-xml">
              <code
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(quoteXml, Prism.languages.xml, "xml"),
                }}
              />
            </pre>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default XmlPreview;
