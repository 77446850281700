import React, { useState } from "react";
import { TableCell, TableRow, Typography, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

type TotalRowProps = {
  totalFootage: number;
  compiledBuildDetails: string;
  totalLeads: number;
  totalChannel: number;
  totalWattage: number;
}

const TotalRow = ({ totalFootage, compiledBuildDetails, totalLeads, totalChannel, totalWattage }: TotalRowProps) => {
  const theme = useTheme();
  const colors = theme.colors;

  const [copiedDesc, setCopiedDesc] = useState(false);

  const handleOnClickDesc = () => {
    navigator.clipboard.writeText(compiledBuildDetails);
    setCopiedDesc(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };

  const totalWattageText = totalWattage ? 
      totalWattage % 1 === 0 ? totalWattage
      : totalWattage.toFixed(2) + "w"
    : "";

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
        borderBottom:
          totalFootage > 0
            ? `1px solid ${
                theme.palette.mode === "dark"
                  ? colors.grey[600]
                  : colors.grey[900]
              }`
            : "",
      }}
    >
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell align="left">
        {totalFootage > 0 && (
          <Typography fontWeight={700}>
            {totalFootage % 1 === 0
              ? totalFootage
              : totalFootage.toFixed(2)}{" "}
            Ft
          </Typography>
        )}
      </TableCell>
      <TableCell> </TableCell>
      <TableCell align="left">
        <Tooltip
          title={copiedDesc ? "Copied!" : "Click to copy"}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: {
              enter: 800,
              exit: 0,
            },
          }}
          onClose={handleOnCloseCopyTooltip}
        >
          <Typography
            onClick={handleOnClickDesc}
            fontWeight={700}
            whiteSpace={"pre-line"}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: colors.blueAccent[500],
              },
            }}
          >
            {totalFootage > 0 && (compiledBuildDetails)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography fontWeight={700}>
          {totalLeads ? totalLeads : ""}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography fontWeight={700}>
          {totalChannel ? totalChannel : ""}
        </Typography>
      </TableCell>
      <TableCell> </TableCell>
      <TableCell align="left">
        <Typography fontWeight={700}>
          {totalWattageText}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TotalRow;
