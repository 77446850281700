import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import StyledNumberInput from "../../../components/StyledNumberInput";
import CurrencyFormatter from "../../../global_functions/CurrencyFormatter";
import { default as TableCell } from "../../../components/StyledTableCell";
import { ControllerTypeRecord, ProjectTypeRecord } from "../../../database/AppsDescGenToolTypes";
import { ServiceComponentObj, ServiceComponentValueObj } from "./data";

function MultiplyQuantity(description: string, quantity: number): string {
  // Regex to find all dynamic values like "{MULTIPLY_QUANTITY(3)}"
  const regex = /\{MULTIPLY_QUANTITY\((\d+)\)\}/g;

  // Function to replace the match with the multiplied quantity
  const replacer = (match: string, p1: string) => {
    return (quantity * Number(p1)).toString();
  };

  // Replace all dynamic values in the string
  const result = description.replace(regex, replacer);

  return result;
}

const ParseDynamicValues = (description: string, quantity: number) => {
  if (!quantity || description == null) return description;
  description = MultiplyQuantity(description, quantity);
  // .. Insert other checks here in the future
  return description;
};

type ServiceComponentProps = {
  index: number;
  serviceComponent: ServiceComponentObj;
  projectType: ProjectTypeRecord;
  controllerType: ControllerTypeRecord;
  updateOutputs: (index: number, extendedPrice: number, description: string) => void;
}

const ServiceComponent = ({ index, serviceComponent, projectType, controllerType, updateOutputs }: ServiceComponentProps) => {
  const colors = useTheme().colors;
  
  let values = serviceComponent.service_component_values.filter(value => value.active);
  values = values.filter(value => {
    const conflicts = value.service_component_value_conflicts.filter(c => c.active);
    const hasConflict = conflicts.find(conflict => 
      conflict.project_type_id === projectType.id && conflict.controller_type_id === controllerType.id
    );
    if(hasConflict) return false;
    const dependencies = value.service_component_value_dependencies.filter(d => d.active);
    const hasDeps = dependencies.length > 0;
    const satisfiesDeps = dependencies.find(dep =>
      dep.project_type_id === projectType.id && dep.controller_type_id === controllerType.id
    );
    if(hasDeps) return satisfiesDeps ? true : false;
    return true;
  });

  let defaultValue = values.find((v) => v.default && v.active);
  if (defaultValue === undefined) defaultValue = values[0];

  const [selValue, setSelValue] = useState<ServiceComponentValueObj | null | undefined>({ ...defaultValue});
  const [quantity, setQuantity] = useState<number>(defaultValue ? 1 : 0);
  const [copiedDesc, setCopiedDesc] = useState(false);

  const description = ParseDynamicValues(
    selValue?.description ?? serviceComponent.default_description ?? '',
    quantity
  );
  const unitPrice = selValue?.price
    ? selValue.price
    : serviceComponent.default_price;
  const freeQuantity = selValue?.free_quantity ? selValue.free_quantity : 0;
  const extendedPrice = Math.max(0, quantity - freeQuantity) * unitPrice;
  const availableValueIds = values.map(v => v.id);

  useEffect(() => {
    const descriptionOutput = quantity > 0 ? description : "";
    if(selValue != null && !availableValueIds.includes(selValue.id)) setSelValue(values[0]);
    updateOutputs(index, extendedPrice, descriptionOutput);
  }, [index, selValue, quantity, description, extendedPrice, updateOutputs, projectType, controllerType]);

  function onChange_value(event: SelectChangeEvent<number>) {
    const vId = event.target.value;
    const newSelValue = values.find((v) => v.id === vId) ?? values[0];
    setSelValue({ ...newSelValue });
  }
  function onChange_qty(newValue: string | number) {
    const newQuantity = Number(newValue) < 0 || newValue === "" ? 0 : Number(newValue);
    setQuantity(newQuantity);
  }
  const handleOnClickDesc = () => {
    navigator.clipboard.writeText(description);
    setCopiedDesc(true);
  };
  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };

  return (
    serviceComponent && (
      <TableRow>
        <TableCell>
          <Typography>{serviceComponent.name}</Typography>
        </TableCell>
        <TableCell>
          {values.length > 0 && (
            <FormControl
              fullWidth
              size={"small"}
              sx={{ minWidth: "175px" }}
            >
              <Select
                disabled={quantity > 0 ? false : true}
                value={selValue?.id ? selValue.id : ""}
                onChange={onChange_value}
              >
                {values.map((v, i) => (
                  <MenuItem
                    key={i}
                    value={v.id}
                  >
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell>
          <StyledNumberInput
            type="number"
            size="small"
            variant="outlined"
            value={quantity}
            onChange={(e) => onChange_qty(e.target.value)}
            sx={{ minWidth: "75px" }}
          />
        </TableCell>
        <TableCell>
          <Typography>
            {quantity > 0 && CurrencyFormatter(unitPrice, true)}
            {quantity > 0 && freeQuantity > 0 && (
              <>
                <br />
                <Typography
                  fontSize={13}
                >{`(Qty > ${freeQuantity})`}</Typography>
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {quantity > 0 && CurrencyFormatter(extendedPrice, true)}
          </Typography>
        </TableCell>
        <TableCell>
          {quantity > 0 && (
            <Tooltip
              title={copiedDesc ? "Copied!" : "Click to copy"}
              TransitionComponent={Fade}
              TransitionProps={{
                timeout: {
                  enter: 800,
                  exit: 0,
                },
              }}
              onClose={handleOnCloseCopyTooltip}
            >
              <Typography
                onClick={handleOnClickDesc}
                whiteSpace="pre-line"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    color: colors.blueAccent[500],
                  },
                }}
              >
                {description}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    )
  );
};

export default ServiceComponent;
