import React, { useEffect } from "react";
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { refreshStatus } from "./qbll";

type StatusBadgeProps = {
  position: "right" | "left";
}

const QBLocalLinkStatusBadge = ({ position }: StatusBadgeProps) => {
  const { status: qbllStatus, querying, query } = useSelector((state: RootState) => state.qbll);

  const { connection, appName, version, qbFileName, errorMsg } = qbllStatus;

  const tooltipTitle = () => {
    return (
      <>
        {!qbllStatus && (
          <Typography>
            Pending status response from QbLocalLink application...
          </Typography>
        )}
        <Typography>{appName}</Typography>
        <Typography>{version}</Typography>
        <Typography>
          Status: {connection === "" ? "Fetching" : connection}
        </Typography>
        <Typography>{qbFileName}</Typography>
        {errorMsg === "0x80040408" && 
          <Typography>Unable to connect to QuickBooks.</Typography>
        }
        {errorMsg === "0x80040438" && 
          <Typography>Multiple QuickBooks applications are open.</Typography>
        }
        {errorMsg === "0x80040417" && errorMsg && 
          <Typography>The company file is not open in QuickBooks.</Typography>
        }
        {errorMsg !== "0x80040408" && errorMsg !== "0x80040438" && errorMsg !== "0x80040417" && errorMsg && 
          <Typography>Error: {errorMsg}</Typography>
        }
        <hr />
        <Typography>
          <a
            href="https://storage.googleapis.com/qblocallink/QBLocalLink_Setup.exe"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#86c9b9" }}
          >
            Download
          </a>
        </Typography>
      </>
    );
  };

  const tooltipStatusIcon = () => {
    return qbllStatus != null ? (
      qbllStatus.connection === "Bad" ? (
        <WarningOutlinedIcon color="error" />
      ) : (
        <CheckOutlinedIcon color="success" />
      )
    ) : (
      <QuestionMarkOutlinedIcon />
    );
  };

  useEffect(() => {
    refreshStatus();
  }, []);

  return (
    <Tooltip title={tooltipTitle()}>
      <Box
        sx={{
          marginLeft: "5px",
          float: position,
        }}
      >
        <Chip
          icon={tooltipStatusIcon()}
          label={`QBLocalLink`}
          variant="outlined"
        />
        <IconButton
          size="small"
          disabled={querying}
          onClick={() => refreshStatus()}
        >
          {querying && query.includes("api/status") ? (
            <CircularProgress
              color="info"
              size={20}
            />
          ) : (
            <CachedOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default QBLocalLinkStatusBadge;
