import React, { useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

type FileImportProps = {
  fileType: string;
  mimeTypes: string[];
  maxSizeMB: number;
  onSuccessfulValidation: (file: File) => void;
  onClearFile: () => void;
}

const FileImport = ({ fileType, mimeTypes, maxSizeMB, onSuccessfulValidation, onClearFile }: FileImportProps) => {
  
  const [newFileInput, setNewFileInput] = useState<File | null>();
  const [fileError, setFileError] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    setFileError('');
    setNewFileInput(null);
    const validTypes = mimeTypes;
    if (!validTypes.includes(file.type)) {
      console.log(file.type)
      setFileError(`Please select an accepted file type (${fileType}).`);
      return;
    }
    const maxSize = maxSizeMB * 1024 * 1024; // 1 MB
    if (file.size > maxSize) {
      setFileError('File size should not exceed 1 MB.');
      return;
    }
    setNewFileInput(file);
    onSuccessfulValidation(file);
  }

  const handleFileClear = () => {
    setNewFileInput(null);
    onClearFile();
  };

  return(
    <label htmlFor={`upload-button`}>
      <input
        id={`upload-button`}
        type="file"
        accept={fileType}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Button 
        component="span"
        startIcon={<FolderOutlinedIcon />}
      >
        Choose File...
      </Button>
      {
        newFileInput && 
        <Typography component="span" ml={2}>
          <b>{newFileInput.name}</b>
          <span style={{ marginLeft: "10px" }}>({Math.round(newFileInput.size / 1024)} KB)</span>
        </Typography>
      }
      {
        fileError !== '' && <Typography color="error">{fileError}</Typography>
      }
      {
        newFileInput &&
        <IconButton aria-label="delete" color="error" onClick={handleFileClear}>
          <ClearOutlinedIcon />
        </IconButton>
      }
    </label>
  );
};

export default FileImport;