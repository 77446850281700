import React, { ReactNode } from "react";
import { TableCell } from "@mui/material";

type StyledTextCellProps = {
  alignment?: string;
  sx?: object;
  children?: ReactNode | null;
}

const StyledTableCell = ({ alignment = "top", sx = {}, children = null }: StyledTextCellProps) => {
  return (
    <TableCell sx={{ verticalAlign: alignment, ...sx }}>{children}</TableCell>
  );
};

export default StyledTableCell;
