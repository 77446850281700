import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { OptionValueWithConflicts } from "../../../../database/Products";
import ConflictsTooltip from "../../../../components/ConflictsTooltip";

const RenderOptionValueListItem = (
  value: number,
  listItem: OptionValueWithConflicts,
  selectedColor: OptionValueWithConflicts | undefined,
  selectedKelvinTemp: OptionValueWithConflicts | undefined,
  selectedLumens: OptionValueWithConflicts | undefined,
  selectedSdcm: OptionValueWithConflicts | undefined,
  conflictIds: number[],
) => {
  return (
    conflictIds.includes(listItem.id) ?
    <ConflictsTooltip key={value} placement="right" title={<>
      <Typography>Conflicts with:</Typography>
      {
        [
          selectedSdcm?.conflicts.find(conflict => conflict.option_value_id_2 === listItem.id) ? selectedSdcm.display_name : '',
          selectedKelvinTemp?.conflicts.find(conflict => conflict.option_value_id_2 === listItem.id) ? selectedKelvinTemp.display_name : '',
          selectedLumens?.conflicts.find(conflict => conflict.option_value_id_2 === listItem.id) ? selectedLumens.display_name : '',
          selectedColor?.conflicts.find(conflict => conflict.option_value_id_2 === listItem.id) ? selectedColor.display_name : '',
        ].filter(str => str !== '').map(str => (<Typography key={str} color='red'><b>{str}</b></Typography>))
      }
    </>}>
      <span>
        <MenuItem
          value={listItem.id}
          disabled={true}
        >
          {listItem.display_name}
        </MenuItem>
      </span>
    </ConflictsTooltip>
    :
    <MenuItem key={value} value={value}>
      {listItem.display_name}
    </MenuItem>
  );
};

export default RenderOptionValueListItem;