import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import { hasPermission } from "../../database/Permissions";

type ItemCategoryProps = {
  title: string;
}

const ItemCategory = ({ title }: ItemCategoryProps) => {
  const colors = useTheme().colors;

  return (
    <Typography
      variant="h6"
      color={colors.grey[300]}
      sx={{ m: "15px 0 5px 20px" }}
    >
      {title}
    </Typography>
  );
};

type ItemProps = {
  title: string;
  to: string;
  icon: React.ReactNode;
  selected: string;
  setSelected(selected: string): void;
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
  const colors = useTheme().colors;

  return (
    <MenuItem
      active={selected === to}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(to)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

type SideBarProps = {
  userEmail: string;
}

const Sidebar = ({ userEmail }: SideBarProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(useLocation().pathname);
  const profile = useSelector((state: RootState) => state.profile);

  const toolsSection = [];
  const dataSection = [];
  const adminSection = [];

  if (hasPermission(profile, "TOOL_TAPETOOL"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="Tape Tool"
        to="/tools/tapetool"
        icon={<ArchitectureIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "CHECK_INVENTORY"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="Check Inventory"
        to="/tools/check-inventory"
        icon={<FileDownloadDoneIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "TOOL_COMMISSION_CHECKER"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="Commission Checker"
        to="/tools/commission-checker"
        icon={<PriceCheckOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "TOOL_HLS_XML"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="HLS XML"
        to="/tools/hls-xml"
        icon={"HLS"}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "TOOL_PROG_PRICE_DESC"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="Programming Desc/Price"
        to="/tools/programming-desc-price"
        icon={<HistoryEduIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "EASY_SPEC_TOOL"))
    toolsSection.push(
      <Item
        key={toolsSection.length}
        title="Easy Spec"
        to="/tools/easy-spec"
        icon={<ViewModuleOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "EASY_SPEC_CONFIG"))
    adminSection.push(
      <Item
        key={adminSection.length}
        title="Easy Spec Config"
        to="/admin/easy-spec-config"
        icon={<SettingsIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "PRODUCT_CONFIG"))
    dataSection.push(
      <Item
        key={dataSection.length}
        title="Product Config"
        to="/data/products"
        icon={<StorageOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );
  if (hasPermission(profile, "MODIFY_PERMISSIONS"))
    adminSection.push(
      <Item
        key={adminSection.length}
        title="Permissions"
        to="/admin/permissions"
        icon={<LockOpenOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    );

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                >
                  WEB TOOLS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* USER */}
          {!isCollapsed && (
            <Box
              mb="25px"
              textAlign="center"
            >
              <Chip
                avatar={
                  <Avatar>
                    {userEmail[0]}
                    {userEmail[1]}
                  </Avatar>
                }
                label={userEmail}
                variant="outlined"
                sx={{ fontSize: "15px" }}
              />
            </Box>
          )}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {hasPermission(profile, "LEAD_TIMES") && (
              <Item
                title="Lead Times"
                to="/lead-times"
                icon={<HourglassEmptyOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {toolsSection.length > 0 && <ItemCategory title={"Tools"} />}
            {toolsSection}

            {dataSection.length > 0 && <ItemCategory title={"Data"} />}
            {dataSection}

            {adminSection.length > 0 && <ItemCategory title={"Admin"} />}
            {adminSection}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
