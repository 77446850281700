import React, { ReactNode, useState } from "react";
import { Box, Button, Divider, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { TextField } from "./TextField";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StyledNumberInput from "../../../../components/StyledNumberInput";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { PostgrestError } from "@supabase/supabase-js";
import { QbItemRelation } from "../types";
import { QbItemChannelAssemblyConflicts as QbItemChannelAssemblyConflicts, QbItemChannelAssemblyDependencies as QbItemChannelAssemblyDependencies } from "../../../../database/Products";

type ListItemBoxProps = {
  highlightColor: string;
  children: ReactNode;
}
const ListItemBox = ({ highlightColor, children }: ListItemBoxProps) => (
  <Box display='flex' flexDirection='row' gap={1} alignItems='center' justifyContent='space-between' bgcolor={highlightColor}>
    { children }
  </Box>
);

type RemoveButtonProps = {
  onClick: () => void;
  children: ReactNode;
}
const RemoveButton = ({ onClick, children }: RemoveButtonProps) => (
  <Button variant="text" color="error" onClick={onClick}>
    { children }
  </Button>
);

type QbItemProps = {
  qbItem: QbItemRelation;
  lensOptions: {id: number, name: string}[];
  mountingOptions: {id: number, name: string}[];
  finishOptions: {id: number, name: string}[];
  saveQbRelation: (quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts) => Promise<PostgrestError | null>;
  deleteQbRelation: () => Promise<PostgrestError | null>;
  reloadData: () => Promise<void>;
  displayAlert: (message: string, severity: "success" | "error") => void;
  hideDependenciesAndConflicts?: boolean;
}

const QbItem = ({ qbItem, lensOptions, mountingOptions, finishOptions, saveQbRelation, deleteQbRelation, reloadData, displayAlert, hideDependenciesAndConflicts = false }: QbItemProps) => {
  const colors = useTheme().colors;

  const [quantityInput, setQuantityInput] = useState<number>(qbItem.quantity);
  const [dependencies, setDependencies] = useState(qbItem.dependencies ?? { lenses: [], mountings: [], finishes: [] });
  const [conflicts, setConflicts] = useState(qbItem.conflicts ?? { lenses: [], mountings: [], finishes: [] });
  const [promptConfirmation, setPromptConfirmation] = useState<boolean>(false);

  const handleSave = async () => {
    const error = await saveQbRelation(quantityInput, dependencies, conflicts);
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    reloadData().then(() => {
      displayAlert('Saved successfully!', 'success');
    });
  };

  const handleDelete = async () => {
    const error = await deleteQbRelation()
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    reloadData().then(() => {
      displayAlert('Saved successfully!', 'success');
    });
  };

  const handleAddDependency = (key: string) => {
    const deliminated = key.split('-');
    const optionType = deliminated[0];
    const valueId = Number(deliminated[1]);
    if(!optionType || !valueId) return;
    switch(optionType){
      case 'lens': {
        if(dependencies.lenses.find(l => l === valueId)) return;
        setDependencies({...dependencies, lenses: [...dependencies.lenses, valueId]})
        break;
      }
      case 'mounting': {
        if(dependencies.mountings.find(m => m === valueId)) return;
        setDependencies({...dependencies, mountings: [...dependencies.mountings, valueId]})
        break;
      }
      case 'finish': {
        if(dependencies.finishes.find(f => f === valueId)) return;
        setDependencies({...dependencies, finishes: [...dependencies.finishes, valueId]})
        break;
      }
      default: { return; }
    }
  }

  const handleAddConflict = (key: string) => {
    const deliminated = key.split('-');
    const optionType = deliminated[0];
    const valueId = Number(deliminated[1]);
    if(!optionType || !valueId) return;
    switch(optionType){
      case 'lens': {
        if(conflicts.lenses.find(l => l === valueId)) return;
        setConflicts({...conflicts, lenses: [...conflicts.lenses, valueId]})
        break;
      }
      case 'mounting': {
        if(conflicts.mountings.find(m => m === valueId)) return;
        setConflicts({...conflicts, mountings: [...conflicts.mountings, valueId]})
        break;
      }
      case 'finish': {
        if(conflicts.finishes.find(f => f === valueId)) return;
        setConflicts({...conflicts, finishes: [...conflicts.finishes, valueId]})
        break;
      }
      default: { return; }
    }
  }

  const handleRemoveDependency = (key: string) => {
    console.log('here')
    const deliminated = key.split('-');
    const optionType = deliminated[0];
    const valueId = Number(deliminated[1]);
    if(!optionType || !valueId) return;
    switch(optionType){
      case 'lens': {
        setDependencies({ ...dependencies, lenses: dependencies.lenses.filter(l => l !== valueId) });
        break;
      }
      case 'mounting': {
        setDependencies({ ...dependencies, mountings: dependencies.mountings.filter(m => m !== valueId) });
        break;
      }
      case 'finish': {
        setDependencies({ ...dependencies, finishes: dependencies.finishes.filter(f => f !== valueId) });
        break;
      }
      default: { return; }
    }
  }
  
  const handleRemoveConflict = (key: string) => {
    const deliminated = key.split('-');
    const optionType = deliminated[0];
    const valueId = Number(deliminated[1]);
    if(!optionType || !valueId) return;
    switch(optionType){
      case 'lens': {
        setConflicts({ ...conflicts, lenses: conflicts.lenses.filter(l => l !== valueId) });
        break;
      }
      case 'mounting': {
        setConflicts({ ...conflicts, mountings: conflicts.mountings.filter(m => m !== valueId) });
        break;
      }
      case 'finish': {
        setConflicts({ ...conflicts, finishes: conflicts.finishes.filter(f => f !== valueId) });
        break;
      }
      default: { return; }
    }
  }

  const qtyUpdated = qbItem.quantity !== quantityInput;
  const dependenciesUpdated = (
    (
      // Dependencies Added?
      !dependencies.lenses.every(l => qbItem.dependencies?.lenses.includes(l)) || 
      !dependencies.mountings.every(m => qbItem.dependencies?.mountings.includes(m)) || 
      !dependencies.finishes.every(f => qbItem.dependencies?.finishes.includes(f))
    )
    ||
    (
      // Dependencies Removed?
      qbItem.dependencies !== null ? 
      (
        !qbItem.dependencies?.lenses.every(l => dependencies.lenses.includes(l)) || 
        !qbItem.dependencies?.mountings.every(m => dependencies.mountings.includes(m)) || 
        !qbItem.dependencies?.finishes.every(f => dependencies.finishes.includes(f))
      )
      : false
    )  
  );
  const conflictsUpdated = (
    (
      // Conflicts Added?
      !conflicts.lenses.every(l => qbItem.conflicts?.lenses.includes(l)) || 
      !conflicts.mountings.every(m => qbItem.conflicts?.mountings.includes(m)) || 
      !conflicts.finishes.every(f => qbItem.conflicts?.finishes.includes(f))
    )
    ||
    (
      // Conflicts Removed?
      qbItem.conflicts !== null ? 
      (
        !qbItem.conflicts?.lenses.every(l => conflicts.lenses.includes(l)) || 
        !qbItem.conflicts?.mountings.every(m => conflicts.mountings.includes(m)) || 
        !qbItem.conflicts?.finishes.every(f => conflicts.finishes.includes(f))
      )
      : false
    )  
  );

  const updated = (
    qtyUpdated ||
    dependenciesUpdated ||
    conflictsUpdated
  );

  const dependencyOptionsList = [
    ...lensOptions.filter(lensOption => ![...conflicts.lenses, ...dependencies.lenses].includes(lensOption.id)).map(lensOption => {
      return <MenuItem key={'dependency-lens-'+lensOption.id} value={'lens-'+lensOption.id}>{`(Lens) ${lensOption.name}`}</MenuItem>
    }),
    ...mountingOptions.filter(mountOption => ![...conflicts.mountings, ...dependencies.mountings].includes(mountOption.id)).map(mountOption => {
      return <MenuItem key={'dependency-mounting-'+mountOption.id} value={'mounting-'+mountOption.id}>{`(Mounting) ${mountOption.name}`}</MenuItem>
    }),
    ...finishOptions.filter(finishOption => ![...conflicts.finishes, ...dependencies.finishes].includes(finishOption.id)).map(finishOption => {
      return <MenuItem key={'dependency-finish-'+finishOption.id} value={'finish-'+finishOption.id}>{`(Finish) ${finishOption.name}`}</MenuItem>
    }),
  ];
  const conflictOptionsList = [
    ...lensOptions.filter(lensOption => ![...conflicts.lenses, ...dependencies.lenses].includes(lensOption.id)).map(lensOption => {
      return <MenuItem key={'conflict-lens-'+lensOption.id} value={'lens-'+lensOption.id}>{`(Lens) ${lensOption.name}`}</MenuItem>
    }),
    ...mountingOptions.filter(mountOption => ![...conflicts.mountings, ...dependencies.mountings].includes(mountOption.id)).map(mountOption => {
      return <MenuItem key={'conflict-mounting-'+mountOption.id} value={'mounting-'+mountOption.id}>{`(Mounting) ${mountOption.name}`}</MenuItem>
    }),
    ...finishOptions.filter(finishOption => ![...conflicts.finishes, ...dependencies.finishes].includes(finishOption.id)).map(finishOption => {
      return <MenuItem key={'conflict-finish-'+finishOption.id} value={'finish-'+finishOption.id}>{`(Finish) ${finishOption.name}`}</MenuItem>
    }),
  ];

  return (
    <Box display='flex' flexDirection='row' gap={2} alignItems='stretch'>
      <TextField 
        label='ListID'
        value={qbItem.list_id}
        disabled
      />
      <TextField 
        label='Item Name'
        value={qbItem.full_name}
        disabled
      />
      <StyledNumberInput 
        label='Quantity'
        type='number'
        value={quantityInput}
        onChange={(e) => setQuantityInput(Number(e.target.value))}
        sx={{ 
          maxWidth: '125px',
          '& .MuiInputBase-input': {
            backgroundColor: qtyUpdated ? colors.updatedValue.yellow : ''
          }
        }}
      />
      {
        !hideDependenciesAndConflicts &&
        <Divider orientation='vertical' flexItem />
      }
      <Box display={!hideDependenciesAndConflicts ? 'flex' : 'none'} flexDirection='row' gap={2}>
        <Box display='flex' flexDirection='column' gap={1}>
          {
            (dependencies.lenses.length > 0 || dependencies.mountings.length > 0 || dependencies.finishes.length > 0) &&
            <Typography>Depends On:</Typography>
          }
          {
            dependencies.lenses.map(lens => (
              <ListItemBox key={'depLens'+lens} highlightColor={!qbItem.dependencies?.lenses.includes(lens) ? colors.updatedValue.yellow : ''}>
                <Typography>(Lens) {lensOptions.find(l => l.id === lens)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveDependency('lens-'+lens)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          {
            dependencies.mountings.map(mounting => (
              <ListItemBox key={'depMount'+mounting} highlightColor={!qbItem.dependencies?.mountings.includes(mounting) ? colors.updatedValue.yellow : ''}>
                <Typography>(Mounting) {mountingOptions.find(m => m.id === mounting)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveDependency('mounting-'+mounting)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          {
            dependencies.finishes.map(finish => (
              <ListItemBox key={'depFinish'+finish} highlightColor={!qbItem.dependencies?.finishes.includes(finish) ? colors.updatedValue.yellow : ''}>
                <Typography>(Finish) {finishOptions.find(f => f.id === finish)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveDependency('finish-'+finish)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          <Select value={0} onChange={(e) => handleAddDependency(e.target.value.toString())}>
            <MenuItem value={0}>Add Dependency</MenuItem>
            {
              dependencyOptionsList
            }
          </Select>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box display='flex' flexDirection='column' gap={1}>
        {
            (conflicts.lenses.length > 0 || conflicts.mountings.length > 0 || conflicts.finishes.length > 0) &&
            <Typography>Conflicts With:</Typography>
          }
          {
            conflicts.lenses.map(lens => (
              <ListItemBox key={'confLens'+lens} highlightColor={!qbItem.conflicts?.lenses.includes(lens) ? colors.updatedValue.yellow : ''}>
                <Typography>(Lens) {lensOptions.find(l => l.id === lens)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveConflict('lens-'+lens)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          {
            conflicts.mountings.map(mounting => (
              <ListItemBox key={'confMount'+mounting} highlightColor={!qbItem.conflicts?.mountings.includes(mounting) ? colors.updatedValue.yellow : ''}>
                <Typography>(Mounting) {mountingOptions.find(m => m.id === mounting)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveConflict('mounting-'+mounting)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          {
            conflicts.finishes.map(finish => (
              <ListItemBox key={'confFinish'+finish} highlightColor={!qbItem.conflicts?.finishes.includes(finish) ? colors.updatedValue.yellow : ''}>
                <Typography>(Finish) {finishOptions.find(f => f.id === finish)?.name ?? ''}</Typography>
                <RemoveButton onClick={() => handleRemoveConflict('finish-'+finish)}>Remove</RemoveButton>
              </ListItemBox>
            ))
          }
          <Select value={0} onChange={(e) => handleAddConflict(e.target.value.toString())}>
            <MenuItem value={0}>Add Conflict</MenuItem>
            { 
              conflictOptionsList 
            }
          </Select>
        </Box>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box display='flex' flexDirection='row' gap={1} alignItems='flex-start'>
        <Button 
          color='secondary' 
          variant='contained' 
          startIcon={<SaveOutlinedIcon />}
          disabled={!updated}
          onClick={() => handleSave()}
        >
          Save
        </Button>
        <Button 
          color='error'
          onClick={() => setPromptConfirmation(true)}
        >
          Remove
        </Button>
      </Box>
      <Dialog
        open={promptConfirmation}
        onClose={() => setPromptConfirmation(false)}
        aria-labelledby="import-dialog-title"
        aria-describedby="import-dialog-description"
        maxWidth='lg'
      >
        <DialogTitle id="import-dialog-title">
          Remove link to QuickBooks Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="import-dialog-description">
            Are you sure you want to remove the link to QuickBooks Item "<b>{qbItem.full_name}</b>"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPromptConfirmation(false)} >
            Cancel
          </Button>
          <Button 
            onClick={() => handleDelete()} 
            color="error"
            variant="contained"
          >
            Yes, Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QbItem;