import React, { useState } from "react";
import {
  Divider,
  FormHelperText,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import roundDigits from "../../../../global_functions/RoundDigits";
import { GetLedTapePartNumber, LedTape } from "../../../../database/Products";
import { Mm2In } from "../../../../global_functions/UomConversion";
import TextCell from "./TextCell";

type TapeSpecsProps = {
  ledTape: LedTape;
}

const TapeSpecs = ({ ledTape }: TapeSpecsProps) => {
  const colors = useTheme().colors;

  const [clickedToCopy, setClickedToCopy] = useState<boolean>(false);

  const partNumber = GetLedTapePartNumber(ledTape);
  const specs = ledTape.specs;

  const handleOnClickPartNumber = () => {
    navigator.clipboard.writeText(partNumber);
    setClickedToCopy(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setClickedToCopy(false);
  };

  return (
    <Box component={Paper} width='500px' p={2}>      
      <Typography>LED Tape Specs</Typography>
      <Divider sx={{ marginBottom: '15px' }} />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TextCell><b>Spec Sheet</b></TextCell>
            <TextCell>
              {ledTape.spec_sheet_url && (
                <Link
                  href={`${ledTape.spec_sheet_url}`}
                  target="_blank"
                >{`Spec Sheet`}</Link>
              )}
            </TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Name</b></TextCell>
            <TextCell>{ledTape.name}</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Part Number</b></TextCell>
            <Tooltip
              title={clickedToCopy ? "Copied!" : "Click to copy"}
              TransitionComponent={Fade}
              TransitionProps={{
                timeout: {
                  enter: 800,
                  exit: 0,
                },
              }}
              onClose={handleOnCloseCopyTooltip}
            >
              <TableCell
                onClick={handleOnClickPartNumber}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    color: colors.blueAccent[500],
                  },
                }}
              >
                <Typography>{partNumber}</Typography>
              </TableCell>
            </Tooltip>
          </TableRow>
          <TableRow>
            <TextCell><b>Input Voltage</b></TextCell>
            <TextCell>{ledTape.input_voltage}</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Watts/ft</b></TextCell>
            <TextCell>{specs.watts_per_ft} watts / Ft</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Max Run Length</b></TextCell>
            <TextCell>{specs.max_run_ft} Ft</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Max Cut Intervals</b></TextCell>
            <TextCell>{specs.max_cut_intervals}</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Cut Interval Length</b></TextCell>
            <TextCell>{specs.cut_interval_mm} mm ({Mm2In(specs.cut_interval_mm ?? 0)}")</TextCell>
          </TableRow>
          <TableRow>
            <TextCell><b>Lumens / Ft</b></TextCell>
            <TextCell>{specs.lumens_per_ft} lm</TextCell>
          </TableRow>
            <TableRow>
              <TextCell><b>Beam Spread</b></TextCell>
              <TextCell>{specs.beam_spread}°</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Color Temperature</b></TextCell>
              <TextCell>{ledTape.kelvinTemp?.display_name ?? ''}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>CRI</b></TextCell>
              <TextCell>{specs.cri}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>IP Rating</b></TextCell>
              <TextCell>IP {specs.ip_rating}</TextCell>
            </TableRow>            
            <TableRow>
              <TextCell><b>Width</b></TextCell>
              {specs.endcap_width_mm ? (
                <TextCell>
                  {`${specs.width_mm} mm (+${roundDigits(
                    specs.endcap_width_mm - (specs.width_mm ?? 0),
                    2
                  )} mm w/ endcaps)`}
                </TextCell>
              ) : (
                <TextCell>{specs.width_mm} mm</TextCell>
              )}
            </TableRow>
            <TableRow>
              <TextCell><b>Height</b></TextCell>
              <TextCell>{specs.height_mm} mm</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Diode</b></TextCell>
              <TextCell>{specs.diode}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Diode Brand</b></TextCell>
              <TextCell>{specs.diode_brand}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Diodes / Ft</b></TextCell>
              <TextCell>{specs.diodes_per_ft}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Binning Tolerance</b></TextCell>
              <TextCell>{specs.binning_tolerance}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Dimming Options</b></TextCell>
              <TextCell>{specs.dimming_options ? 'Yes' : 'No'}</TextCell>
            </TableRow>
            <TableRow>
              <TextCell><b>Physical Temperature Range</b></TextCell>
              <TextCell>{specs.temp_range_low}F TO {specs.temp_range_high}F</TextCell>
            </TableRow>
        </TableBody>
      </Table>
      <Box
        marginTop="25px"
        textAlign="center"
      >
        <Typography>Polynomial Wattage Calculation</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>B</TableCell>
              <TableCell>C</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{ledTape.specs.a}</TableCell>
              <TableCell>{ledTape.specs.b}</TableCell>
              <TableCell>{ledTape.specs.c}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <FormHelperText sx={{ textAlign: "center" }}>
          {`Wattage Per Length = (A * (Length)^2) + (B * Length) + C)`}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default TapeSpecs;