import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import FileImport from "../../../../../../../components/FileImport";
import { supabase_products } from "../../../../../../../database/supabaseClient";
import { LedTapeSpecRecord } from "../../../../../../../database/Products";
import { parseNullableNumber, parseNullableSDCM, parseNumber } from "../../../../utils";

type SpecsImportProps = {
  requiredFields: string[];
  onImported: () => void;
  onClose: () => void;
}

type CSVRecord = {
  [key: string]: string;
};

type CSVValidationResult = {
  isValid: boolean;
  errorMessage?: string;
  data?: CSVRecord[];
};

const SpecsImport = ({ requiredFields, onImported, onClose }: SpecsImportProps) => {
  const colors = useTheme().colors;

  const [open, setOpen] = useState<boolean>(true);
  const [parsing, setParsing] = useState<boolean>(false);
  const [parsingError, setParsingError] = useState<string>('');
  const [specRecords, setSpecRecords] = useState<(Pick<LedTapeSpecRecord, 'id'> & Partial<LedTapeSpecRecord>)[] | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [erroredRecords, setErroredRecords] = useState<Partial<LedTapeSpecRecord>[]>([]);

  const handleClose = () => {
    resetAllStates();
    setOpen(false);
    onClose();
  }

  const resetAllStates = () => {
    if(specRecords) setSpecRecords(null);
    if(erroredRecords) setErroredRecords([]);
    if(progress > 0) setProgress(0);
    if(totalRecords > 0) setTotalRecords(0);
    if(parsingError !== '') setParsingError('');
  }

  const handleFileProvided = async (file: File) => {
    resetAllStates();

    setParsing(true);
    parseCSV(file).then(async (result) => {
      setParsing(false);
      if(result.errorMessage){
        setParsingError(result.errorMessage);
      }
      if(!result.data) return;
      const newSpecRecords = convertToSpecRecords(result.data);
      setSpecRecords(newSpecRecords);
    });
  }

  const convertToSpecRecords = (csvRecs: CSVRecord[]): (Pick<LedTapeSpecRecord, 'id'> & Partial<LedTapeSpecRecord>)[] => {
    const specRecords: (Pick<LedTapeSpecRecord, 'id'> & Partial<LedTapeSpecRecord>)[] = csvRecs.map((csvRec) => {
      const record: (Pick<LedTapeSpecRecord, 'id'> & Partial<LedTapeSpecRecord>) = {
        id: parseNumber(csvRec.id),
        max_run_ft: parseNumber(csvRec.max_run_ft),
        max_cut_intervals: parseNumber(csvRec.max_cut_intervals),
        watts_per_ft: parseNullableNumber(csvRec.watts_per_ft) ?? undefined,
        efficacy: parseNullableNumber(csvRec.efficacy),
        lumens_per_ft: parseNullableNumber(csvRec.lumens_per_ft),
        wavelength_low_nm: parseNullableNumber(csvRec.wavelength_low_nm),
        wavelength_high_nm: parseNullableNumber(csvRec.wavelength_high_nm),
        sdcm: parseNullableSDCM(csvRec.sdcm),
        width_mm: parseNumber(csvRec.width_mm),
        height_mm: parseNumber(csvRec.height_mm),
        endcap_width_mm: parseNullableNumber(csvRec.endcap_width_mm),
        endcap_height_mm: parseNullableNumber(csvRec.endcap_height_mm),
        cut_interval_mm: parseNumber(csvRec.cut_interval_mm),
        beam_spread: parseNumber(csvRec.beam_spread),
        cri: csvRec.cri,
        ip_rating: parseNullableNumber(csvRec.ip_rating),
        diodes_per_ft: parseNullableNumber(csvRec.diodes_per_ft),
        diode_brand: csvRec.diode_brand,
        diode: csvRec.diode,
        binning_tolerance: csvRec.binning_tolerance,
        dimming_options: (csvRec.dimming_options === 'TRUE'),
        temp_range_low: parseNullableNumber(csvRec.temp_range_low),
        temp_range_high: parseNullableNumber(csvRec.temp_range_high),
        a: parseNumber(csvRec.a),
        b: parseNumber(csvRec.b),
        c: parseNumber(csvRec.c),
        max_lead_len_ft: parseNullableNumber(csvRec.max_lead_len_ft)
      }
      return record;
    });
    return specRecords;
  }

  const updateDatabase = async () => {
    if(!canImport || !specRecords) return;
    setTotalRecords(specRecords.length);
    for(let i = 0; i < specRecords.length; i++){
      const specRecord = specRecords[i];
      const { data, error } = await supabase_products
      .from('led_tape_specs')
      .update(specRecord)
      .eq('id', specRecord.id);
      if(error){
        console.error(error);
        setErroredRecords(prev => [...prev, specRecord]);
        setProgress(((i + 1) / specRecords.length) * 100);
        continue;
      }
      console.log('Record with id', specRecord.id, 'updated successfully:', data);
      setProgress(((i + 1) / specRecords.length) * 100);
    }
    onImported();
  }

  const parseCSV = (file: File): Promise<CSVValidationResult> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target?.result as string;
        const rows = text.split('\n').map((row) => row.split('\r')[0].split(','));
        const header = rows[0];
  
        // Check if all required columns are present
        for (const column of requiredFields) {
          if (!header.includes(column)) {
            resolve({
              isValid: false,
              errorMessage: `Missing required column: ${column}`,
            });
            return;
          }
        }
  
        // Parse data rows into objects
        const data: CSVRecord[] = rows.slice(1).map((row) => {
          const record: CSVRecord = {};
          row.forEach((cell, index) => {
            record[header[index]] = cell;
          });
          return record;
        });

        const scrubbedData = data.map(record => {
          const keys = Object.keys(record);
          const newObj: CSVRecord = {};
          keys.forEach(key => { if(requiredFields.includes(key)) newObj[key] = record[key] });
          return newObj;
        });
  
        resolve({
          isValid: true,
          data: scrubbedData.filter(record => Object.keys(record).length > 1), // Remove empty rows
        });
      };
      reader.readAsText(file);
    });
  };

  const canImport = (
    !parsing && 
    progress === 0 && 
    totalRecords === 0 && 
    parsingError === '' && 
    specRecords !== null &&
    specRecords.length > 0
  );

  const canCancel = (!parsing && progress === 0);

  return (
    <Dialog
      open={open}
      onClose={() => { return; }}
      aria-labelledby="import-dialog-title"
      aria-describedby="import-dialog-description"
      maxWidth='lg'
    >
      <DialogTitle id="import-dialog-title">
        Import Specs Data
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="import-dialog-description">
          Upload a .CSV file to update the existing LED Tape specs. Each row represents product specs for a unique combination of option values.
        </DialogContentText>
        {
          progress === 0 && totalRecords === 0 &&
          <Box my={2}>
            <FileImport 
              fileType=".csv"
              mimeTypes={['text/csv', 'application/vnd.ms-excel']}
              maxSizeMB={2}
              onSuccessfulValidation={handleFileProvided}
              onClearFile={() => { resetAllStates() }}
            />
          </Box>
        }
        <Box>
          {
            parsing &&
            <CircularProgress />
          }
          {
            parsingError !== '' && 
            <Typography color='red'>{parsingError}</Typography>
          }
          {
            !parsing && specRecords && specRecords.length > 0 && progress === 0 &&
            <Box>
              <Typography mb={1}>Data Prevew ({specRecords.length} total)</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.primary[400] }}>
                    {
                      requiredFields.map(field => (
                          <TableCell key={field}><b>{field}</b></TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    specRecords.slice(0, 5).map(record => (
                      <TableRow key={record.id}>
                        {
                          requiredFields.map(field => (
                            <TableCell key={field+record.id}>
                              {record[field as keyof LedTapeSpecRecord]?.toString()}
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          }
          {
            totalRecords > 0 &&
            <Box my={2}>
              <Typography>{Math.round(progress)}% ({Math.round(totalRecords * (progress/100))}/{totalRecords})</Typography>
              <LinearProgress variant="determinate" color="secondary" value={progress} />
              {
                erroredRecords.length > 0 &&
                <Box my={2}>
                  <Typography color='error'><b>Failed Ids:</b></Typography>
                  <Typography color='error'>{erroredRecords.map(rec => rec.id).toString()}</Typography>
                </Box>
              }
            </Box>
          }
        </Box>
      </DialogContent>
      {
        progress === 100 ?
        <DialogActions>
          <Button onClick={handleClose} variant='contained'>
            Finish
          </Button>
        </DialogActions>
        :
        <DialogActions>
          <Button 
            onClick={handleClose}
            disabled={!canCancel}
          >
            Cancel
          </Button>
          <Button 
            onClick={updateDatabase} 
            color="secondary"
            variant="contained"
            disabled={!canImport}
          >
            Import
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

export default SpecsImport;