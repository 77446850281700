import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { default as TextInput } from "./ConfigTextInput";
import { BaseComboRecord, ControllerTypeRecord, ProjectTypeRecord } from "../../../../database/AppsDescGenToolTypes";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { insertRecord, updateRecord } from "../data";

export type ConfigProjectControllerCombinationFormProps = {
  record: BaseComboRecord;
  isNew: boolean;
  onCancel: () => void;
  onSave: (record: BaseComboRecord, updateDatabase: () => Promise<PostgrestSingleResponse<null> | undefined>) => Promise<void>;
  projectTypes: ProjectTypeRecord[];
  controllerTypes: ControllerTypeRecord[];
}

const ConfigProjectControllerCombinationForm = ({
  record: recordData,
  isNew,
  onCancel,
  onSave,
  projectTypes,
  controllerTypes
}: ConfigProjectControllerCombinationFormProps) => {
  const cleanedRecordData = { ...recordData };
  delete cleanedRecordData.name;
  const [record, setRecord] = useState(cleanedRecordData);
  const [modified, setModified] = useState(false);
  const [selProjType, setSelProjType] = useState(
    projectTypes.find((v) => v.id === record.project_type_id),
  );
  const [selControllerType, setSelControllerType] = useState(
    controllerTypes.find((v) => v.id === record.controller_type_id),
  );

  function updateField(fieldName: keyof(BaseComboRecord), fieldValue: string | number | boolean) {
    const newRecord = {...record, [fieldName]: fieldValue};
    setRecord(newRecord);
    setModified(true);
  }

  function updateProjectType(event: SelectChangeEvent<number>) {
    const projectTypeId = event.target.value;
    const projectType = projectTypes.find((v) => v.id === projectTypeId);
    if(!projectType) return;
    setSelProjType(projectType);
    setRecord((prev) => ({ ...prev, project_type_id: projectType.id }));
    setModified(true);
  }
  function updateControllerType(event: SelectChangeEvent<number>) {
    const controllerTypeId = event.target.value;
    const controllerType = controllerTypes.find((v) => v.id === controllerTypeId);
    if(!controllerType) return;
    setSelControllerType(controllerType);
    setRecord((prev) => ({ ...prev, controller_type_id: controllerType.id }));
    setModified(true);
  }

  const handleInsert = async () => {
    return insertRecord(record, "base_combos");
  }

  const handleUpdate = async () => {
    return updateRecord(record, "base_combos");
  }

  return (
    <Box sx={{ m: "25px 0", width: "50%" }}>
      <Box marginBottom="20px" sx={{ "& > *": { marginRight: "10px" } }}>
        <Button
          variant="outlined"
          color="info"
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          startIcon={<SaveOutlinedIcon />}
          disabled={!modified}
          onClick={() => onSave(record, isNew ? handleInsert : handleUpdate)}
        >
          Save
        </Button>
      </Box>

      {record !== null && (
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Switch
                checked={record.active ? true : false}
                color="secondary"
              />
            }
            label={record.active ? "Enabled" : "Disabled"}
            onChange={(e, checked) => updateField("active", checked)}
            sx={{ marginBottom: "15px" }}
          />
          <FormControl
            fullWidth
            size={"small"}
            sx={{ minWidth: "175px", marginBottom: "20px" }}
          >
            <InputLabel id="project-type-label">Project Type</InputLabel>
            <Select
              value={selProjType ? selProjType.id : ""}
              label={"Project Type"}
              labelId="project-type-label"
              onChange={updateProjectType}
            >
              {projectTypes?.map((pt, i) => (
                <MenuItem key={i} value={pt.id}>
                  {pt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            size={"small"}
            sx={{ minWidth: "175px", marginBottom: "20px" }}
          >
            <InputLabel id="controller-type-label">Controller Type</InputLabel>
            <Select
              value={selControllerType ? selControllerType.id : ""}
              label={"Controller Type"}
              labelId="controller-type-label"
              onChange={updateControllerType}
            >
              {controllerTypes?.map((pt, i) => (
                <MenuItem key={i} value={pt.id}>
                  {pt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextInput
            label="Price"
            value={record.price}
            fieldName="price"
            updateValue={updateField}
          />
          <TextInput
            label="Description"
            value={record.description}
            fieldName="description"
            updateValue={updateField}
          />
        </Box>
      )}
    </Box>
  );
};

export default ConfigProjectControllerCombinationForm;
