import React from "react";
import StyledTextField from "../../../components/StyledTextField";
import { LeadTime } from "../types";

type TextInputProps = {
  label: string;
  value: string | number;
  fieldName: keyof LeadTime;
  updateValue: <K extends keyof LeadTime>(field: K, newValue: LeadTime[K]) => void;
  number?: boolean;
  helperText?: string;
}

const TextInput = ({
  label,
  value,
  fieldName,
  updateValue,
  number = false,
  helperText,
}: TextInputProps) => {
  return (
    <StyledTextField
      label={label}
      value={value}
      size="small"
      type={number ? "number" : "text"}
      onChange={(e) => updateValue(fieldName, e.target.value)}
      sx={{ marginBottom: "12px" }}
      autoComplete="off"
      helperText={helperText}
    />
  );
};

export default TextInput;