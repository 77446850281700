import React from "react";
import { useTheme } from "@mui/material";
import { Box, Container } from "@mui/system";
import { supabase } from "./database/supabaseClient";
import { LoginButton } from "./components/ButtonCTA";
import Header from "./components/Header";

const Auth = () => {
  const colors = useTheme().colors;

  async function signInWithAzure() {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email",
      },
    });

    if(error) console.error(error.message);
  }

  return (
    <Container>
      <Container maxWidth="sm">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          p="50px"
          margin="50px"
          borderRadius="4px"
          sx={{
            backgroundColor: colors.primary[400],
            boxShadow: "3px 3px 5px rgb(0,0,0,0.35)",
          }}
        >
          <Header
            title="Kelvix Web Tools"
            subtitle="Web interface for internal tools used at Kelvix"
          />
          <LoginButton variant="contained" onClick={signInWithAzure}>
            Login
          </LoginButton>
        </Box>
      </Container>
    </Container>
  );
};

export default Auth;
