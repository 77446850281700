import React, { useState } from "react";
import { Box, FormControlLabel, Switch, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { default as TextInput } from "./ConfigTextInput";
import DataTable from "../../../../components/DataTable";
import ConfigServiceComponentValueConflictForm, { ConfigServiceComponentValueConflictFormProps } from "./ConfigServiceComponentValueConflictForm";
import ConfigServiceComponentValueDependencyForm, { ConfigServiceComponentValueDependencyFormProps } from "./ConfigServiceComponentValueDependencyForm";
import { ControllerTypeRecord, ProjectTypeRecord, ServiceComponentValueConflictRecord, ServiceComponentValueDependencyRecord, ServiceComponentValueRecord } from "../../../../database/AppsDescGenToolTypes";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { ServiceComponentValueObj, insertRecord, updateRecord } from "../data";

export type ConfigServiceComponentValueFormProps = {
  record: ServiceComponentValueObj;
  isNew: boolean;
  onCancel: () => void;
  onSave: (record: ServiceComponentValueRecord, updateDatabase: () => Promise<PostgrestSingleResponse<null> | undefined>) => Promise<void>;
  reloadData: () => Promise<void>;
  projectTypes: ProjectTypeRecord[];
  controllerTypes: ControllerTypeRecord[];
}

const ConfigServiceComponentValueForm = ({
  record: recordData,
  onCancel,
  onSave,
  reloadData,
  projectTypes,
  controllerTypes
}: ConfigServiceComponentValueFormProps) => {
  const cleanedRecordData = { ...recordData };
  const [record, setRecord] = useState<ServiceComponentValueObj>(cleanedRecordData);
  const [modified, setModified] = useState(false);

  const isNew = Object.keys(recordData).length <= 1;

  function updateField(fieldName: keyof(ServiceComponentValueObj), fieldValue: string | number | boolean) {
    const newRecord = {...record, [fieldName]: fieldValue};
    setRecord(newRecord);
    setModified(true);
  }

  const handleInsert = async () => {
    const { service_component_value_conflicts:_, service_component_value_dependencies:__, ...newRecord } = record;
    return insertRecord(newRecord, "service_component_values");
  }

  const handleUpdate = async () => {
    const { service_component_value_conflicts:_, service_component_value_dependencies:__, ...updatedRecord } = record;
    return updateRecord(updatedRecord, "service_component_values");
  }

  return (
    <Box>
      <Box sx={{ m: "25px 0", width: "50%" }}>
        <Box
          marginBottom="20px"
          sx={{ "& > *": { marginRight: "10px" } }}
        >
          <Button
            variant="outlined"
            color="info"
            startIcon={<ArrowBackOutlinedIcon />}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveOutlinedIcon />}
            disabled={!modified}
            onClick={() => onSave(record, isNew ? handleInsert : handleUpdate)}
          >
            Save
          </Button>
        </Box>
        {record !== null && (
          <Box
            display="flex"
            flexDirection="column"
          >
            <FormControlLabel
              control={
                <Switch
                  checked={record.active ? true : false}
                  color="secondary"
                />
              }
              label={record.active ? "Enabled" : "Disabled"}
              onChange={(e, checked) => updateField("active", checked)}
              sx={{ marginBottom: "15px" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={record.default ? true : false}
                  color="secondary"
                />
              }
              label="Default Value"
              onChange={(e, checked) => updateField("default", checked)}
              sx={{ marginBottom: "15px" }}
            />
            <TextInput
              label="Name"
              value={record.name}
              fieldName="name"
              updateValue={updateField}
            />
            <TextInput
              label="Price"
              value={record.price ? record.price.toString() : ""}
              fieldName="price"
              updateValue={updateField}
            />
            <TextInput
              label="Free Quantity"
              value={record.free_quantity}
              fieldName="free_quantity"
              updateValue={updateField}
              number={true}
            />
            <TextInput
              label="Description"
              value={record.description}
              fieldName="description"
              updateValue={updateField}
              helperText="Dynamic Values Example: {MULTIPLY_QUANTITY(n)}"
            />
          </Box>
        )}
      </Box>
      {!isNew && (
        <DataTable<ServiceComponentValueConflictRecord>
          title="VALUE CONFLICTS"
          records={recordData.service_component_value_conflicts}
          idInjections={{ value_id: recordData.id }}
          displayColumns={[
            {
              display: "Project Type",
              field: "project_type_id",
              lookupData: projectTypes,
            },
            {
              display: "Controller Type",
              field: "controller_type_id",
              lookupData: controllerTypes,
            },
            { display: "Notes", field: "notes" },
          ]}
          configForm={(customProps: ConfigServiceComponentValueConflictFormProps) => (
            <ConfigServiceComponentValueConflictForm {...customProps} projectTypes={projectTypes} controllerTypes={controllerTypes} />
          )}
          cancelConfig={onCancel}
          reloadData={reloadData}
          resetView={false}
        />
      )}
      {!isNew && (
        <DataTable<ServiceComponentValueDependencyRecord>
          title="VALUE DEPENDENCIES"
          records={recordData.service_component_value_dependencies}
          idInjections={{ value_id: recordData.id }}
          displayColumns={[
            {
              display: "Project Type",
              field: "project_type_id",
              lookupData: projectTypes,
            },
            {
              display: "Controller Type",
              field: "controller_type_id",
              lookupData: controllerTypes,
            },
            { display: "Notes", field: "notes" },
          ]}
          configForm={(customProps: ConfigServiceComponentValueDependencyFormProps) => (
            <ConfigServiceComponentValueDependencyForm {...customProps} projectTypes={projectTypes} controllerTypes={controllerTypes} />
          )}
          cancelConfig={onCancel}
          reloadData={reloadData}
          resetView={false}
        />
      )}
    </Box>
  );
};

export default ConfigServiceComponentValueForm;
