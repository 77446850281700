import { supabase_apps } from "../../../database/supabaseClient";
import { BaseComboRecord } from "../../../database/AppsDescGenToolTypes";
import { ControllerTypeRecord } from "../../../database/AppsDescGenToolTypes";
import { ProjectTypeRecord } from "../../../database/AppsDescGenToolTypes";
import { ServiceComponentRecord } from "../../../database/AppsDescGenToolTypes";
import { ServiceComponentValueRecord } from "../../../database/AppsDescGenToolTypes";
import { ServiceComponentValueConflictRecord } from "../../../database/AppsDescGenToolTypes";
import { ServiceComponentValueDependencyRecord } from "../../../database/AppsDescGenToolTypes";
import { Database } from "../../../database/database.types";

type TableNames = keyof Database['apps_desc_gen_tool']['Tables'];
type RecordType = BaseComboRecord | ControllerTypeRecord | ProjectTypeRecord | ServiceComponentRecord | ServiceComponentValueRecord | ServiceComponentValueConflictRecord | ServiceComponentValueDependencyRecord;

export type ServiceComponentObj = ServiceComponentRecord & {
  service_component_values: ServiceComponentValueObj[];
};

export type ServiceComponentValueObj = ServiceComponentValueRecord & {
  service_component_value_conflicts: ServiceComponentValueConflictRecord[];
  service_component_value_dependencies: ServiceComponentValueDependencyRecord[];
};

const DB_TBL_SERVICE_COMPONENTS: TableNames = "service_components";
const DB_TBL_SERVICE_COMPONENT_VALUES: TableNames = "service_component_values";
const DB_TBL_SERVICE_COMPONENT_VALUE_CONFLICTS: TableNames = "service_component_value_conflicts";
const DB_TBL_SERVICE_COMPONENT_VALUE_DEPENDENCIES: TableNames = "service_component_value_dependencies";

// Generic function to get records from any table with optional activeOnly filter
export const getRecords = async <T extends RecordType>(tableName: string, activeOnly = false, orderBy = '', ascending = true): Promise<T[]> => {
  let query = supabase_apps.from(tableName).select("*");
  if (activeOnly) query = query.eq("active", true);
  if (orderBy !== '') query.order(orderBy, { ascending: ascending});
  const { data, error } = await query;
  if (error)console.error(error);
  return data ?? [];
};

export const getServiceComponentRecords = async (): Promise<ServiceComponentObj[]> => {
  const { data, error } = await supabase_apps
    .from(DB_TBL_SERVICE_COMPONENTS)
    .select(
    `*, ${DB_TBL_SERVICE_COMPONENT_VALUES}(*, ${DB_TBL_SERVICE_COMPONENT_VALUE_CONFLICTS}(*), ${DB_TBL_SERVICE_COMPONENT_VALUE_DEPENDENCIES}(*))`
    )
    .order("display_index", { ascending: true });
  if (error) {
    console.error(error);
    return [];
  }
  return data as ServiceComponentObj[];
};

export const insertRecord = async (record: RecordType, tableName: TableNames) => {
  let response;
  try {
    response = await supabase_apps.from(tableName).insert(record);
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateRecord = async (record: RecordType, tableName: TableNames) => {
  let response;
  try {
    response = await supabase_apps.from(tableName).update(record).match({ id: record.id });
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  return response;
};
  