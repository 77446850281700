import React, { useState, useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Collapse,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { PermissionRecord, UserPermissionRecord } from "../../../database/PublicTypes";
import { Profile } from "../../../database/Profile";
import { getPermissions } from "./data";

type CellProps = {
  sx?: object;
  children?: React.ReactNode;
}

const Cell = ({ sx = {}, children = "" }: CellProps) => {
  return (
    <TableCell sx={{ ...sx }}>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

type PermissionProps = {
  permission: PermissionRecord;
  userPermissions: UserPermissionRecord[];
  profiles: Profile[];
  onProfileSelected: (profileId: string | null) => void;
}

const Permission = ({
  permission,
  userPermissions,
  profiles,
  onProfileSelected,
}: PermissionProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const usersGranted = userPermissions.filter(
    (userPermission) => userPermission.permission_name === permission.name,
  );

  return (
    <TableRow
      hover
      //onClick={(e) => setOpen(!open)}
      //sx={{'& :hover': {cursor: 'pointer'}}}
    >
      <Cell sx={{ verticalAlign: "top" }}>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Cell>
      <Cell sx={{ verticalAlign: "top" }}>{permission.name}</Cell>
      <Cell sx={{ verticalAlign: "top" }}>{permission.description}</Cell>
      <TableCell sx={{ minWidth: "250px" }}>
        {usersGranted.length}
        {usersGranted.length > 0 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              {usersGranted.map((userPermission) => (
                <ListItem disablePadding key={userPermission.id}>
                  <ListItemButton
                    onClick={() =>
                      onProfileSelected(
                        profiles.find((p) => p.id === userPermission.user_id)?.id ?? null
                      )
                    }
                  >
                    <ListItemText
                      primary={
                        profiles.find((p) => p.id === userPermission.user_id)?.full_name ?? ""
                      }
                    />
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </TableCell>
    </TableRow>
  );
};

type PermissionListProps = {
  onProfileSelected: (profileId: string | null) => void;
  userPermissions: UserPermissionRecord[];
  profiles: Profile[];
}

const PermissionsList = ({ onProfileSelected, userPermissions, profiles }: PermissionListProps) => {
  const colors = useTheme().colors;
  const [permissions, setPermissions] = useState<PermissionRecord[]>([]);

  useEffect(() => {
    const query = async () => {
      const data = await getPermissions();
      setPermissions(data);
    }
    query();
  }, []);

  return (
    <Box>
      <TableContainer sx={{ marginBottom: "50px" }}>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <Cell />
              <Cell>
                <strong>Name</strong>
              </Cell>
              <Cell>
                <strong>Description</strong>
              </Cell>
              <Cell>
                <strong>Users</strong>
              </Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((permission, index) => (
                <Permission
                  key={index}
                  permission={permission}
                  userPermissions={userPermissions}
                  profiles={profiles}
                  onProfileSelected={onProfileSelected}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PermissionsList;
