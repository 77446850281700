export function EnsureSingle<T>(value: T | T[] | null | undefined): T | null{
  if(Array.isArray(value)) {
    if(value.length > 0) return value[0];
    return null;
  }
  return value ?? null;
}

export function EnsureArray<T>(value: T | T[] | null): T[] {
  if (Array.isArray(value)) {
    return value;
  }
  if (value === null) {
    return [];
  }
  return [value];
}

export function RemoveNullsAndUndefineds<T>(item: (T | null | undefined)[]): T[] {
  return item.filter((i): i is T => i !== null && i !== undefined);
}