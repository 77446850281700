import { TxnInventoryData } from "../../../qbll/types";
import { GetUomConversionPrice, GetUomConversionQuantity } from "../../../global_functions/UomConversion";

const newQtLineBoiler: QuoteLine = {
  QTEVERSION: "1",
  QTETYPE: "PRJ",
  DIVMFG: "KELV",
  MFG: "KELV",
  APPROVETYPE: "UNK",
  QTENUMBER: "",
  LINENUMBER: 0,
  LABELTYPE: "BLNK",
  QUANTITY: 0,
  TYPE: "",
};

const buildQuoteData = (
  data: TxnInventoryData, 
  quoteVersion: number, 
  zeroedPricing: boolean, 
  blankDescription: boolean
): BuildResult => {
  const timeCreated = new Date(data.timeCreated);
  const goodThru = new Date(data.timeCreated);
  goodThru?.setMonth(goodThru.getMonth() + 6);

  const quoteData = {
    QUOTEHDR: {
      QTENUMBER: data.refNumber,
      QTEVERSION: Math.ceil(Number(quoteVersion)),
      QTETYPE: "PRJ",
      ENTRYDATE: timeCreated.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      ENTRYTIME: timeCreated.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      GOODTHRUDATE: goodThru.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      QTESTATUS: "ENT",
      JOBNAME: data.projectName,
      JOBCITY: data.shipAddress.city,
      JOBSTATEPROVINCE: data.shipAddress.state,
    },
    QUOTECUST: {
      QTENUMBER: data.refNumber,
      QTEVERSION: Math.ceil(Number(quoteVersion)),
      QTETYPE: "PRJ",
      CUSTNAME: data.customer.fullName,
      CUSTADDRLINE1: data.shipAddress.line1,
      CUSTADDRLINE2: data.shipAddress.line2,
      CUSTADDRLINE3: data.shipAddress.line3,
      CUSTCITY: data.shipAddress.city,
      CUSTSTATEPROVINCE: data.shipAddress.state,
    },
    QUOTELINE: [] as QuoteLine[],
  };

  const warnings: Warning[] = [];

  const skipLineItems = [
    "SUBTOTAL",
    "Void Balance",
    "Sales Discount - 1",
    "Sales Discount - 2",
    "Sales Discount - 3",
    "Sales Discount - 4",
    "Sales Discount - 5",
    "Warranty",
    "SAMPLE DISCOUNT",
    "SAMPLE ACCOMODATION",
    "SHIPPING",
    "Commission Adjustment",
  ];

  data.lineItems?.forEach((lineItem) => {
    if (skipLineItems.includes(lineItem.fullName)) return;

    const newLine: QuoteLine = { ...newQtLineBoiler };
    newLine.QTENUMBER = data.refNumber;
    const uom = lineItem.unitOfMeasure;
    const uomo = lineItem.unitOfMeasureOverride;
    newLine.LINENUMBER = quoteData.QUOTELINE.length + 1;

    if (lineItem.listId === "") {
      newLine.LABELTYPE = "BLNK";
    } else {
      newLine.LABELTYPE = "REG";
      if(lineItem.fullName) newLine.DESCRIPTION = lineItem.fullName;

      if (zeroedPricing) {
        newLine.DSPEACH = 0;
      } else {
        if (lineItem.type === "Group") {
          if (lineItem.priceTotal > 0 && lineItem.quantity > 0) {
            newLine.DSPEACH = lineItem.priceTotal / lineItem.quantity;
          }
        } else {
          newLine.DSPEACH = GetUomConversionPrice(uom, uomo, lineItem.priceRate ?? 0);
        }
      }
      if (!blankDescription) {
        newLine.LINECOMMENT = lineItem.description;
        if (newLine.LINECOMMENT.length > 500)
          newLine.LINECOMMENT = newLine.LINECOMMENT.slice(0, 500);
      }
    }

    newLine.QUANTITY = GetUomConversionQuantity(uom, uomo, lineItem.quantity);
    newLine.QUANTITY = Math.ceil(newLine.QUANTITY);

    const typeDescription = lineItem.typeDescription ?? "";
    const typeLength = typeDescription.length;
    newLine.TYPE = typeDescription.slice(0, 20);
    if (typeLength > 20) {
      warnings.push({
        line: newLine.LINENUMBER,
        description: `'Type' field exceeds maximum character limit (${typeLength}/20).`,
      });
    }

    quoteData.QUOTELINE.push(newLine);
  });

  return {quoteData, warnings};
}

export default buildQuoteData;