import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tokens } from "../theme";

type StyledNumberInputProps = TextFieldProps & {
  maxWidth?: string;
}

const StyledNumberInput = styled(TextField)<StyledNumberInputProps>(({ theme, maxWidth }) => {
  const colors = tokens(theme.palette.mode);
  return {
    maxWidth: maxWidth || "75px",
    "& label.Mui-focused": {
      color: colors.blueAccent[500],
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.blueAccent[500],
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.blueAccent[500],
      },
    },
  };
});

export default StyledNumberInput;
