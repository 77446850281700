import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { LeadTime } from "./types";
import { deleteRecord, insertRecord, updateRecord } from "./data";
import TextInput from "./Components/TextInput";
import { isSuccess } from "../../global_functions/StatusCode";

type ConfigProps ={
  targetLeadTime: LeadTime;
  onComplete: (success: boolean) => void;
  onCancel: () => void;
  userId: string;
  nextDisplayOrder: number;
}

const Config = ({ targetLeadTime, onComplete, onCancel, userId, nextDisplayOrder}: ConfigProps) => {
  const [modified, setModified] = useState<boolean>(false);
  const [leadTime, setLeadTime] = useState<LeadTime>({
    ...targetLeadTime,
    state: targetLeadTime.state ?? "normal",
  });

  const onChangeValue = <K extends keyof LeadTime>(field: K, newValue: LeadTime[K]) => {
    if (!modified) setModified(true);
    setLeadTime((prev) => ({
      ...prev,
      [field]: newValue,
      modified_by: userId,
    }));
  };

  const handleSaveConfig = async () => {
    leadTime.last_modified = new Date().toISOString();
    leadTime.modified_by = userId;
    const statusCode = leadTime.new ? await insertRecord(leadTime) : await updateRecord(leadTime);
    onComplete(isSuccess(statusCode));
  };

  const handleDeleteConfig = async (leadTime: LeadTime) => {
    const statusCode = await deleteRecord(leadTime);
    onComplete(isSuccess(statusCode));
  };

  return (
    <Box sx={{ m: "25px 0", width: "50%" }}>
      <Box marginBottom="20px">
        <Button
          variant="outlined"
          color="info"
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </Box>

      {leadTime  && (
        <Box
          display="flex"
          flexDirection="column"
        >
          <TextInput
            label="Name"
            value={leadTime.name ?? ""}
            fieldName="name"
            updateValue={onChangeValue}
          />
          <TextInput
            label="Item Code(s)"
            value={leadTime.item_code ?? ""}
            fieldName="item_code"
            updateValue={onChangeValue}
          />
          <TextInput
            label="Lead Time"
            value={leadTime.lead_time ?? ""}
            fieldName="lead_time"
            updateValue={onChangeValue}
          />
          <FormControl sx={{ marginBottom: "12px" }}>
            <InputLabel id="state-select">State</InputLabel>
            <Select
              labelId="state-select"
              value={leadTime.state ?? "normal"}
              label="State"
              onChange={(e) => onChangeValue("state", e.target.value)}
            >
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="longer_than_usual">Longer Than Usual</MenuItem>
            </Select>
          </FormControl>
          <TextInput
            label="Notes"
            value={leadTime.notes ?? ""}
            fieldName="notes"
            updateValue={onChangeValue}
          />
          <TextInput
            label="Display Order"
            value={leadTime.display_order ?? nextDisplayOrder}
            fieldName="display_order"
            number={true}
            helperText="Lower values will be displayed higher on the list."
            updateValue={onChangeValue}
          />
          <Box sx={{ "& > *": { marginRight: "10px" } }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<SaveOutlinedIcon />}
              disabled={!modified}
              onClick={() => handleSaveConfig()}
            >
              Save
            </Button>
            {!leadTime.new && (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForeverOutlinedIcon />}
                onClick={() => handleDeleteConfig(leadTime)}
                sx={{ marginLeft: "15px" }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Config;
