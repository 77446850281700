// https://medium.com/@mokshi/how-to-persist-custom-material-ui-theme-light-dark-using-redux-toolkit-and-local-storage-in-481f4399eb4b
import { createSlice, Dispatch } from "@reduxjs/toolkit";

const initialState = {
  darkMode: Boolean(JSON.parse(localStorage.getItem("darkMode") ?? "false")),
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkMode = !state.darkMode;
    },
  },
});

// The function below is called a thunk and allows us to perform async logic.
// It can be dispatched like a regular action: `dispatch(toggleTheme())`.
// This will call the thunk with the `dispatch` function as the first argument.
// Async code can then be executed and other actions can be dispatched
export const asyncToggleTheme = () => (dispatch: Dispatch) => {
  const isDarkMode = !!JSON.parse(localStorage.getItem("darkMode") ?? "false");
  localStorage.setItem("darkMode", JSON.stringify(!isDarkMode));
  dispatch(toggleTheme());
};

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
