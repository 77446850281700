import { TableCell, Typography, useTheme } from "@mui/material";
import React from "react";

export const HeadCell = ({ children }: { children: React.ReactNode; }) => {
  const colors = useTheme().colors;
  return(
    <TableCell 
      scope="row"
      sx={{
        bgcolor: colors.primary[400]
      }}
    >
      <Typography><b>{ children }</b></Typography>
    </TableCell>
  );
};

export const CodeCell = ({red, children }: { red: boolean, children: React.ReactNode; }) => {
  const colors = useTheme().colors;
  return(
    <TableCell
      sx={{
        bgcolor: red ? colors.redAccent[900] : 'none'
      }}
    >
      <Typography>{ children }</Typography>
    </TableCell>
  );
};

export const Cell = ({red, pl, minWidth, children }: { red?: boolean, pl?: string, minWidth?: string, children: React.ReactNode; }) => {
  const colors = useTheme().colors;
  return(
    <TableCell 
      sx={{ 
        minWidth: minWidth ?? "",
        bgcolor: red ? colors.redAccent[900] : 'none',
      }}
    >
      <Typography sx={{ paddingLeft: pl ? pl : '' }}>{ children }</Typography>
    </TableCell>
  );
};