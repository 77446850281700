import React, { useState, useEffect, useCallback } from "react";
import { Alert, Box, Container, Snackbar, SnackbarOrigin } from "@mui/material";
import { LeadTime } from "./types";
import Header from "../../components/Header";
import LeadTimes from "./LeadTimes";
import Config from "./Config";
import { getLeadTimes } from "./data";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { hasPermission } from "../../database/Permissions";

const Index = () => {
  const [leadTimes, setLeadTimes] = useState<LeadTime[]>([]);
  const [configLeadTime, setConfigLeadTime] = useState<LeadTime | null>(null);
  const [displaySuccess, setDisplaySuccessAlert] = useState<boolean>(false);
  const [displayError, setDisplayErrorAlert] = useState<boolean>(false);

  const profile = useSelector((state: RootState) => state.profile);
  const configGranted = hasPermission(profile, "LEAD_TIMES_CONFIG");

  const queryData = useCallback(async () => {
    const data = await getLeadTimes();
    setLeadTimes(data);
  }, [])

  useEffect(() => {
    queryData();
  }, []);

  const handleCloseSuccessAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setDisplaySuccessAlert(false);
  };
  const handleCloseErrorAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setDisplayErrorAlert(false);
  };
  const handleCompleteConfig =  async (success = false) => {
    await queryData();
    if(success) setDisplaySuccessAlert(true);
    else setDisplayErrorAlert(true);
    setConfigLeadTime(null);
  };
  const handleCancelConfig = () => {
    setConfigLeadTime(null);
  };

  const snackBarPosition: SnackbarOrigin = { vertical: "bottom", horizontal: "center" };
  const nextDisplayOrder = Math.max(...leadTimes.map((leadTime) => leadTime.display_order)) + 1;

  return (
    <Box m="20px">
      <Header
        title="LEAD TIMES"
        subtitle="Create, View, and Update lead times for products (or anything)."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        {configLeadTime ?
          <Config
            targetLeadTime={configLeadTime}
            onComplete={handleCompleteConfig}
            onCancel={handleCancelConfig}
            userId={profile.id}
            nextDisplayOrder={nextDisplayOrder}
          />
          :
          <LeadTimes
            leadTimes={leadTimes}
            configGranted={configGranted}
            onSelectedLeadTime={setConfigLeadTime}
          />
        }
        <Snackbar
          open={displaySuccess}
          autoHideDuration={3500}
          onClose={handleCloseSuccessAlert}
          anchorOrigin={snackBarPosition}
        >
          <Alert
            onClose={handleCloseSuccessAlert}
            severity="success"
            sx={{ width: "100%", fontSize: "20px" }}
          >
            Updated Lead Time data successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={displayError}
          autoHideDuration={3500}
          onClose={handleCloseErrorAlert}
          anchorOrigin={snackBarPosition}
        >
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            sx={{ width: "100%", fontSize: "20px" }}
          >
            There was an error updating the Lead Time data.
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Index;
