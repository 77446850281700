import React, { useState, useEffect, useCallback } from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import Header from "../../../components/Header";
import { default as TabPanel } from "../../../components/StyledTabPanel";
import PermissionsList from "./PermissionsList";
import Users from "./Users";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { Profile } from "../../../database/Profile";
import { getPermissions, getProfileRecords, getUserPermissionRecords } from "./data";
import { PermissionRecord, UserPermissionRecord } from "../../../database/PublicTypes";
import { hasPermission } from "../../../database/Permissions";

const Permissions = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [selectedProfile, setSelectedProfile] = useState<string | null | undefined>(null);
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [permissions, setPermissions] = useState<PermissionRecord[]>([]);
  const [userPermissions, setUserPermissions] = useState<UserPermissionRecord[]>([]);

  const profile = useSelector((state: RootState) => state.profile);
  if (!hasPermission(profile, "MODIFY_PERMISSIONS")) window.location.href = "/";

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  };

  const reloadData = useCallback(async () => {
    const [ profileRecords, permissionRecords, userPermissionRecords ] = await Promise.all([
      getProfileRecords(),
      getPermissions(),
      getUserPermissionRecords()
    ]);
  
    const profiles: Profile[] = profileRecords.map(p => {
      const userPerms = userPermissionRecords.filter(userPerm => userPerm.user_id === p.id);
      const permissionNames = userPerms.map(userPerm => userPerm.permission_name ?? "");
      const profile = {...p, permissions: permissionNames};
      return profile;
    });

    setProfiles(profiles);
    setUserPermissions(userPermissionRecords);
    setPermissions(permissionRecords);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    reloadData();
  }, [reloadData]);

  const onProfileSelected = (profileId: string | null) => {
    //const profile = profiles.find(p => p.id === profileId);
    setTabValue(1);
    setSelectedProfile(profileId);
  };

  return (
    <Box m="20px">
      <Header
        title="PERMISSIONS"
        subtitle="Permissions grant access for specific functions/tools."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={changeTab}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Permissions" id="0" />
            <Tab label="Users" id="1" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue}  index={0} >
          <PermissionsList 
            onProfileSelected={onProfileSelected}
            userPermissions={userPermissions}
            profiles={profiles}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} >
          <Users
            profiles={profiles}
            editProfileId={selectedProfile}
            onProfileSelected={onProfileSelected}
            permissions={permissions}
            userPermissions={userPermissions}
            reloadData={reloadData}
          />
        </TabPanel>
      </Container>
    </Box>
  );
};

export default Permissions;
