import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Link,
  TextField,
  useTheme,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import { HasDiodeImaging } from "../../../../global_functions/DiodeImaging";
import { LedTape } from "../../../../database/Products";
import { ChannelAssembly } from "../types";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TextCell from "./TextCell";

type CompatibleChannelProps = {
  ledTape: LedTape;
  channelAssemblies: ChannelAssembly[];
}

const CompatibleChannel = ({ ledTape, channelAssemblies }: CompatibleChannelProps) => {
  const colors = useTheme().colors;

  const [filter, setFilter] = useState<string>("");

  const specs = ledTape.specs;
  const maxTapeWidth = specs.endcap_width_mm ?? specs.width_mm ?? 0;
  const compatibleChannel = channelAssemblies.filter(c => c.channel.width_internal_mm >= maxTapeWidth);
  const filteredList =
    filter === "" ?
    compatibleChannel :
    compatibleChannel.filter(
      channel => channel.name.toLowerCase().includes(filter.toLowerCase())
    );

  // 1 FT = 304.8 mm
  const diodeSpacing_mm = 304.8 / (specs.diodes_per_ft ?? 0);

  return (
    <Box component={Paper} width='500px' p={2}>
      <Typography>Compatible Channel</Typography>
      <Divider sx={{ marginBottom: '10px' }} />
      <TableContainer>
        <Box display='flex' alignItems='flex-end' mb={2}>
          <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField
            label="Search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Box style={{ display: "flex", marginLeft: "25px" }}>
            <Box width='20px' height='20px' mr='5px' bgcolor={colors.tableRowHighlight["bad"]}></Box>
            <Typography>= Diode Imaging</Typography>
          </Box>
        </Box>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TableCell align="left">
                <Typography fontWeight={700}>Channel</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={700}>
                  {`Internal Width (>= ${maxTapeWidth} mm)`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={700}>Spec Sheet URL</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList?.map((c, i) => (
              <TableRow
                key={i}
                sx={{
                  backgroundColor:
                    !ledTape.force_no_diode_imaging &&
                    HasDiodeImaging(diodeSpacing_mm, c.channel.height_internal_mm ?? 0)
                      ? colors.tableRowHighlight["bad"]
                      : "none",
                }}
              >
                <TextCell>{c.name}</TextCell>
                <TextCell>{c.channel.width_internal_mm} mm</TextCell>
                <TextCell>
                  <Link
                    href={`${c.spec_sheet_url}`}
                    target="_blank"
                  >{`(Spec Sheet)`}</Link>
                </TextCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompatibleChannel;