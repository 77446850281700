import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { SpecSheet } from "../types";

type SpecSheetUploadProps = {
  specSheet: SpecSheet;
  handleUpload: (specSheet: SpecSheet, file: File) => void;
}

const SpecSheetUpload = ({ specSheet, handleUpload }: SpecSheetUploadProps) => {
  const [newFileInput, setNewFileInput] = useState<File | null>();
  const [fileError, setFileError] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileError('');
      setNewFileInput(null);
      const validTypes = ['application/pdf'];
      if (!validTypes.includes(file.type)) {
        setFileError('Please select an accepted file type (PDF).');
        return;
      }
      const maxSize = 10 * 1024 * 1024; // 1 MB
      if (file.size > maxSize) {
        setFileError('File size should not exceed 10 MB.');
        return;
      }
      // Normalize the file name
      const fileExtension = file.name.split('.').pop();
      const baseName = file.name.slice(0, file.name.lastIndexOf('.'));
      const normalizedBaseName = baseName.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
      const normalizedFileName = normalizedBaseName + "." + fileExtension;
      console.log('Normalized File Name:', normalizedBaseName);
      // Create a new File object with the normalized file name
      const fileDupe = new File([file], normalizedFileName, {
        type: file.type, // Preserve the original MIME type
        lastModified: file.lastModified, // Optionally preserve the last modified date
      });      
      setNewFileInput(fileDupe);
    }
  };

  const handleFileClear = () => {
    setNewFileInput(null);
  };

  const handleUploadClick = () => {
    if(!newFileInput) return;
    handleUpload(specSheet, newFileInput);
  }

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="10px" ml={3} maxWidth="400px">
      <label htmlFor={`upload-button-spec-sheet`}>
        <input
          id={`upload-button-spec-sheet`}
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <Button 
          component="span"
          startIcon={<FolderOutlinedIcon />}
        >
          Choose File...
        </Button>
        {
          newFileInput && 
          <Typography component="span" ml={2}>
            <b>{newFileInput.name}</b>
            <span style={{ marginLeft: "10px" }}>({Math.round(newFileInput.size / 1024)} KB)</span>
          </Typography>
        }
        {
          fileError !== '' && <Typography color="error">{fileError}</Typography>
        }
        {
          newFileInput &&
          <IconButton aria-label="delete" color="error" onClick={handleFileClear}>
            <ClearOutlinedIcon />
          </IconButton>
        }
      </label>
      <Button 
        variant="contained"
        color={(!newFileInput) ? "info" : "success"} 
        onClick={handleUploadClick}
        disabled={!newFileInput}
      >
        Upload
      </Button>
    </Box>
  );
};

export default SpecSheetUpload;