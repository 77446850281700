// https://medium.com/@mokshi/how-to-persist-custom-material-ui-theme-light-dark-using-redux-toolkit-and-local-storage-in-481f4399eb4b
import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "./reducers/themeSlice";
import qbllSlice from "./reducers/qbllSlice";
import profileSlice from "./reducers/profileSlice";

export const store = configureStore({
  reducer: {
    theme: themeSlice,
    qbll: qbllSlice,
    profile: profileSlice,
  },
});

// Define RootState type based on the store's reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
