import React from "react";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import AttributesList, { Content } from "../../../../components/AttributesList";
import ProductImage from "./ProductImage";
import { ChannelAssembly, ChannelAssemblyOptionType, ChannelAssemblyOptions } from "../../../../database/Products";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

type ChannelAssemblySpecsProps = {
  channelAssembly: ChannelAssembly;
  options: ChannelAssemblyOptions;
  profileImage: string;
  altText: string;
  onChangeOption: (option: ChannelAssemblyOptionType, ezCode: string) => void;
}

const ChannelAssemblySpecs = ({ channelAssembly, options, profileImage, altText, onChangeOption }: ChannelAssemblySpecsProps) => {
  options.lenses = options.lenses.sort((a, b) => a.display_order - b.display_order);
  options.mountings = options.mountings.sort((a, b) => a.display_order - b.display_order);
  options.finishes = options.finishes.sort((a, b) => a.display_order - b.display_order);
  const attributesTableContent: Content = {
    headers: {
      attributeLabel: 'Option',
      valuesLabel: 'Values (Label / Easy Spec Code)',
      align: 'left'
    },
    attributes: [
      {
        name: "lens",
        label: "Lenses",
        values: options.lenses.map(lens => ({
          name: lens.name,
          highlight: channelAssembly.lens.name === lens.name,
          code: lens.easy_spec_code
        }))      
      },
      {
        name: "mounting",
        label: "Mountings",
        values: options.mountings.map(mounting => ({
          name: mounting.name,
          highlight: channelAssembly.mounting.name === mounting.name,
          code: mounting.easy_spec_code
        }))      
      },
      {
        name: "finish",
        label: "Finish",
        values: options.finishes.map(finish => ({
          name: finish.name,
          highlight: channelAssembly.finish.name === finish.name,
          code: finish.easy_spec_code
        }))      
      },
    ]
  };

  return (
    <Card
      sx={{ 
        padding: '15px',
        width: '100%',
        maxWidth: '450px'
      }}
    >
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Typography variant="h5">
            {channelAssembly.name}
          </Typography>
          <Typography mb={2} fontSize={12}>
            Channel Assembly
          </Typography>
        </Box>
        <Box>
          <ProductImage 
            fileName={profileImage}
            altText={altText}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography>Specs</Typography>
        <Button 
          variant="text"
          endIcon={<OpenInNewOutlinedIcon />} 
          target="_blank" 
          disabled={channelAssembly.spec_sheet_url === null}
          href={channelAssembly.spec_sheet_url ?? ''}
        >
          Spec Sheet
        </Button>
      </Box>
      <Divider />
      <Box mt={1} mb={2} px={2}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography>Length</Typography>
          <Typography>{channelAssembly.channel.length_m} Meters</Typography>
        </Box>
        <Box my={1}>
          <Typography>Internal</Typography>
          <Divider />
          <Box pl={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography>Width</Typography>
              <Typography>{channelAssembly.channel.width_internal_mm} mm</Typography>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography>Height</Typography>
              <Typography>{channelAssembly.channel.height_internal_mm} mm</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography>External</Typography>
          <Divider />
          <Box pl={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography>Width</Typography>
              <Typography>{channelAssembly.channel.width_external_mm} mm</Typography>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography>Height</Typography>
              <Typography>{channelAssembly.channel.height_external_mm} mm</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <AttributesList 
        content={attributesTableContent}
        clickableValues={true}
        onClickValue={(attributeName: string, value: string) => onChangeOption(attributeName as ChannelAssemblyOptionType, value)}
      />
    </Card>
  );
};

export default ChannelAssemblySpecs;