import React, { Dispatch, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardContent, Divider, Fade, List, ListItem, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { updateChannelAssemblyAttributeDetails } from "../../data";
import { ChannelAssemblyProductWithEasySpec } from "../../../../../database/EasySpec";
import { IsChannelAssemblyOptionType, extractVariableNames } from "../../../../../database/Products";
import AttributesList, { Content } from "../../../../../components/AttributesList";

const formulaValidation = (value: string) => {
  if(value === '') return 'Pleaes provide a formula for each.';
  const match = value.match(/\{([^}]+)\}/);
  if(!match) return; // No inner params detected.
  const param = match[1];
  if(IsChannelAssemblyOptionType(param)) return;
  return `Cannot find option or attribute slug with name: ${param}`;
};

type AttributeConfigProps = {
  channelAssembly: ChannelAssemblyProductWithEasySpec | null;
  reloadTapeData: () => Promise<void>;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const AttributeConfig = ({ channelAssembly, reloadTapeData, displayAlert }: AttributeConfigProps) => {
  const colors = useTheme().colors;

  const [lensFormulaInput, setLensFormulaInput] = useState<string>(channelAssembly?.easySpec?.lens_formula ?? '');
  const [mountingFormulaInput, setMountingFormulaInput] = useState<string>(channelAssembly?.easySpec?.mounting_formula ?? '');
  const [finishFormulaInput, setFinishFormulaInput] = useState<string>(channelAssembly?.easySpec?.finish_formula ?? '');
  const [attributeConfigChanged, setAttributeConfigChanged] = useState<boolean>(false);
  const [copiedSlug, setCopiedSlug] = useState<boolean>(false);

  const handleFormulaInputChange = (newValue: string, setter: Dispatch<React.SetStateAction<string>>) => {
    if (/^[a-z0-9{} ]+$/i.test(newValue) || newValue === "") {
      setter(newValue);
      if(!attributeConfigChanged) setAttributeConfigChanged(true);
    }
  }

  const allFormulasProvided = (
    lensFormulaInput !== '' &&
    mountingFormulaInput !== '' &&
    finishFormulaInput !== ''
  );

  const handleSaveClick = async () => {
    if(!attributeConfigChanged || errors.size > 0 || !allFormulasProvided) return;
    if(!channelAssembly) return;
    const successful = await updateChannelAssemblyAttributeDetails(channelAssembly.id, {
      lens_formula: lensFormulaInput,
      mounting_formula: mountingFormulaInput,
      finish_formula: finishFormulaInput
    });
    if(successful)
      reloadTapeData().then(() => {
        displayAlert('Successfully updated the attribute formulas!', 'success');
      });
    else
      displayAlert('Error updating attribute formulas.', 'error');
  }

  const handleClickToCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopiedSlug(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedSlug(false);
  };

  let hasDuplicateSlugs = false;
  const hasSlugs: Set<string> = new Set();
  const allInputSlugs = [
    ...extractVariableNames(lensFormulaInput),
    ...extractVariableNames(mountingFormulaInput),
    ...extractVariableNames(finishFormulaInput)
  ];
  allInputSlugs.forEach(slug => {
    if(hasSlugs.has(slug)){
      hasDuplicateSlugs = true;
    }else{
      hasSlugs.add(slug)
    }
  });

  const missingRequiredSlugs: Set<string> = new Set();
  const requiredSlugs: Set<string> = new Set(['lens', 'mounting', 'finish']);
  requiredSlugs.forEach(slug => { if(!hasSlugs.has(slug)) missingRequiredSlugs.add(slug) });

  const errors: Set<string> = new Set();
  if(hasDuplicateSlugs) errors.add('Formulas contain duplicate option slugs.');
  if(missingRequiredSlugs.size > 0) errors.add(`Missing required option slugs: ${[...missingRequiredSlugs].join(', ')}`)
  errors.add(formulaValidation(lensFormulaInput) ?? '');
  errors.add(formulaValidation(mountingFormulaInput) ?? '');
  errors.add(formulaValidation(finishFormulaInput) ?? '');
  errors.delete('');
  const formulaErrorsArr = [ ...errors ];

  const options = channelAssembly?.options;
  const attributesTableContent: Content = {
    headers: {
      attributeLabel: 'Attribute',
      valuesLabel: 'Values (Label / Easy Spec Code)',
      align: 'left'
    },
    attributes: [
      {
        name: "lens",
        label: "Lenses",
        values: options?.lenses.map(lens => ({
          name: lens.name,
          code: lens.easy_spec_code
        })) ?? []
      },
      {
        name: "mounting",
        label: "Mountings",
        values: options?.mountings.map(mounting => ({
          name: mounting.name,
          code: mounting.easy_spec_code
        })) ?? []
      },
      {
        name: "finish",
        label: "Finish",
        values: options?.finishes.map(finish => ({
          name: finish.name,
          code: finish.easy_spec_code
        })) ?? []
      },
    ]
  };
  
  const hover = {
    "&:hover": {
      cursor: "pointer",
      color: colors.blueAccent[500],
    }
  };

  const missingEasySpecCodes: { option: string; value: string; }[] = [];
  attributesTableContent.attributes.forEach(attr => {
    attr.values.forEach(value => { if(value.code === '') missingEasySpecCodes.push({ option: attr.name, value: value.name })})
  });

  return (
    <Card sx={{ marginTop: "20px" }}>
      <CardContent>
        <Typography variant="h5">Attribute Config</Typography>
        <Divider />
        <Box p="10px" maxWidth="950px">
          <Typography>Each Channel Assembly needs to have code output for Lens, Mounting, and Finish.</Typography>
          <Box my={1}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlinedIcon />}
              >
                Available Options
              </AccordionSummary>
              <AccordionDetails>
                <AttributesList content={attributesTableContent} />
              </AccordionDetails>
            </Accordion>
            {
              missingEasySpecCodes.length > 0 &&
              <Alert severity="warning" sx={{ marginTop: '15px'}}>
                <Typography>The following Option Values do not have an Easy Spec code.</Typography>
                {
                  missingEasySpecCodes.map((opt, i) => (
                    <Typography key={i}>{opt.option}: {opt.value}</Typography>
                  ))
                }
              </Alert>
            }
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <Box width="100%" padding={1}>
              <Typography>Series</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                disabled
                value={channelAssembly?.channel?.assembly_sku_code ?? ''}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>Lens</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={lensFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setLensFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>Mounting</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={mountingFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setMountingFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>Finish</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={finishFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setFinishFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" sx={{ paddingTop: "30px" }}>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                disabled={!attributeConfigChanged || errors.size > 0  || !allFormulasProvided}
                onClick={handleSaveClick}
                sx={{ marginTop: "10px" }}
              >
                Save
              </Button>
            </Box>
          </Box>
          {
            errors.size > 0 &&
            <List dense={true} >
              {
                formulaErrorsArr.map((err, i) => (
                  <ListItem key={i} sx={{ color: 'red'}}>• {err}</ListItem>
                ))
              }
            </List>
          }
          <Box mt={4}>
            <Typography>Channel Assembly Option slugs:</Typography>
            <List dense={true}>
              {
                (channelAssembly?.options.lenses.length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{lens}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{lens}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{lens}`}</ListItem>
              }
              {
                (channelAssembly?.options.mountings.length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{mounting}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{mounting}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{mounting}`}</ListItem>
              }
              {
                (channelAssembly?.options.finishes.length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{finish}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{finish}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{finish}`}</ListItem>
              }
            </List>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttributeConfig;