import React, { useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { Box, Container, } from "@mui/material";
import Header from "../../../components/Header";
import LedTapeConfig from "./pages/LedTapeConfig/LedTapeConfig";
import ChannelConfig from "./pages/ChannelConfig/ChannelConfig";
import MainView from "./pages/MainView/MainView";

const EasySpecConfig = () => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return(
    <Box m="20px">
      <Header
        title="EASY SPEC CONFIG"
        subtitle="Configure the settings for the Easy Spec tool on the website."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Routes>
          <Route path="/" element={<MainView />} />
          <Route path="/led-tape/:ezLedTapeId" element={<LedTapeConfig />} />
          <Route path="/channel/:channelId" element={<ChannelConfig />} />
        </Routes>
      </Container>
    </Box>
  );
};

export default EasySpecConfig;