import React, { useCallback, useEffect, useState } from "react";
import { LedTapeOptionValueRecord, LedTapeRecord, LedTapeSpecRecord } from "../../../../../../database/Products";
import { Box, Button, Card, CardContent, CircularProgress, useTheme } from "@mui/material";
import { supabase_products } from "../../../../../../database/supabaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Enums } from "../../../../../../database/database.types";
import './SpecsConfig.css';
import { createCSVBlob, downloadCSV } from "../../../utils";
import SpecsImport from "./components/SpecsImport";

type SpecsConfigProps = {
  record: LedTapeRecord;
}

const SpecsConfig = ({ record }: SpecsConfigProps) => {

  const [specsData, setSepcsData] = useState<LedTapeSpecRecord[] | null>(null);
  const [optionsData, setOptionsData] = useState<Partial<LedTapeOptionValueRecord>[] | null>(null);
  const [showSpecsImport, setShowSpecsImport] = useState<boolean>(false);

  const queryData = useCallback(async () => {
    const [ specsResponse, optionsResponse ] = await Promise.all([
      supabase_products
      .from('led_tape_specs')
      .select('*')
      .eq('led_tape_slug', record.slug)
      .eq('conflicting_options', false)
      .order('option_sdcm')
      .order('option_lumens')
      .order('option_kelvin_temp')
      .order('option_color'),
      supabase_products
      .from('led_tape_option_values')
      .select('*')
      .eq('led_tape_slug', record.slug)
    ]);
    if(specsResponse.error) console.log(specsResponse.error);
    if(optionsResponse.error) console.log(optionsResponse.error);
    setSepcsData(specsResponse.data ?? []);
    setOptionsData(optionsResponse.data ?? []);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const getOptionDisplayName = (optionId: number | undefined) => {
    const option = optionsData?.find(opt => opt.id === optionId);
    return option ? option.display_name : '';
  };
  const columns: GridColDef<LedTapeSpecRecord>[] = getColumns(record.led_type, getOptionDisplayName);
  const columnFieldNames = columns.map(column => column.field) as (keyof LedTapeSpecRecord)[];
  const requiredColumns = columnFieldNames.filter(field => !field.startsWith('option_'));

  const handleExportData = () => {
    if(!specsData || !specsData[0]) return;
    const rows = [
      columnFieldNames.join(','),
      ...specsData.map(specs => columnFieldNames.map(key => {
        const specValue = specs[key];
        if(key.includes('option_')){
          return optionsData?.find(optData => optData.id === specValue)?.display_name ?? '';
        }
        return specValue;
      }).join(','))
    ];
    const csvString =rows.join('\n');
    const csvBlob = createCSVBlob(csvString);
    downloadCSV(csvBlob, `${record.slug}_specs.csv`);
  }

  const handleShowImportData = (display: boolean) => {
    setShowSpecsImport(display);
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row' gap={1} mb={2}>
        <Button 
          startIcon={<FileUploadOutlinedIcon />}
          onClick={() => handleShowImportData(true)} 
        >
          Import
        </Button>
        <Button 
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={handleExportData} 
          disabled={!specsData}
        >
            Export
          </Button>
      </Box>
      <Card>
        <CardContent sx={{ padding: '0'}}>
          <Box sx={{ height: '750px', width: '100%' }}>
            {
              !specsData &&
              <CircularProgress />
            }
            {
              specsData &&
              <DataGrid
                density="compact"
                rows={specsData}
                columns={columns}
                //checkboxSelection // TODO: Checkbox select for Export function
                disableSelectionOnClick
              />
            }
          </Box>
        </CardContent>
      </Card>
      {
        showSpecsImport && 
        <SpecsImport 
          requiredFields={requiredColumns} 
          onImported={queryData}
          onClose={() => handleShowImportData(false)}
        />
      }
    </Box>
  );
};

export default SpecsConfig;

const getColumns = (ledType: Enums<'LED Type'>, getOptionDisplayName: (optionId: number | undefined) => string | undefined): GridColDef<LedTapeSpecRecord>[] => {
  const mode = useTheme().palette.mode;
  const shadedCellClass = mode === 'light' ? 'light-column' : 'dark-column';
  const id = { field: 'id', headerName: 'id', hide: true }
  const optionSdcm = { 
    field: 'option_sdcm', 
    headerName: '[SDCM]', 
    cellClassName: shadedCellClass, 
    valueGetter: (params: GridValueGetterParams<LedTapeSpecRecord>) => getOptionDisplayName(params.row.option_sdcm)
  }
  const optionLumens = {
    field: 'option_lumens', 
    headerName: '[Lumens]', 
    cellClassName: shadedCellClass,
    valueGetter: (params: GridValueGetterParams<LedTapeSpecRecord>) => getOptionDisplayName(params.row.option_lumens)
  }
  const optionKelvinTemp = { 
    field: 'option_kelvin_temp', 
    headerName: '[Kelvin Temp]', 
    cellClassName: shadedCellClass,
    valueGetter: (params: GridValueGetterParams<LedTapeSpecRecord>) => getOptionDisplayName(params.row.option_kelvin_temp)
  }
  const optionColor = { 
    field: 'option_color', 
    headerName: '[Color]', 
    cellClassName: shadedCellClass,
    valueGetter: (params: GridValueGetterParams<LedTapeSpecRecord>) => getOptionDisplayName(params.row.option_color)
  }
  const maxRunFt = { field: 'max_run_ft', headerName: 'Max Run (FT)', editable: false }
  const maxCutIntervals = { field: 'max_cut_intervals', headerName: 'Max Cut Intervals', editable: false }
  const wattsPerFt = { field: 'watts_per_ft', headerName: 'Watts/Ft', editable: false }
  const efficacy = { field: 'efficacy', headerName: 'Efficacy', editable: false }
  const lumensPerFt = { field: 'lumens_per_ft', headerName: 'Lumens/Ft', editable: false }
  const wavelengthHighNm = { field: 'wavelength_low_nm', headerName: 'Wavelength Low (nm)', editable: false, width: 125 }
  const wavelengthLowNm = { field: 'wavelength_high_nm', headerName: 'Wavelength High (nm)', editable: false, width: 125 }
  const sdcm = { field: 'sdcm', headerName: 'SDCM', editable: false }
  const widthMm = { field: 'width_mm', headerName: 'Width (mm)', editable: false }
  const heightMm = { field: 'height_mm', headerName: 'Height (mm)', editable: false }
  const endcapWidthMm = { field: 'endcap_width_mm', headerName: 'Endcap Width (mm)', editable: false }
  const endcapHeightMm = { field: 'endcap_height_mm', headerName: 'Endcap Height (mm)', editable: false }
  const cutIntervalMm = { field: 'cut_interval_mm', headerName: 'Cut Interval (mm)', editable: false }
  const beamSpread = { field: 'beam_spread', headerName: 'Beam Spread', editable: false }
  const cri = { field: 'cri', headerName: 'CRI', editable: false }
  const ipRating = { field: 'ip_rating', headerName: 'IP Rating', editable: false }
  const diodesPerFt = { field: 'diodes_per_ft', headerName: 'Diodes / Ft', editable: false }
  const diodeBrand = { field: 'diode_brand', headerName: 'Diode Brand', editable: false }
  const diode = { field: 'diode', headerName: 'Diode', editable: false }
  const binningTolerance = { field: 'binning_tolerance', headerName: 'Binning Tolerance', editable: false }
  const dimmingOptions = { field: 'dimming_options', headerName: 'Dimming Options', editable: false }
  const tempRangeLow = { field: 'temp_range_low', headerName: 'Temp Range (Low)', editable: false }
  const tempRangeHigh = { field: 'temp_range_high', headerName: 'Temp Range (High)', editable: false }
  const a = { field: 'a', headerName: 'A', editable: false }
  const b = { field: 'b', headerName: 'B', editable: false }
  const c = { field: 'c', headerName: 'C', editable: false }
  const maxLeadFt = { field: 'max_lead_len_ft', headerName: 'Max Lead (Ft)', editable: false }
  const columns: GridColDef<LedTapeSpecRecord>[] = [id];
  switch(ledType) {
    case 'Color Tuning': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'Dim To Warm': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'Pixel': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'RGB': {
      columns.push(...[optionLumens, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, widthMm, heightMm, 
        endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'RGBCT': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'RGBW': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'RGBWA': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'Single Color': {
      columns.push(...[optionLumens, optionColor, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, wavelengthHighNm, wavelengthLowNm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'Static White': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
    case 'UV': {
      columns.push(...[optionSdcm, optionLumens, optionKelvinTemp, maxRunFt, maxCutIntervals, wattsPerFt, efficacy, lumensPerFt, sdcm,
        widthMm, heightMm, endcapWidthMm, endcapHeightMm, cutIntervalMm, beamSpread, cri, ipRating, diodesPerFt, diodeBrand, diode, binningTolerance, dimmingOptions,
        tempRangeLow, tempRangeHigh, a, b, c, maxLeadFt
      ]);
      break;
    }
  }

  return columns;
}