import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, IconButton, TextField, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { uploadProductImage } from "../data";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { getEasySpecImageUrl } from "../../../../database/Products";

type ImageConfigProps = {
  title: string;
  imageName: string;
  imageAltText: string;
  width: number;
  height: number;
  displayAlert: (message: string, severity: "success" | "error") => void;
  updateImageDetails: (fileName?: string , altText?: string ) => Promise<boolean>;
}

const ImageConfig = (props: ImageConfigProps) => {
  const { title, imageName, imageAltText, width, height, updateImageDetails, displayAlert } = props;

  const [imageUrl, setImageUrl] = useState<string>('');
  const [newFileInput, setNewFileInput] = useState<File | null>();
  const [fileError, setFileError] = useState<string>('');
  const [altTextInput, setAltTextInput] = useState<string>(imageAltText ?? '');
  const [altTextChanged, setAltTextChanged] = useState<boolean>(false);

  const queryImageUrl = useCallback(async (newFileName?: string) => {
    const fileName = newFileName ?? imageName;
    if(!fileName) return;
    const url = await getEasySpecImageUrl(fileName);
    setImageUrl(url ?? '');
  }, []);

  useEffect(() => {
    queryImageUrl();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileError('');
      setNewFileInput(null);
      const validTypes = ['image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        setFileError('Please select an accepted file type (JPG, PNG).');
        return;
      }
      const maxSize = 1 * 1024 * 1024; // 1 MB
      if (file.size > maxSize) {
        setFileError('File size should not exceed 1 MB.');
        return;
      }

      // Normalize the file name
      const fileExtension = file.name.split('.').pop();
      const baseName = file.name.slice(0, file.name.lastIndexOf('.'));
      const normalizedBaseName = baseName.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
      const normalizedFileName = normalizedBaseName + "." + fileExtension;
      console.log('Normalized File Name:', normalizedBaseName);
      // Create a new File object with the normalized file name
      const fileDupe = new File([file], normalizedFileName, {
        type: file.type, // Preserve the original MIME type
        lastModified: file.lastModified, // Optionally preserve the last modified date
      });
      
      setNewFileInput(fileDupe);
    }
  };

  const handleFileClear = () => {
    setNewFileInput(null);
  };

  const handleAltTextChange = (newValue: string) => {
    setAltTextInput(newValue);
    setAltTextChanged(true);
  };

  const handleUpdateUploadClick = async () => {
    const fileName = newFileInput ? await uploadProductImage(newFileInput) : imageName;
    if(fileName === null) {
      displayAlert('Error uploading the image file.', 'error');
      return;
    }
    const success = await updateImageDetails(fileName, altTextInput);
    if(success) {
      await queryImageUrl(fileName);
      setNewFileInput(null);
      setAltTextChanged(false);
      displayAlert(`Successfully updated the ${title}.`, 'success');
    }
    else{
      displayAlert(`Error updating the ${title}`, 'error');
    }
  };

  const handleDownloadImage = () => {
    const anchor = document.createElement('a');
    anchor.href = imageUrl;
    anchor.download = imageUrl.slice(imageUrl.lastIndexOf('.'));
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <Card sx={{ width: "100%"}}>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Divider />
        <Box p="10px" display="flex" flexDirection="row" maxWidth="850px">
          <Box>
            <Box width={`${width}px`} height={`${height}px`} bgcolor={imageUrl === '' ? 'gray' : ''}>
              {
                imageUrl !== '' &&
                <img src={imageUrl} alt="" style={{boxShadow: '2px 2px 2px rgb(0,0,0,0.2)'}} /> // TODO: add & load alt text
              }
            </Box>
            {
              imageUrl &&
              <Button 
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={handleDownloadImage}
                sx={{ width: "245px", marginTop: "10px"}} 
              >
                Download
              </Button>
            }
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="10px" ml={3} maxWidth="400px">
            <label htmlFor={`upload-button-${title}`}>
              <input
                id={`upload-button-${title}`}
                type="file"
                accept=".jpg, .png"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <Button 
                component="span"
                startIcon={<FolderOutlinedIcon />}
              >
                Choose File...
              </Button>
              {
                newFileInput && 
                <Typography component="span" ml={2}>
                  <b>{newFileInput.name}</b>
                  <span style={{ marginLeft: "10px" }}>({Math.round(newFileInput.size / 1024)} KB)</span>
                </Typography>
              }
              {
                fileError !== '' && <Typography color="error">{fileError}</Typography>
              }
              {
                newFileInput &&
                <IconButton aria-label="delete" color="error" onClick={handleFileClear}>
                  <ClearOutlinedIcon />
                </IconButton>
              }
            </label>
            <TextField
              label="Alt Text"
              color="info"
              value={altTextInput}
              onChange={(e) => handleAltTextChange(e.target.value)}
            />
            <Button 
              variant="contained"
              color={(altTextChanged && !newFileInput) ? "info" : "success"} 
              onClick={handleUpdateUploadClick}
              disabled={!newFileInput && !altTextChanged}
            >
              {
                altTextChanged && !newFileInput && "Update"
              }
              {
                !altTextChanged && newFileInput && "Upload"
              }
              {
                !altTextChanged && !newFileInput && "Update / Upload"
              }
              {
                altTextChanged && newFileInput && "Update / Upload"
              }
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImageConfig;