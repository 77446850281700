import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';

type HotkeysDialogProps = {
  open: boolean;
  onClose: () => void;
}

const ToolInfoDialog = ({ open, onClose }: HotkeysDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth='lg'
    >
      <DialogTitle display='flex' flexDirection='row' gap={1}>
        <InfoOutlinedIcon /> <Typography>Tool Information</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='row' gap={4} mt={1} justifyContent='center'>
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
              <Box py={1} px={2}  bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>Ctrl</Typography>
              </Box>
              <Typography fontSize={24}>+</Typography>
              <Box py={1} px={2} bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>D</Typography>
              </Box>
            </Box>
            <Box maxWidth='190px'>
              <Typography textAlign='center'>Duplicates rows!</Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='row' gap={1} alignItems='center' justifyContent='center'>
              <Box py={1} px={2}  bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>Ctrl</Typography>
              </Box>
              <Typography fontSize={24}>+</Typography>
              <Box py={1} px={2} bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>F</Typography>
              </Box>
            </Box>
            <Box maxWidth='190px'>
              <Typography textAlign='center'>Insert FMC onto focused row.</Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='row' gap={1} alignItems='center' justifyContent='center'>
              <Box py={1} px={2}  bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>Ctrl</Typography>
              </Box>
              <Typography fontSize={24}>+</Typography>
              <Box py={1} px={2} bgcolor='#efefef' border='2px solid #a3a3a3' borderRadius={1} boxShadow='0px 2px 4px 0px #a1a1a1'>
                <Typography fontWeight='bold'>[ ARROW KEY ]</Typography>
              </Box>
            </Box>
            <Box maxWidth='190px'>
              <Typography textAlign='center'>Switches input field in the desired direction.</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ marginY: '25px '}} />
        <Box display='flex' flexDirection='row' gap={4} justifyContent='center'>
          <Box display='flex' flexDirection='column' gap={2} maxWidth='190px'>
            <Box display='flex' flexDirection='column' gap={1} alignItems='flex-start'>
            <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>           
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' gap={1}>
              <BalanceOutlinedIcon />
              <Typography textAlign='center'>Balanced</Typography>
            </Box>
            <Typography textAlign='center'>Attempts to have all cuts at an even length but will allow some cuts to have an additional interval, without extending past max run length, in order to reduce the delta.</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='column' gap={2} maxWidth='190px'>
            <Box display='flex' flexDirection='column' gap={1} alignItems='center'>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' gap={1}>
              <CalendarViewWeekOutlinedIcon />
              <Typography textAlign='center'>Even</Typography>
            </Box>
            <Typography textAlign='center'>Forces all cuts to be of equal length, without. Note: Can result in large deltas for very long runs.</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display='flex' flexDirection='column' gap={2} maxWidth='190px'>
            <Box display='flex' flexDirection='column' gap={1} alignItems='flex-start'>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
                <Box width='25px' height='20px' bgcolor='#efefef' border='2px solid #a3a3a3'></Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' gap={1}>
              <ViewQuiltOutlinedIcon />
              <Typography textAlign='center'>Maximized</Typography>
            </Box>
            <Typography textAlign='center'>Builds cuts to the maximum run length first, then will include a shorter length at the end to cover the remainer.</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ToolInfoDialog;