import React from "react";
import { supabase_products } from "../../../../../../../database/supabaseClient";
import { Button, TableCell, TableRow, Typography } from "@mui/material";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

type ConflictProps = {
  id: number;
  option1: string;
  option2: string;
  onRemove: () => void;
}

const Conflict = ({ id, option1, option2, onRemove }: ConflictProps) => {

  const handleRemoveConflict = async() => {
    const response = await supabase_products
    .from('led_tape_option_values_conflicts')
    .delete()
    .eq('id', id);
    if(response.error){
      console.error(response.error.message);
      return;
    }
    onRemove();
  }

  return (
    <TableRow>
      <TableCell>
        <Typography>
          {option1}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {option2}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Button 
          color="error" 
          startIcon={<ClearOutlinedIcon />}
          onClick={handleRemoveConflict}
        >
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default Conflict;