import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../database/Profile";

const initialState: Profile = {
  id: "",
  full_name: null,
  updated_at: null,
  permissions: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile>) => {
      return action.payload; // Replace the entire state with the new profile
    },
  },
});

export const { setProfile } = profileSlice.actions;
export default profileSlice.reducer;
