import React, { useState } from "react";
import { Card, CardContent, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import { ChannelAssembly, LedTape } from "../../../../database/Products";
import { LedTapeRun } from "../../../../global_functions/RunCalculations";
import { EzBom } from "../types";
import { getBuildDetails } from "../../../../global_functions/BuildDetails";

type DescriptionProps = {
  ledTape: LedTape;
  channelAssembly: ChannelAssembly;
  lines: (LedTapeRun & { quantity: number })[];
  ledTapePartNumber: string;
  ezBom: EzBom;
  channelRequired: number;
}

const Description = ({ ledTape, channelAssembly, lines, ledTapePartNumber, channelRequired }: DescriptionProps) => {
  const colors = useTheme().colors;

  const [copiedContent, setCopiedContent] = useState<boolean>(false);
  
  const handleOnClick = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopiedContent(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedContent(false);
  };

  const buildDetails = lines.map(line => getBuildDetails(line.quantity, line)).join('\n');
  const channelAssemblyName = channelAssembly.name ?? '';
  const fmlQty = lines.reduce((acc, curVal) => {return acc + (curVal.strips.length * curVal.quantity)}, 0);
  const tapeConductors = ledTape.conductors ?? 0;
  const lensDesc = channelAssembly.lens.name; // TODO: Allow more names in description, if available (in qb_items)
  let mountingsQty = channelAssembly.mounting.qb_items[0]?.quantity * channelRequired;
  if(isNaN(mountingsQty)) mountingsQty = channelRequired;
  const mountingItemName = channelAssembly.mounting.qb_items[0]?.full_name ?? '';

  let description = 'Unable to generate the description text...';
  if(channelAssembly.channel && ledTapePartNumber !== ''){
    description = 
    `${channelAssemblyName} Easy Spec Components Include:
    • (${fmlQty}) Factory soldered power feeds (FML-${tapeConductors}C-6), provided to comprise total requested run length(s) listed below
    • ${ledTapePartNumber} at each of the following lengths:
      ${buildDetails}
    • (${channelRequired}) 2-meter extrusions of Channel ${channelAssemblyName} ${lensDesc ? `& ${lensDesc} lens(es)` : ''} to cover the total length of LED strip listed above
    • (${mountingsQty}) Mounting Accessory & End Cap “Paks” ${mountingItemName} to mount and enclose all necessary ${channelAssemblyName} segments provided
    
    *Power supplies sold separately`;
  }

  return (
    <Card sx={{ maxWidth: '1000px'}}>
      <CardContent>
        <Typography variant="h5">
          Easy Spec Description
        </Typography>
        <Divider />
        <Tooltip
          title={copiedContent ? "Copied!" : "Click to copy"}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: {
              enter: 800,
              exit: 0,
            },
          }}
          onClose={handleOnCloseCopyTooltip}
        >
          <Typography 
            mt={2} 
            whiteSpace='pre-line'
            onClick={() => handleOnClick(description)}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: colors.blueAccent[500],
              },
            }}
          >
            {description}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default Description;