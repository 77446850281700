import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../../components/Header";
import RoundDigits from "../../../global_functions/RoundDigits";
import { CurrencyInputToNumber } from "../../../global_functions/DollarInput";
import CurrencyFormatter from "../../../global_functions/CurrencyFormatter";
import { ExpandMoreOutlined } from "@mui/icons-material";
import KelvixLogo from "../../../components/KelvixLogo";
import Fade from "@mui/material/Fade";

type CellProps = {
  children?: React.ReactNode;
}

const Cell = ({ children }: CellProps) => {
  return (
    <TableCell>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

const CommissionChecker = () => {
  const colors = useTheme().colors;

  const textInputSx = {
    "&": {
      marginRight: "10px",
      maxWidth: "150px",
    },
    "& label.Mui-focused": {
      color: colors.blueAccent[500],
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.blueAccent[500],
      },
    },
  };

  const [bomBaseAmount, setBomBaseAmount] = useState(0);
  const [poTotal, setPoTotal] = useState(0);
  const [baseComRate, setBaseComRate] = useState(0.1);
  const [overageRate, setOverageRate] = useState(1.0);
  const [agentSplit, setAgentSplit] = useState("None");
  const [showSettings, setShowSettings] = useState(false);
  const [clickedToCopy, setClickedToCopy] = useState(false);

  function onChange_BaseCommissionRate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const input = event.target.value;
    const value = CurrencyInputToNumber(input);
    const percent = value / 100;
    setBaseComRate(percent);
  }

  function onChange_OverageRate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const input = event.target.value;
    const value = CurrencyInputToNumber(input);
    const percent = value / 100;
    setOverageRate(percent);
  }

  function onChange_BomBaseAmount(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = CurrencyInputToNumber(event.target.value);
    setBomBaseAmount(value);
  }

  function onChange_POAmount(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = CurrencyInputToNumber(event.target.value);
    setPoTotal(value);
  }

  function onChange_AgentSplit(event: SelectChangeEvent<string>) {
    const value = event.target.value;
    setAgentSplit(value);
  }

  const isSplit = agentSplit !== "None";
  const overage = poTotal - bomBaseAmount;
  const baseCommission = bomBaseAmount * baseComRate;
  const overageCommission = overage * overageRate;
  const totalCommission = baseCommission + overageCommission;
  const overallCommissionPerc = totalCommission / poTotal;

  // 'Base' commission is always 10%
  // The below share split is _of_ that 10%
  let specAgentBaseShare = 0;
  let purchAgentBaseShare = 0;
  let destAgentBaseShare = 0;

  switch (agentSplit) {
    case "None":
      specAgentBaseShare = 0;
      purchAgentBaseShare = 1;
      destAgentBaseShare = 0;
      break;
    case "50/50 (Specifier & Purchasing)":
      specAgentBaseShare = 1;
      purchAgentBaseShare = 0.5;
      destAgentBaseShare = 0;
      break;
    case "50/50 (Purchasing & Destination)":
      specAgentBaseShare = 0;
      purchAgentBaseShare = 0.5;
      destAgentBaseShare = 0.5;
      break;
    case "75/25":
      specAgentBaseShare = 1;
      purchAgentBaseShare = 0.25;
      destAgentBaseShare = 0;
      break;
    case "50/25/25":
      specAgentBaseShare = 1;
      purchAgentBaseShare = 0.25;
      destAgentBaseShare = 0.25;
      break;
    default:
      break;
  }

  function calcAgentCommission(baseShare: number, includeOverage = false) {
    const baseCommissionPerc = baseComRate * baseShare;
    const commission =
      baseCommissionPerc * bomBaseAmount +
      (includeOverage ? overageCommission : 0);
    const overallCommissionPerc =
      commission === 0 || poTotal === 0 ? 0 : commission / poTotal;
    return {
      commission: commission,
      baseCommissionPerc: baseCommissionPerc,
      overallCommissionPerc: overallCommissionPerc,
    };
  }

  const specAgent = calcAgentCommission(specAgentBaseShare);
  const purchAgent = calcAgentCommission(purchAgentBaseShare, true);
  const destAgent = calcAgentCommission(destAgentBaseShare);

  function onClick_Overage() {
    navigator.clipboard.writeText(RoundDigits(overage, 2).toString());
    setClickedToCopy(true);
  }

  function onClick_Percentage() {
    navigator.clipboard.writeText(RoundDigits(overallCommissionPerc * 100, 2).toString());
    setClickedToCopy(true);
  }

  const handleOnCloseCopyTooltip = () => {
    setClickedToCopy(false);
  };

  return (
    <Box m="20px">
      <Header
        title="COMMISSION CHECKER"
        subtitle="Check the commission due for a given BOM base amount."
      />

      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Accordion
          expanded={showSettings}
          onChange={(event, isExpanded) => setShowSettings(isExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ marginRight: "35px", flexShrink: 0 }}>
              Settings
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {`Base ${baseComRate * 100}%, with ${
                overageRate * 100
              }% Overage, Agent Split: ${agentSplit}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Base Commission Rate"
              value={CurrencyFormatter(baseComRate * 100)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={onChange_BaseCommissionRate}
              helperText="Base amount of the BOM"
              sx={{ ...textInputSx }}
            />
            <TextField
              label="Overage Keep Rate"
              value={CurrencyFormatter(overageRate * 100)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={onChange_OverageRate}
              helperText="% of overage agent keeps"
              sx={{ ...textInputSx }}
            />
            <FormControl
              variant="outlined"
              size="small"
            >
              <InputLabel>Agent Split</InputLabel>
              <Select
                value={agentSplit}
                label="Agent Split"
                onChange={onChange_AgentSplit}
              >
                <MenuItem value={"None"}>None</MenuItem>
                <MenuItem value={"50/50 (Specifier & Purchasing)"}>
                  50/50 (Specifier & Purchasing)
                </MenuItem>
                <MenuItem value={"50/50 (Purchasing & Destination)"}>
                  50/50 (Purchasing & Destination)
                </MenuItem>
                <MenuItem value={"75/25"}>75/25</MenuItem>
                <MenuItem value={"50/25/25"}>50/25/25</MenuItem>
              </Select>
              <FormHelperText>
                Specification / Purchase / Destination
              </FormHelperText>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Paper
          elevation={3}
          sx={{
            margin: "35px 0",
            padding: "30px",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <KelvixLogo />
            <Box
              display="flex"
              flexDirection="row"
              marginLeft="25px"
            >
              <TextField
                label="Base Amount"
                value={CurrencyFormatter(bomBaseAmount)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={onChange_BomBaseAmount}
                helperText="Base amount of the BOM"
                sx={{ ...textInputSx }}
              />
              <TextField
                label="PO Amount"
                value={CurrencyFormatter(poTotal)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={onChange_POAmount}
                helperText="Amount on Customer's PO"
                sx={{ ...textInputSx }}
              />
              <Tooltip
                title={clickedToCopy ? "Copied!" : "Click to copy"}
                TransitionComponent={Fade}
                TransitionProps={{
                  timeout: {
                    enter: 800,
                    exit: 0,
                  },
                }}
                onClose={handleOnCloseCopyTooltip}
              >
                <Typography
                  variant="h5"
                  padding={1}
                  onClick={onClick_Overage}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      color: colors.blueAccent[500],
                    },
                  }}
                >
                  Overage: <strong>{CurrencyFormatter(overage, true)}</strong>
                </Typography>
              </Tooltip>
            </Box>
          </Box>

          <TableContainer>
            <Table
              size="small"
              sx={{
                maxWidth: "650px",
                margin: "25px 0",
              }}
            >
              <TableHead sx={{ background: colors.primary[400] }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography>Calculation</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Value</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <Cell> Base Commission </Cell>
                  <Cell>
                    {" "}
                    {`${baseComRate * 100}% OF ${CurrencyFormatter(
                      bomBaseAmount,
                      true
                    )} = `}
                  </Cell>
                  <Cell>
                    <strong>{`${CurrencyFormatter(
                      baseCommission,
                      true
                    )}`}</strong>
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell> Overage Commission </Cell>
                  <Cell>
                    {`${overageRate * 100}% OF ${CurrencyFormatter(
                      overage,
                      true
                    )} = `}
                  </Cell>
                  <Cell>
                    {" "}
                    <strong>{`${CurrencyFormatter(
                      overageCommission,
                      true
                    )}`}</strong>{" "}
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell> Total </Cell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                    >
                      <Typography>
                        <strong>
                          {isSplit
                            ? "Split"
                            : CurrencyFormatter(totalCommission, true)}
                        </strong>
                      </Typography>
                      {
                        !isSplit &&
                        <Box
                          onClick={onClick_Percentage}
                          sx={{ marginLeft: "5px" }}
                        >
                          {totalCommission > 0 && (
                            <Tooltip
                              title={clickedToCopy ? "Copied!" : "Click to copy"}
                              TransitionComponent={Fade}
                              TransitionProps={{
                                timeout: {
                                  enter: 800,
                                  exit: 0,
                                },
                              }}
                              onClose={handleOnCloseCopyTooltip}
                            >
                              <Typography
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                    color: colors.blueAccent[500],
                                  },
                                }}
                              >
                                {`(${RoundDigits(
                                  overallCommissionPerc * 100,
                                  2
                                )}%)`}
                              </Typography>
                            </Tooltip>
                          )}
                        </Box>
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table
              size="small"
              sx={{ maxWidth: "650px" }}
            >
              <TableHead sx={{ background: colors.primary[400] }}>
                <TableRow sx={{ "& > *": { fontWeight: "bold" } }}>
                  <Cell></Cell>
                  <Cell>Specifier Agent</Cell>
                  <Cell>Purchasing Agent</Cell>
                  <Cell>Destination Agent</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <Cell>Commission Payout</Cell>
                  <Cell>{`${CurrencyFormatter(
                    specAgent.commission,
                    true
                  )}`}</Cell>
                  <Cell>{`${CurrencyFormatter(
                    purchAgent.commission,
                    true
                  )}`}</Cell>
                  <Cell>{`${CurrencyFormatter(
                    destAgent.commission,
                    true
                  )}`}</Cell>
                </TableRow>
                <TableRow>
                  <Cell>Commission % of Base</Cell>
                  <Cell>{specAgent.baseCommissionPerc * 100}%</Cell>
                  <Cell>{purchAgent.baseCommissionPerc * 100}%</Cell>
                  <Cell>{destAgent.baseCommissionPerc * 100}%</Cell>
                </TableRow>
                <TableRow>
                  <Cell>Commission % of PO Total</Cell>
                  <Cell>
                    {RoundDigits(specAgent.overallCommissionPerc * 100, 2)}%
                  </Cell>
                  <Cell>
                    {RoundDigits(purchAgent.overallCommissionPerc * 100, 2)}%
                  </Cell>
                  <Cell>
                    {RoundDigits(destAgent.overallCommissionPerc * 100, 2)}%
                  </Cell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </Box>
  );
};

export default CommissionChecker;
