import React, { useCallback, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getChannelProducts, getLedProducts, insertNewEasySpecChannel, insertNewEasySpecTape } from '../data';
import { Box, CircularProgress, DialogContent, IconButton, TextField } from "@mui/material";
import { InsertResponse, ProductType } from "../types";
import { ChannelAssemblyRecord, LedTapeRecord } from "../../../../database/Products";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

interface AddProductDialogProps {
  open: boolean;
  onClose: () => void;
  ledTapeIdExclusions: number[];
  channelIdExclusions: number[];
  onSelectedProduct: (type: ProductType, productId: number) => void;
}

const AddProductDialog = (props: AddProductDialogProps) => {
  
  const { open, onClose, ledTapeIdExclusions, channelIdExclusions, onSelectedProduct } = props;

  const [productType, setProductType] = useState<"led_tape" | "channel" | null>(null);
  const [ledTape, setLedTape] = useState<LedTapeRecord[]>([]);
  const [channel, setChannel] = useState<ChannelAssemblyRecord[]>([]);
  const [querying, setQuerying] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState<string>('');

  const queryLedTapeData = useCallback(async () => {
    setLedTape([]);
    getLedProducts().then((data) => {
      setLedTape(data);
      setQuerying(false);      
    });
  }, []);
  
  const queryChannelData = useCallback(async () => {
    setChannel([]);
    getChannelProducts().then((data) => {
      setChannel(data);
      setQuerying(false);
    });
  }, []);

  useEffect(() => {
    setQuerying(productType !== null);
    switch(productType){
      case "led_tape": queryLedTapeData();
        break;
      case "channel": queryChannelData();
        break;
    }
  }, [productType]);

  const handleListClickLedTape = (ledTapeProdRecordId: number) => {
    insertNewEasySpecTape(ledTapeProdRecordId).then((data: InsertResponse) => {
      if(data !== null)
        onSelectedProduct("tape", data[0].id);
    });
  };

  const handleListClickChannel = (channelProdRecordId: number) => {
    insertNewEasySpecChannel(channelProdRecordId).then((data: InsertResponse) => {
      if(data !== null)
        onSelectedProduct("channel", data[0].id);
    });
  };

  const handleOnChangeFilter = (value: string) => {
    setFilterInput(value);
  }

  const filteredLedTape = ledTape.filter(p => 
    !ledTapeIdExclusions.includes(p.id) &&
    p.name.toLowerCase().includes(filterInput.toLowerCase())
  );
  const filteredChannel = channel.filter(p => 
    !channelIdExclusions.includes(p.id) &&
    p.name.toLowerCase().includes(filterInput.toLowerCase())
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle fontSize="2em" textAlign='center'>Add Product to Easy Spec</DialogTitle>
      <DialogContent dividers sx={{ width: '600px', minHeight: '400px'}}>
        {
          productType === null &&
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: '15px'
          }}>
            <Button fullWidth onClick={() => setProductType('led_tape')}>
              LED Tape
            </Button>
            <Button fullWidth onClick={() => setProductType('channel')}>
              Channel
            </Button>
          </Box>
        }
        {
          productType !== null &&
          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '15px'}}>
            <IconButton size="small" color="info" onClick={() => setProductType(null)}>
              <ArrowBackIosNewOutlinedIcon fontSize="inherit" />
            </IconButton>
            <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField 
              label="Search"
              color="info"
              value={filterInput}
              onChange={(e) => handleOnChangeFilter(e.target.value)}
            />
          </Box>
        }
        {
          querying &&
          <CircularProgress />
        }
        {
          productType === "led_tape" && 
          <List sx={{ pt: 0, height: '600px' }}>
            {filteredLedTape.map((tape) => (
              <ListItem key={tape.id} disableGutters sx={{ padding: '0'}} >
                <ListItemButton onClick={() => handleListClickLedTape(tape.id)} sx={{ padding: '0'}}>
                  <ListItemText primary={tape.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
        {
          productType === "channel" && 
          <List sx={{ pt: 0, height: '600px' }}>
            {filteredChannel.map((channel) => (
              <ListItem key={channel.id} disableGutters sx={{ padding: '0'}} >
                <ListItemButton onClick={() => handleListClickChannel(channel.id)} sx={{ padding: '0'}}>
                  <ListItemText primary={channel.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
      </DialogContent>
    </Dialog>
  );
}

export default AddProductDialog;