import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { SelectField, TextField } from '../../../components/TextField';
import { FamilyRecord, LedTapeRecord } from '../../../../../../database/Products';
import { supabase_products } from '../../../../../../database/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Enums } from '../../../../../../database/database.types';

type NewLedTapeProps = {
  onCancel: () => void;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const NewLedTape = ({ onCancel, displayAlert }: NewLedTapeProps) => {

  const navigate = useNavigate();

  const [families, setFamilies] = useState<FamilyRecord[]>([]);
  const [familyInput, setFamilyInput] = useState<number | null>(null);
  const [ledTypeInput, setLedTypeInput] = useState<Enums<'LED Type'>>('Static White');
  const [slugInput, setSlugInput] = useState<string>('');
  const [nameInput, setNameInput] = useState<string>('');

  const queryFamilies = useCallback(async () => {
    const { data, error } = await supabase_products.from('families').select('*').order('name', { ascending: true });
    if(error) console.error(error);
    setFamilies(data ?? []);
  }, []);

  useEffect(() => {
    if(families.length !== 0) return;
    queryFamilies();
  }, []);

  const canAdd = (familyInput && slugInput !== '' && nameInput !== '');

  const handleAdd = async () => {
    if(!canAdd) return;
    const newRecord: Pick<LedTapeRecord, 'family_id' | 'led_type' | 'slug' | 'name'> = {
      family_id: familyInput,
      led_type: ledTypeInput,
      slug: slugInput,
      name: nameInput,
    }
    const { data, error } = await supabase_products.from('led_tape').insert(newRecord).select('id, slug').single();
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    if(!data) return;
    navigate(`led-tape/${data.slug}`);
  }

  const handleLedTypeChange = (value: string) => {
    switch(value){
      case 'Static White':
        setLedTypeInput('Static White');
        break;
      case 'Color Tuning': 
        setLedTypeInput('Color Tuning');
        break;
      case 'Dim To Warm': 
        setLedTypeInput('Dim To Warm');
        break;
      case 'RGB': 
        setLedTypeInput('RGB');
        break;
      case 'RGBW': 
        setLedTypeInput('RGBW');
        break;
      case 'RGBCT': 
        setLedTypeInput('RGBCT');
        break;
      case 'RGBWA': 
        setLedTypeInput('RGBWA');
        break;
      case 'Single Color': 
        setLedTypeInput('Single Color');
        break;
      case 'Pixel': 
        setLedTypeInput('Pixel');
        break;
      case 'UV': 
        setLedTypeInput('UV');
        break;
      default: setLedTypeInput('Static White');
    }
  }

  return (
    <>
    <DialogTitle id="import-dialog-title">
      Add a new LED Tape
    </DialogTitle>
    <DialogContent>
      <Box display='flex' flexDirection='row' gap={2} mb={2}>
        <SelectField 
          label='Family'
          value={families.length === 0 || familyInput === null ? '' : familyInput}
          onChange={(e) => setFamilyInput(Number(e.target.value))}
          required
          fullWidth
        >
          {
            families.map(family => (
              <MenuItem key={family.id} value={family.id}>
                {family.name}
              </MenuItem>
            ))
          }
        </SelectField>
      </Box>
      <Box mb={2}>
        <TextField 
          label='Slug' 
          value={slugInput}
          onChange={(e) => setSlugInput(e.target.value)}
          helperText={`Used as a human readable unique identifer (i.e., "uni-i-wl"). Cannot be edited after creating. `}
          required
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <TextField 
          label='Name'
          value={nameInput} 
          onChange={(e) => setNameInput(e.target.value)}
          required
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <SelectField 
          label='LED Type'
          value={ledTypeInput}
          onChange={(e) => handleLedTypeChange(e.target.value)}
          helperText={`Determines the type of configurable options. Cannot be edited after creating. `}
          required
          fullWidth
        >
          <MenuItem value={'Static White'}>Static White</MenuItem>
          <MenuItem value={'Color Tuning'}>Color Tuning</MenuItem>
          <MenuItem value={'Dim To Warm'}>Dim To Warm</MenuItem>
          <MenuItem value={'RGB'}>RGB</MenuItem>
          <MenuItem value={'RGBW'}>RGBW</MenuItem>
          <MenuItem value={'RGBCT'}>RGBCT</MenuItem>
          <MenuItem value={'RGBWA'}>RGBWA</MenuItem>
          <MenuItem value={'Single Color'}>Single Color</MenuItem>
          <MenuItem value={'Pixel'}>Pixel</MenuItem>
          <MenuItem value={'UV'}>UV</MenuItem>
        </SelectField>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={handleAdd} variant='contained' color='success' disabled={!canAdd}>
        Add
      </Button>
    </DialogActions>
    </>
  );
};

export default NewLedTape;