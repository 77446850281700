import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { GetFeet, GetUomWord } from "../../../global_functions/UomConversion";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Cell from "./Cell";
import ItemRow from "./ItemRow";
import { Item, LineItem, TxnInventoryData } from "../../../qbll/types";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ItemForTable } from "./types";

function getCurrentDate() {
  const date = new Date();
  let day: number | string = date.getDate();
  let month: number | string = date.getMonth() + 1; // getMonth() returns a zero-based value (0-11)
  const year = date.getFullYear().toString().substr(-2); // get the last 2 digits of the year

  // padStart will add a 0 in front of the day/month if they are only one digit
  day = day.toString().padStart(2, "0");
  month = month.toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
}

export function toLocale(number: string | number) {
  const num = Number(number);
  const locale = num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // Remove unnecessary trailing zeroes
  const formatted = locale.replace(/\.0*$|(\.[0-9]*[1-9])0+$/, "$1");
  return formatted;
}

function formatQuantity(value: number, currentUom: string, forceFeet: boolean) {
  return forceFeet ? GetFeet(currentUom, value) : value;
}

function getAllInventoryLineItems(lineItems: LineItem[]) {
  const inventoryLineItems = lineItems.filter(
    (lineItem) =>
      lineItem.item?.itemTypeStr === "Inventory" ||
      lineItem.item?.itemTypeStr === "InventoryAssembly"
  );
  const groupLineItems = lineItems.filter((lineItem) => lineItem.item?.itemTypeStr === "Group");
  groupLineItems.forEach((lineItem) => {
    if (!lineItem.children || lineItem.children.length === 0) return;
    const childInvLineItems = lineItem.children.filter(
      (lineItem) =>
        lineItem.item?.itemTypeStr === "Inventory" ||
        lineItem.item?.itemTypeStr === "InventoryAssembly"
    );
    childInvLineItems.forEach((child) => {
      inventoryLineItems.push(child);
    });
  });
  return inventoryLineItems;
}

function buildItemForTable(item: Item, qtyNeeded: number, isAssemblyItem = false, uomOverride?: string, assemParentItem?: Item) {
  const { inventory } = item;
  if(!inventory) return;
  const newItem: ItemForTable = {
    listId: item.listId,
    fullName: item.fullName,
    isAssemblyPart: isAssemblyItem,
    uom: isAssemblyItem ? inventory.unitOfMeasure : uomOverride ?? '',
    quantityNeeded: isAssemblyItem ? ((item.assemblyQuantity ?? 0) * qtyNeeded) : qtyNeeded,
    onHand: inventory.quantityOnHand,
    onSalesOrder: isAssemblyItem ? 
      inventory.quantityOnSalesOrder + ((assemParentItem?.inventory?.quantityOnSalesOrder ?? 0) * (item.assemblyQuantity ?? 0))
      : inventory.quantityOnSalesOrder,
    onSalesOrderIndiv: isAssemblyItem ? inventory.quantityOnSalesOrder : 0,
    onSalesOrderParent: isAssemblyItem ? assemParentItem?.inventory?.quantityOnSalesOrder ?? 0 : 0,
    parentPartNumber: isAssemblyItem ? assemParentItem?.fullName ?? '' : '',
    onOrder: inventory.quantityOnOrder,
    sites: inventory.sites,
    haveEnough: false
  };
  return newItem;
}

function getExistingItem(item: Item, itemArr: ItemForTable[]) {
  const existingItem = itemArr.find((existingItem) => existingItem.listId === item.listId);
  return existingItem;
}

function getCompiledItems(lineItems: LineItem[]) {
  const compiledItems: ItemForTable[] = [];
  lineItems.forEach((lineItem) => {
    const item: Item | null = JSON.parse(JSON.stringify(lineItem.item));
    if(item == null) return;
    if (item.itemTypeStr === "InventoryAssembly") {
      item.assemblyChildren?.forEach((assemItem) => {
        if(!assemItem.inventory) return;
        const newItem = buildItemForTable(assemItem, lineItem.quantity, true, undefined, item);
        if(!newItem) return;
        const existingItem = getExistingItem(assemItem, compiledItems);
        if (existingItem) {
          const assemQtyNeeded = (assemItem.assemblyQuantity ?? 0) * lineItem.quantity;
          existingItem.quantityNeeded += assemQtyNeeded;
        } else {
          compiledItems.push(newItem);
        }
      });
    } else if (item.itemTypeStr === "Inventory") {
      const newItem = buildItemForTable(
        item,
        lineItem.quantity,
        false,
        lineItem.unitOfMeasureOverride
      );
      if(!newItem) return;
      const existingItem = getExistingItem(item, compiledItems);
      if (existingItem) {
        existingItem.quantityNeeded += newItem.quantityNeeded;
      } else {
        compiledItems.push(newItem);
      }
    }
  });
  return compiledItems;
}

type SoDataProps = {
  soData: TxnInventoryData
  onQueryItemSiteInventory: (listId: string) => Promise<void>;
}

const SoData = ({ soData, onQueryItemSiteInventory }: SoDataProps) => {
  const colors = useTheme().colors;

  const lineItems = soData.lineItems ?? [];
  const inventoryLineItems = getAllInventoryLineItems(lineItems);
  const compiledItems = getCompiledItems(inventoryLineItems);
  const defaultSelections = Array(compiledItems.length).fill(false);

  const [forceFeet, setForceFeet] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<boolean[]>([...defaultSelections]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleSelectRow = (selectIndex: number, isSelected: boolean) => {
    const newSelectRows = [...selectedRows];
    newSelectRows[selectIndex] = isSelected;
    setSelectedRows(newSelectRows);
  };

  compiledItems.forEach((itemForTable, index) => {
    const { uom, sites } = itemForTable;
    let { quantityNeeded, onHand, onSalesOrder, onOrder } = itemForTable;

    quantityNeeded = formatQuantity(quantityNeeded, uom, forceFeet);
    quantityNeeded = Number(quantityNeeded.toFixed(2));

    onHand = formatQuantity(onHand, uom, forceFeet);
    onHand = Number(onHand.toFixed(2));

    onSalesOrder = formatQuantity(onSalesOrder, uom, forceFeet);
    onSalesOrder = Number(onSalesOrder.toFixed(2));

    onOrder = formatQuantity(onOrder ?? 0, uom, forceFeet);
    onOrder = Number(onOrder.toFixed(2));

    sites?.forEach((site) => {
      let { quantityOnHand } = site;
      quantityOnHand = formatQuantity(quantityOnHand ?? 0, uom, forceFeet);
      quantityOnHand = Number(quantityOnHand.toFixed(2));
      site.quantityOnHand = quantityOnHand;
    });

    const haveEnough =
      soData.type === "Quote"
        ? (itemForTable.haveEnough = Number(onSalesOrder) + Number(quantityNeeded) <= Number(onHand))
        : Number(onSalesOrder) <= Number(onHand);

    itemForTable = {
      ...itemForTable,
      quantityNeeded: quantityNeeded,
      onHand: onHand,
      onSalesOrder: onSalesOrder,
      onOrder: onOrder,
      uom: GetUomWord(uom ?? '', forceFeet),
      haveEnough: haveEnough,
      sites: sites,
    };

    compiledItems[index] = itemForTable;
  });

  function copyToClipboard() {
    const selectedLineItems = compiledItems.filter((lineItem, index) => selectedRows[index]);
    const data = selectedLineItems.map((lineItem) => {
      const row = [
        getCurrentDate(),
        soData.refNumber,
        lineItem.fullName,
        lineItem.quantityNeeded,
        lineItem.uom,
        "",
        "CUSTOMER ORDER",
      ];
      return row;
    });

    const text = data.map((row) => row.join("\t")).join("\n");

    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  }

  function toggleSelectAll(e: React.ChangeEvent<HTMLInputElement>) {
    const toggle = e.target.checked;
    setSelectAll(toggle);
    const rows = [...selectedRows];
    rows.fill(toggle);
    setSelectedRows(rows);
  }

  return (
    soData && (
      <Box>
        <Typography>
          <strong>Ref Number: </strong>
          {soData.refNumber}
        </Typography>
        <Typography>
          <strong>Customer: </strong>
          {soData.customer.fullName}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
        >
          <FormControl sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  title="Force Feet"
                  checked={forceFeet}
                  onChange={(e) => setForceFeet(e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Force Feet"
            />
          </FormControl>
          <Box>
            <Button
              variant="outlined"
              size="small"
              startIcon={<ContentCopyOutlinedIcon />}
              onClick={() => copyToClipboard()}
              color="info"
              sx={{ marginRight: "10px" }}
            >
              Copy Selected
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<ListAltOutlinedIcon />}
              href="https://app.smartsheet.com/sheets/pw8x73G8fHWvvgXfrMhJMRgwQ5wxFFJ4GwGw57r1?view=grid"
              target="_blank"
              color="info"
            >
              Ordering Sheet
            </Button>
          </Box>
        </Box>
        <TableContainer sx={{ marginTop: "10px" }}>
          <Table size="small">
            <TableHead sx={{ background: colors.primary[400] }}>
              <TableRow>
                <Cell>
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => toggleSelectAll(e)}
                    color="secondary"
                    size="small"
                  />
                </Cell>
                <Cell>
                  <strong>Item Name</strong>
                </Cell>
                <Cell>
                  <strong>Needed</strong>
                </Cell>
                <Cell>
                  <strong>UoM</strong>
                </Cell>
                <Cell>
                  <strong>On Hand</strong>
                </Cell>
                <TableCell>
                  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <Typography>
                      <strong>On Sales Orders</strong>
                    </Typography>
                    {
                      soData.type === "Sales Order" && 
                      <Tooltip
                        color="info"
                        title={
                          <Typography>These values include quantity "needed" for the Sales Order being viewed.</Typography>
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    }
                  </Box>
                </TableCell>
                <Cell>
                  <strong>On PO</strong>
                </Cell>
                <TableCell align="right">
                  <Typography>
                    <strong>Site Allocation</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compiledItems.map((item, index) => (
                <ItemRow
                  key={index}
                  index={index}
                  itemData={item}
                  checked={selectedRows[index]}
                  onChangedChecked={handleSelectRow}
                  queryItemSiteInventory={onQueryItemSiteInventory}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );
};

export default SoData;
