import React from "react";
import StyledTextField from "../../../../components/StyledTextField";

type ConfigTextInputProps<T> = {
  label: string;
  value: string | number;
  fieldName: keyof(T);
  updateValue: (fieldName: keyof(T), value: string | number | boolean) => void;
  helperText?: string;
  number?: boolean;
}

const ConfigTextInput = <T,>({
  label,
  value,
  fieldName,
  updateValue,
  helperText,
  number = false,
}: ConfigTextInputProps<T>) => {
  let v = value ? value : "";
  if (number) v = Number(v);
  return (
    <StyledTextField
      type={number ? "number" : "text"}
      label={label}
      value={v}
      onChange={(e) => updateValue(fieldName, e.target.value)}
      size="small"
      multiline={!number}
      helperText={helperText}
      sx={{
        marginBottom: "20px",
      }}
    />
  );
};

export default ConfigTextInput;
