import { Autocomplete, Box, Button, Card, CardContent, Divider, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '../../components/TextField';
import { ChannelAssemblyRecord, ChannelRecord } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { DisplayAlert } from '../../types';

type BasicDetailsProps = {
  record: ChannelAssemblyRecord;
  reloadRecord: () => Promise<void>;
  displayAlert: DisplayAlert;
}

const BasicDetails = ({ record, reloadRecord, displayAlert }: BasicDetailsProps) => {
  const colors = useTheme().colors;

  const [nameInput, setNameInput] = useState<string>(record.name);
  const [specSheetUrlInput, setSpecSheetUrlInput] = useState<string>(record.spec_sheet_url ?? '');
  const [selectedChannelName, setSelectedChannelName] = useState<string | null>(null);
  const [channelSearchInput, setChannelSearchInput] = useState<string>("");
  const [channelList, setChannelList] = useState<Pick<ChannelRecord, 'id' | 'name' | 'assembly_sku_code'>[] | null>(null);

  const queryChannel = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('channel').select('id, name, assembly_sku_code');
    if(error) console.error(error.message);
    setChannelList(data ?? []);
    setSelectedChannelName(data?.find(d => d.id === record.channel_id)?.name ?? null)
  }, []);

  const updateAssembly = async () => {
    const foundChannel = channelList?.find(channel => channel.name === selectedChannelName);
    if(!foundChannel) return;
    const updatedRecord = {
      name: nameInput,
      channel_id: foundChannel.id,
      spec_sheet_url: specSheetUrlInput
    }
    const { error } = await supabase_products
    .from('channel_assemblies').update({...updatedRecord}).eq('id', record.id);
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return false;
    }
    reloadRecord().then(() => {
      displayAlert('Saved successfully!', 'success');
    });
    return true;
  };

  useEffect(() => {
    queryChannel();
  }, []);

  const handleSave = () => {
    if(!updated || !canSave) return;
    updateAssembly().then();
  }

  const channelNameList: string[] = channelList?.map((channel) => channel.name) ?? [];
  const channelOnRecord = channelList?.find(channel => channel.id === record.channel_id);

  const updated = (
    nameInput !== record.name ||
    selectedChannelName !== channelOnRecord?.name ||
    specSheetUrlInput !== (record.spec_sheet_url ?? '')
  );
  
  const canSave = (
    nameInput !== '' &&
    selectedChannelName !== null &&
    selectedChannelName !== ''
  );  

  return (
    <Card>
      <CardContent>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' flexDirection='row' gap={2}>
            <TextField 
              label='Assembly Name'
              value={nameInput} 
              onChange={(e) => setNameInput(e.target.value)}
              updated={nameInput !== record.name}
            />
            <Autocomplete
              disablePortal
              id="channels"
              options={channelNameList}
              renderInput={(params) => (
                <TextField
                  label="Channel"
                  updated={selectedChannelName !== channelOnRecord?.name}
                  {...params}
                />
              )}
              value={selectedChannelName}
              onChange={(event, newValue) => setSelectedChannelName(newValue)}
              inputValue={channelSearchInput}
              onInputChange={(event, newValue) => setChannelSearchInput(newValue)}
              size="small"
              sx={{
                minWidth: '200px',
                "& label.Mui-focused": {
                  color: colors.blueAccent[500],
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: colors.blueAccent[500],
                  },
                },
              }}
            />
          </Box>
          <TextField 
            label='Spec Sheet URL'
            value={specSheetUrlInput} 
            onChange={(e) => setSpecSheetUrlInput(e.target.value)}
            updated={specSheetUrlInput !== (record.spec_sheet_url ?? '')}
          />
          <Divider />
          <Box>
            <Button 
              color='secondary' 
              variant='contained' 
              startIcon={<SaveOutlinedIcon />}
              disabled={!updated || !canSave}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BasicDetails;