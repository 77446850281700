import React, { useCallback, useEffect, useState } from "react";
import { getEasySpecImageUrl } from "../../../../database/Products";

type ProductImageProps = {
  fileName: string;
  altText?: string;
  width?: number;
  height?: number;
}

const ProductImage = ({ fileName, altText, width, height }: ProductImageProps) => {
  
  const [url, setUrl] = useState<string>('');

  const queryUrl = useCallback(async () => {
    const data = await getEasySpecImageUrl(fileName);
    if(data) setUrl(data);
  }, []);

  useEffect(() => {
    queryUrl();
  }, []);

  const widthStr = width ? `${width}px` : '';
  const heightStr = height ? `${height}px` : '';

  return (
    <img src={url} alt={altText ?? ''} width={widthStr} height={heightStr} />
  );
};

export default ProductImage;