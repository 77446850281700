export const CurrencyInputToNumber = (input: string) => {
  // Remove all non-digit, non-dot characters
  input = input.replace(/[^0-9]/g, "");

  // Remove leading zeros
  input = input.replace(/^0+/, "");

  if (input === "") return 0;

  // Convert the cleaned string to a number
  const wholeNumber = parseFloat(input);
  const withCents = wholeNumber / 100;

  return withCents;
};
