import React, { useCallback, useEffect, useState } from 'react';
import { LedTapeOptionType, LedTapeOptionValueRecord } from '../../../../../../../database/Products';
import { supabase_products } from '../../../../../../../database/supabaseClient';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, Button, Card, CardContent, CircularProgress, Divider, MenuItem, Typography, useTheme } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import OptionValue from './OptionValue';
import { StrictModeDroppable } from '../../../../../../../components/StrictModeDroppable';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeepCopy from '../../../../../../../utils/DeepCopy';
import { SelectField } from '../../../../components/TextField';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { DisplayAlert } from '../../../../types';

type OptionProps = {
  ledTapeSlug: string;
  type: LedTapeOptionType;
  displayAlert: DisplayAlert;
}

const Option = ({ ledTapeSlug, type, displayAlert }: OptionProps) => {
  const colors = useTheme().colors;
  
  const [defaultValue, setDefaultValue] = useState<number | undefined>(undefined);
  const [defaultValueInput, setDefaultValueInput] = useState<number | undefined>(undefined);
  const [values, setValues] = useState<LedTapeOptionValueRecord[]>([]);
  const [newOptionValues, setNewOptionValues] = useState<(Pick<LedTapeOptionValueRecord, 'type' | 'led_tape_slug'> & { randId: number })[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [moving, setMoving] = useState<boolean>(false);

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('led_tape_option_values')
    .select('*')
    .eq('led_tape_slug', ledTapeSlug)
    .eq('type', type)
    .order('display_order', { ascending: true });
    if(error) console.error(error);
    setValues(data ?? []);
    const defaultValue = data?.find(d => d.default === true);
    if(defaultValue){
      setDefaultValue(defaultValue.id);
      setDefaultValueInput(defaultValue.id);
    }
    if(loading) setLoading(false);
  }, []);

  const updateDisplayOrderData = useCallback(async (values: Pick<LedTapeOptionValueRecord, 'id' | 'display_order'>[]) => {
    try {
      const updatePromises = values.map(async (value) => {
        const { data, error } = await supabase_products
          .from('led_tape_option_values')
          .update({ display_order: value.display_order })
          .eq('id', value.id)
          .select('*').single();  
        if (error) console.error(`Error updating record ID ${value.id}:`, error);
        return data;
      });
      await Promise.all(updatePromises);
      displayAlert('All records updated successfully!', 'success');
      return true;
    } catch (error) {
      console.error('Error updating records:', error);
      displayAlert(`Error updating records: ${error}`, 'error')
      return false;
    }
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const handleAddNew = () => {
    const newOptionvalue = { type: type, led_tape_slug: ledTapeSlug, randId: Math.random() };
    const newOptions = [...newOptionValues, newOptionvalue];
    setNewOptionValues(newOptions);
  }

  const handleUpdateDefault = async () => {
    if(defaultValue){
      await supabase_products
        .from('led_tape_option_values')
        .update({ default: false })
        .eq('id', defaultValue);
    }
    if(!defaultValueInput) return;
    const { data, error } = await supabase_products
      .from('led_tape_option_values')
      .update({ default: true })
      .eq('id', defaultValueInput)
      .select('*').single();
    if(error) console.error(error.message);
    if(!data) {
      displayAlert('Error setting deafult value.', 'error');
      return;
    }
    queryData().then(() => {
      displayAlert('Default value saved!', 'success');
    });
  }

  const handleInsertedNew = (randId: number) => {
    removeNewEntry(randId);
    queryData();
  }

  const handleCancelNew = (randId: number) => {
    removeNewEntry(randId);
  }

  const removeNewEntry = (randId: number) => {
    const optionValues = [...newOptionValues];
    const targetIndex = optionValues.findIndex(optValue => optValue.randId === randId);
    optionValues.splice(targetIndex, 1);
    setNewOptionValues(optionValues);
  }

  const onDragEnd = (result: DropResult) => {
    setMoving(false);
    const { destination, source, draggableId } = result;
    if(!destination) return;
    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ){
      return;
    }
    const targetValue = values.find(value => value.id.toString() === draggableId);
    if(!targetValue) return;
    const oldValues = Array.from(values.map(v=>DeepCopy<LedTapeOptionValueRecord>(v)));
    const splicedValues = Array.from(values);
    splicedValues.splice(source.index, 1);
    splicedValues.splice(destination.index, 0, targetValue);
    const reorderedValues = splicedValues.map((value, i) => {
      value.display_order = i;
      return value;
    });
    setValues(reorderedValues);
    const recordsThatChangedPositions = reorderedValues.filter(reVal => {
      const matchedRecord = oldValues.find(value => value.id === reVal.id);
      if(!matchedRecord) return false;
      if(matchedRecord.display_order === reVal.display_order) return false;
      return true;
    });
    const recordsToUpdate = recordsThatChangedPositions.map((value) => ({ id: value.id, display_order: value.display_order }));
    updateDisplayOrderData(recordsToUpdate).then(successful => {
      if(!successful) setValues(oldValues);
    });
  }

  return (
    <DragDropContext onDragStart={() => setMoving(true)} onDragEnd={onDragEnd} >
      <Card>
        <CardContent>
          <Typography variant="h5">{type}</Typography>
          <Divider sx={{ marginBottom: '10px' }} />
          <Box px={2}>

            {
              values.length > 0 &&
              <Box display='flex' flexDirection='row' alignItems='flex-end' gap={2} mb={2}>
                <SelectField
                  label='Default'
                  value={defaultValueInput ?? ''}
                  onChange={(e) => setDefaultValueInput(Number(e.target.value))}
                  updated={false}
                  minWidth='150px'
                >
                  {
                    values.map(value=> (
                      <MenuItem key={value.id} value={value.id}>
                        {value.display_name}
                      </MenuItem>
                    ))
                  }
                </SelectField>
                <Button 
                  variant='contained' 
                  color='secondary'
                  onClick={() => handleUpdateDefault()}
                  startIcon={<SaveOutlinedIcon />}
                  disabled={defaultValue === defaultValueInput}
                >
                  Save
                </Button>
              </Box>
            }
            {
              loading &&
              <CircularProgress />
            }
            <StrictModeDroppable droppableId={type}>
              {
                (provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      values.map((value, i) => (
                        <Draggable key={value.id} draggableId={value.id.toString()} index={i}>
                          {
                            (provided) => (
                              <Box 
                                {...provided.draggableProps} ref={provided.innerRef} 
                                display='flex' flexDirection='row' alignItems='center' gap={2} mb={1} py={0.5}
                                sx={{
                                  '&:hover': {
                                    boxShadow: moving ? '' : colors.boxShadow,
                                  },
                                }}
                              >
                                <Box {...provided.dragHandleProps}>
                                  <DragIndicatorOutlinedIcon />
                                </Box>
                                <OptionValue
                                  record={value} 
                                  ledTapeSlug={ledTapeSlug} 
                                  type={type}
                                  displayAlert={displayAlert} 
                                />
                              </Box>
                            )
                          }
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                  </Box>
                )
              }
            </StrictModeDroppable>
            {
              newOptionValues.map((newValue) => (
                <Box key={newValue.randId} mb={2}>
                  <OptionValue 
                    ledTapeSlug={ledTapeSlug}
                    type={type} 
                    displayAlert={displayAlert}
                    nextDisplayOrder={values.length}
                    onInserted={() => handleInsertedNew(newValue.randId)}
                    onCancelNew={() => handleCancelNew(newValue.randId)}
                  />
                </Box>
              ))
            }
            <Box>
              <Button 
                startIcon={<AddOutlinedIcon />}
                onClick={() => handleAddNew()}
              >
                Add
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </DragDropContext>
  );
};

export default Option;