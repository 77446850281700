import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import OptionValue from './OptionValue';
import { StrictModeDroppable } from '../../../../../../components/StrictModeDroppable';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeepCopy from '../../../../../../utils/DeepCopy';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { DisplayAlert } from '../../../types';

type LensOptionProps = {
  channelAssemblyId: number;
  selectValues: (channelAssemblyId: number) => Promise<ValueRecord[]>;
  updateDisplayOrder: (values: Pick<ValueRecord, 'id' | 'display_order'>[]) => Promise<boolean>;
  insertValue: (newRecord: NewValueRecord) => Promise<PostgrestSingleResponse<ValueRecord>>;
  updateValue: (record: Pick<ValueRecord, 'id' | 'name' | 'sku_code' | 'easy_spec_code'>) =>
    Promise<PostgrestSingleResponse<ValueRecord>>;
  displayAlert: DisplayAlert;
}

const Option = ({ channelAssemblyId, selectValues, updateDisplayOrder, insertValue, updateValue, displayAlert }: LensOptionProps) => {
  const colors = useTheme().colors;

  const [values, setValues] = useState<ValueRecord[]>([]);
  const [newOptionValues, setNewOptionValues] = useState<{ channel_assembly_id: number; randId: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [moving, setMoving] = useState<boolean>(false);

  const queryData = useCallback(async () => {
    const data = await selectValues(channelAssemblyId);
    setValues(data ?? []);
    if(loading) setLoading(false);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const handleAddNew = () => {
    const newOptionvalue = { channel_assembly_id: channelAssemblyId, randId: Math.random() };
    const newOptions = [...newOptionValues, newOptionvalue];
    setNewOptionValues(newOptions);
  }

  const handleInsertedNew = (randId: number) => {
    removeNewEntry(randId);
    queryData();
  }

  const handleCancelNew = (randId: number) => {
    removeNewEntry(randId);
  }

  const removeNewEntry = (randId: number) => {
    const optionValues = [...newOptionValues];
    const targetIndex = optionValues.findIndex(optValue => optValue.randId === randId);
    optionValues.splice(targetIndex, 1);
    setNewOptionValues(optionValues);
  }

  const onDragEnd = (result: DropResult) => {
    setMoving(false);
    const { destination, source, draggableId } = result;
    if(!destination) return;
    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ){
      return;
    }
    const targetValue = values.find(value => value.id.toString() === draggableId);
    if(!targetValue) return;
    const oldValues = Array.from(values.map(v=>DeepCopy<ValueRecord>(v)));
    const splicedValues = Array.from(values);
    splicedValues.splice(source.index, 1);
    splicedValues.splice(destination.index, 0, targetValue);
    const reorderedValues = splicedValues.map((value, i) => {
      value.display_order = i;
      return value;
    });
    setValues(reorderedValues);
    const recordsThatChangedPositions = reorderedValues.filter(reVal => {
      const matchedRecord = oldValues.find(value => value.id === reVal.id);
      if(!matchedRecord) return false;
      if(matchedRecord.display_order === reVal.display_order) return false;
      return true;
    });
    const recordsToUpdate = recordsThatChangedPositions.map((value) => ({ id: value.id, display_order: value.display_order }));
    updateDisplayOrder(recordsToUpdate).then(successful => {
      if(!successful){
        setValues(oldValues);
        displayAlert(`Error updating records.`, 'error');
      }else{
        displayAlert('All records updated successfully!', 'success');
      }
    });
  }

  return (
    <DragDropContext onDragStart={() => setMoving(true)} onDragEnd={onDragEnd} >
      {
        loading &&
        <CircularProgress />
      }
      <StrictModeDroppable droppableId='Lenses'>
        {
          (provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {
                values.map((value, i) => (
                  <Draggable key={value.id} draggableId={value.id.toString()} index={i}>
                    {
                      (provided) => (
                        <Box 
                          {...provided.draggableProps} ref={provided.innerRef} 
                          display='flex' flexDirection='row' alignItems='center' gap={2} mb={1} py={0.5}
                          sx={{
                            '&:hover': {
                              boxShadow: moving ? '' : colors.boxShadow,
                            },
                          }}
                        >
                          <Box {...provided.dragHandleProps}>
                            <DragIndicatorOutlinedIcon />
                          </Box>
                          <OptionValue
                            record={value}
                            channelAssemblyId={channelAssemblyId}
                            InsertRecord={insertValue}
                            UpdateRecord={updateValue}                                
                            displayAlert={displayAlert} 
                          />
                        </Box>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </Box>
          )
        }
      </StrictModeDroppable>
      {
        newOptionValues.map((newValue) => (
          <Box key={newValue.randId} mb={2}>
            <OptionValue
              channelAssemblyId={channelAssemblyId}
              InsertRecord={insertValue}
              UpdateRecord={updateValue}
              nextDisplayOrder={values.length}
              onInserted={() => handleInsertedNew(newValue.randId)}
              onCancelNew={() => handleCancelNew(newValue.randId)}
              displayAlert={displayAlert} 
            />
          </Box>
        ))
      }
      <Box>
        <Button 
          startIcon={<AddOutlinedIcon />}
          onClick={() => handleAddNew()}
        >
          Add
        </Button>
      </Box>
    </DragDropContext>
  );
};

export default Option;