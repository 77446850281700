import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { supabase_products } from "../../../../../../database/supabaseClient";
import { LedTapeOptionValueRecord, LedTapeOptionValuesConflictRecord } from "../../../../../../database/Products";
import Conflict from "./components/Conflict";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NewConflict from "./components/NewConflict";
import { DisplayAlert } from "../../../types";

type ConflictsProps = {
  ledTapeSlug: string;
  displayAlert: DisplayAlert;
}

const Conflicts = ({ ledTapeSlug, displayAlert }: ConflictsProps) => {
  
  const colors = useTheme().colors;

  const [optionValues, setOptionValues] = useState<LedTapeOptionValueRecord[] | null>(null);
  const [conflictsData, setConflictsData] = useState<LedTapeOptionValuesConflictRecord[] | null>(null);
  const [newConflicts, setNewConflicts] = useState<number[]>([]);

  const queryData = useCallback(async() => {
    const [optionValuesRes, conflictsRes] = await Promise.all([
      supabase_products
      .from('led_tape_option_values')
      .select('*')
      .eq('led_tape_slug', ledTapeSlug)
      .order('display_order', { ascending: true }),
      supabase_products
      .from('led_tape_option_values_conflicts')
      .select('*')
      .eq('led_tape_slug', ledTapeSlug)
      .order('option_value_id_1')
      .order('option_value_id_2')
    ]);
    if(optionValuesRes.error) console.log(optionValuesRes.error.message);
    if(conflictsRes.error) console.log(conflictsRes.error.message);
    setOptionValues(optionValuesRes.data ?? []);
    setConflictsData(conflictsRes.data ?? []);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const handleAddNewConflict = () => {
    setNewConflicts([...newConflicts, Math.random() * 100]);
  }

  const handleAfterInsertNewConflict = (targetId: number) => {
    queryData().then(() => {
      displayAlert('Conflict saved!', 'success');
    });
    handleCancelNewConflict(targetId);
  }

  const handleCancelNewConflict = (targetId: number) => {
    const updatedConflicts = newConflicts.filter(conflict => conflict !== targetId);
    setNewConflicts(updatedConflicts);
  }

  const conflicts = conflictsData ? conflictsData?.map(conflict => {
    const option1 = optionValues?.find(val => val.id === conflict.option_value_id_1);
    const option2 = optionValues?.find(val => val.id === conflict.option_value_id_2);
    return {
      id: conflict.id,
      option1: `${option1?.type ?? ''}: ${option1?.display_name}`,
      option2: `${option2?.type ?? ''}: ${option2?.display_name}`
    }
  }) : [];

  return (
    <Box>
      <TableContainer component={Paper} sx={{ maxWidth: '750px'}}>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TableCell>
                <Typography><b>Selecting this...</b></Typography>
              </TableCell>
              <TableCell>
                <Typography><b>Will disable this...</b></Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              optionValues && conflictsData &&
              conflicts.map(conflict => (
                <Conflict
                  key={conflict.id}
                  id={conflict.id}
                  option1={conflict.option1}
                  option2={conflict.option2}
                  onRemove={() => queryData()}
                />
              ))
            }
            {
              optionValues &&
              newConflicts.map(randId => (
                <NewConflict
                  key={randId}
                  ledTapeSlug={ledTapeSlug}
                  optionValues={optionValues}
                  onInserted={() => handleAfterInsertNewConflict(randId)}
                  onCancel={() => handleCancelNewConflict(randId)}
                  displayAlert={displayAlert}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Button 
          onClick={handleAddNewConflict}
          startIcon={<AddOutlinedIcon />}
        >
          Add Conflict
        </Button>
      </Box>
    </Box>
  );
};

export default Conflicts;