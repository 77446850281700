import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Snackbar,
  Alert,
  Chip,
  Typography,
  useTheme,
  SnackbarCloseReason,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTableCell from "./DataTableCell";
import EditButton from "./EditButton";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

type DataTableProps<T> = {
  title: string;
  records: (T & {active: boolean;})[];
  idInjections?: { [K in keyof T]?: number; };
  displayColumns: {
    display: string;
    field: string;
    lookupData?: any[];
    options?: object;
  }[],
  configForm: (customProps: any) => JSX.Element;
  reloadData: () => Promise<void>,
  cancelConfig: () => void;
  resetView?: boolean;
}

const DataTable = <T,>({ title, records, idInjections, displayColumns, reloadData, configForm, resetView = true }: DataTableProps<T>) => {
  const colors = useTheme().colors;
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [displaySuccess, setDisplaySuccessAlert] = useState<boolean>(false);
  const [displayError, setDisplayErrorAlert] = useState<boolean>(false);

  const snackBarPosition: {vertical: "bottom", horizontal: "center"} = { vertical: "bottom", horizontal: "center" };

  function onClickAdd() {
    setEditingIndex(null);
    setIsNew(true);
    if (resetView) window.scrollTo(0, 0);
  }
  function onClickEdit(index: number) {
    setEditingIndex(index);
    setIsNew(false);
    if (resetView) window.scrollTo(0, 0);
  }
  function onCancelEditClick() {
    setEditingIndex(null);
    setIsNew(false);
  }

  const handleCloseSuccessAlert = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") return;
    setDisplaySuccessAlert(false);
  };
  const handleCloseErrorAlert = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") return;
    setDisplayErrorAlert(false);
  };

  async function updateDatabase(record: object, databaseUpdate: (record: object) => Promise<PostgrestSingleResponse<null>>) {
    setEditingIndex(null);
    setIsNew(false);
    const { error } = await databaseUpdate(record);
    if (error) {
      console.error(error);
      setDisplayErrorAlert(true);
    } else {
      await reloadData();
      setDisplaySuccessAlert(true);
    }
  }

  return (
    <>
      <h3>{title}</h3>
      {editingIndex === null && isNew === false ? (
        <TableContainer sx={{ marginBottom: "50px" }}>
          <Table>
            <TableHead sx={{ background: colors.primary[400] }}>
              <TableRow>
                {records[0]?.active !== undefined && (
                  <TableCell>
                    <Typography>
                      <strong>Active</strong>
                    </Typography>
                  </TableCell>
                )}
                {displayColumns.map((c, k) => (
                  <TableCell key={k}>
                    <Typography>
                      <strong>{c.display}</strong>
                    </Typography>
                  </TableCell>
                ))}
                <TableCell align="right">
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    startIcon={<AddIcon />}
                    onClick={() => onClickAdd()}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records?.map((record, rowIndex) => (
                <TableRow key={rowIndex} hover>
                  {record.active !== undefined && (
                    <TableCell>
                      <Chip
                        label={record.active ? "Enabled" : "Disabled"}
                        color={record.active ? "success" : "warning"}
                        size="small"
                      ></Chip>
                    </TableCell>
                  )}
                  {displayColumns.map((column, colIndex) => (
                    <DataTableCell
                      key={colIndex}
                      record={record}
                      column={column}
                    />
                  ))}
                  <TableCell align="right">
                    <EditButton onClickEdit={() => onClickEdit(rowIndex)} />
                  </TableCell>
                </TableRow>
              ))}
              {/*expanded && (
                <TableRow>
                  <TableCell colSpan={displayColumns.length}>
                    {expanded.content}
                  </TableCell>
                </TableRow>
              )*/}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        configForm({
          record: isNew ? { ...idInjections } : records[editingIndex ?? 0],
          isNew: isNew,
          onCancel: onCancelEditClick,
          onSave: updateDatabase,
          reloadData: reloadData,
        })
      )}
      <Snackbar
        open={displaySuccess}
        autoHideDuration={3500}
        onClose={handleCloseSuccessAlert}
        anchorOrigin={snackBarPosition}
      >
        <Alert
          onClose={handleCloseSuccessAlert}
          severity="success"
          sx={{ width: "100%", fontSize: "20px" }}
        >
          Updated product data successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={displayError}
        autoHideDuration={3500}
        onClose={handleCloseErrorAlert}
        anchorOrigin={snackBarPosition}
      >
        <Alert
          onClose={handleCloseErrorAlert}
          severity="error"
          sx={{ width: "100%", fontSize: "20px" }}
        >
          There was an error updating the product data.
        </Alert>
      </Snackbar>
    </>
  );
};

export default DataTable;
