import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tokens } from "../theme";

const StyledTextField = styled(TextField)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  return {
    "& label.Mui-focused": {
      color: colors.blueAccent[500],
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.blueAccent[500],
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: colors.blueAccent[500],
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.blueAccent[500],
      },
    },
  };
});

export const LoginTextField = styled(StyledTextField)(() => {
  return {
    margin: "10px",
    width: "250px",
  };
});

export default StyledTextField;
