import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { default as TabPanel } from "../../../../components/StyledTabPanel";
import DataTable from "../../../../components/DataTable";
import ConfigProjectTypeForm, { ConfigProjectTypeFormProps } from "./ConfigProjectTypeForm";
import ConfigProjectControllerCombinationForm, { ConfigProjectControllerCombinationFormProps } from "./ConfigProjectControllerCombinationForm";
import ConfigServiceComponentForm, { ConfigServiceComponentFormProps } from "./ConfigServiceComponentForm";
import { BaseComboRecord, ControllerTypeRecord, ProjectTypeRecord } from "../../../../database/AppsDescGenToolTypes";
import { ServiceComponentObj } from "../data";
import ConfigControllerTypeForm, { ConfigControllerTypeFormProps } from "./ConfigControllerTypeForm";

type ConfigProps = {
  cancelConfig: () => void;
  projectTypes: ProjectTypeRecord[];
  controllerTypes: ControllerTypeRecord[];
  baseCombos: BaseComboRecord[];
  serviceComponents: ServiceComponentObj[];
  reloadData: () => Promise<void>;
}

const Config = ({ cancelConfig, projectTypes, controllerTypes, baseCombos, serviceComponents, reloadData }: ConfigProps) => {

  const [tabValue, setTabVal] = useState<number>(0);

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabVal(newValue);
  };

  return (
    <Box>
      <Box marginBottom="15px">
        <Button
          variant="outlined"
          color="info"
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={cancelConfig}
        >
          Back to Tool
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={changeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Project Types" />
          <Tab label="Controller Types" />
          <Tab label="Projects + Controllers" />
          <Tab label="Service Components" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <DataTable<ProjectTypeRecord>
          title="Project Types"
          records={projectTypes}
          displayColumns={[{ display: "Name", field: "name" }]}
          configForm={(customProps: ConfigProjectTypeFormProps) => (
            <ConfigProjectTypeForm {...customProps} />
          )}
          reloadData={reloadData}
          cancelConfig={cancelConfig}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DataTable<ControllerTypeRecord>
          title="Controller Types"
          records={controllerTypes}
          displayColumns={[{ display: "Name", field: "name" }]}
          configForm={(customProps: ConfigControllerTypeFormProps) => (
            <ConfigControllerTypeForm {...customProps} />
          )}
          reloadData={reloadData}
          cancelConfig={cancelConfig}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DataTable<BaseComboRecord>
          title="Project + Controller Combos"
          records={baseCombos}
          displayColumns={[
            {
              display: "Project Type",
              field: "project_type_id",
              lookupData: projectTypes,
            },
            {
              display: "Controller Type",
              field: "controller_type_id",
              lookupData: controllerTypes,
            },
            { display: "Price", field: "price" },
          ]}
          configForm={(customProps: ConfigProjectControllerCombinationFormProps) => (
            <ConfigProjectControllerCombinationForm {...customProps} projectTypes={projectTypes} controllerTypes={controllerTypes} />
          )}
          reloadData={reloadData}
          cancelConfig={cancelConfig}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <DataTable<ServiceComponentObj>
          title="Service Components"
          records={serviceComponents}
          displayColumns={[
            { display: "Name", field: "name" },
            { display: "Default Price", field: "default_price" },
          ]}
          configForm={(customProps: ConfigServiceComponentFormProps) => (
            <ConfigServiceComponentForm {...customProps} projectTypes={projectTypes} controllerTypes={controllerTypes} />
          )}
          reloadData={reloadData}
          cancelConfig={cancelConfig}
        />
      </TabPanel>
    </Box>
  );
};

export default Config;
