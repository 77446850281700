import { Box, Card, CardContent, CircularProgress, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ChannelFinishRecord, ChannelLensRecord, ChannelMountingRecord, QbItemChannelAssemblyConflicts, QbItemChannelAssemblyDependencies } from '../../../../../../database/Products';
import { supabase_products } from '../../../../../../database/supabaseClient';
import QbItems from '../../../components/QbItems';
import { CreateQbRelFinish, CreateQbRelLens, CreateQbRelMount, DeleteQbRelFinish, DeleteQbRelLens, DeleteQbRelMounts, GetQbRelFinish, GetQbRelLens, GetQbRelMount, SaveQbRelFinish, SaveQbRelLens, SaveQbRelMounts } from '../../../data';

type QbItemsConfigProps = {
  channelAssemblyId: number;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const QbItemsConfig = ({ channelAssemblyId, displayAlert }: QbItemsConfigProps) => {

  const [lensList, setLensList] = useState<ChannelLensRecord[] | null>(null);
  const [mountList, setMountList] = useState<ChannelMountingRecord[] | null>(null);
  const [finishList, setFinishList] = useState<ChannelFinishRecord[] | null>(null);

  const queryListData = useCallback(async () => {
    const [lensRes, mountRes, finishRes] = await Promise.all([
      supabase_products.from('channel_lenses').select('*').eq('channel_assembly_id', channelAssemblyId).order('display_order'),
      supabase_products.from('channel_mountings').select('*').eq('channel_assembly_id', channelAssemblyId).order('display_order'),
      supabase_products.from('channel_finishes').select('*').eq('channel_assembly_id', channelAssemblyId).order('display_order')
    ]);
    setLensList(lensRes.data ?? []);
    setMountList(mountRes.data ?? []);
    setFinishList(finishRes.data ?? []);
  }, []);

  useEffect(() => {
    queryListData();
  } , []);

  return (
    <Card>
      <CardContent>
        <Box>
          <Typography>Lenses</Typography>
          <Divider sx={{ marginBottom: '15px' }} />
          <Box display='flex' flexDirection='column' gap={2} px={4} mb={4}>
            {
              !lensList ?
              <CircularProgress />
              :
              lensList.map(lens => (
                <Box key={`Lens${lens.id}`}>
                  <Typography>{lens.name}</Typography>
                  <Divider sx={{ marginBottom: '15px' }} />
                  <Box px={2}>
                    <QbItems
                      lensOptions={lensList?.map(l => ({id: l.id, name: l.name})) ?? []}
                      mountingOptions={mountList?.map(m => ({id: m.id, name: m.name})) ?? []}
                      finishOptions={finishList?.map(f => ({id: f.id, name: f.name})) ?? []}
                      getQbRelation={() => GetQbRelLens(lens.id)}
                      createQbRelation={(listId: string) => CreateQbRelLens(listId, lens.id)}
                      saveQbRelation={(quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string) => SaveQbRelLens(quantity, dependencies, conflicts, listId, lens.id)}
                      deleteQbRelation={(listId: string) => DeleteQbRelLens(listId, lens.id)}
                      displayAlert={displayAlert}
                    />
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box>
          <Typography>Mountings</Typography>
          <Divider sx={{ marginBottom: '15px' }} />
          <Box display='flex' flexDirection='column' gap={2} px={4} mb={4}>
            {
              !mountList ?
              <CircularProgress />
              :
              mountList.map(mount => (
                <Box key={`Lens${mount.id}`}>
                  <Typography>{mount.name}</Typography>
                  <Divider sx={{ marginBottom: '15px' }} />
                  <Box px={2}>
                    <QbItems
                      lensOptions={lensList?.map(l => ({id: l.id, name: l.name})) ?? []}
                      mountingOptions={mountList?.map(m => ({id: m.id, name: m.name})) ?? []}
                      finishOptions={finishList?.map(f => ({id: f.id, name: f.name})) ?? []}
                      getQbRelation={() => GetQbRelMount(mount.id)}
                      createQbRelation={(listId: string) => CreateQbRelMount(listId, mount.id)}
                      saveQbRelation={(quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts,  listId: string) => SaveQbRelMounts(quantity, dependencies, conflicts, listId, mount.id)}
                      deleteQbRelation={(listId: string) => DeleteQbRelMounts(listId, mount.id)}
                      displayAlert={displayAlert}
                    />
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box>
          <Typography>Finishes</Typography>
          <Divider sx={{ marginBottom: '15px' }} />
          <Box display='flex' flexDirection='column' gap={2} px={4} mb={4}>
            {
              !finishList ?
              <CircularProgress />
              :
              finishList.map(finish => (
                <Box key={`Lens${finish.id}`}>
                  <Typography>{finish.name}</Typography>
                  <Divider sx={{ marginBottom: '15px' }} />
                  <Box px={2}>
                    <QbItems
                      lensOptions={lensList?.map(l => ({id: l.id, name: l.name})) ?? []}
                      mountingOptions={mountList?.map(m => ({id: m.id, name: m.name})) ?? []}
                      finishOptions={finishList?.map(f => ({id: f.id, name: f.name})) ?? []}
                      getQbRelation={() => GetQbRelFinish(finish.id)}
                      createQbRelation={(listId: string) => CreateQbRelFinish(listId, finish.id)}
                      saveQbRelation={(quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string) => SaveQbRelFinish(quantity, dependencies, conflicts, listId, finish.id)}
                      deleteQbRelation={(listId: string) => DeleteQbRelFinish(listId, finish.id)}
                      displayAlert={displayAlert}
                    />
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default QbItemsConfig;