import React from 'react';
import { ChannelAssemblyRecord } from '../../../../../database/Products';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import Option from './components/Option';
import { InsertFinish, InsertLens, InsertMounting, SelectFinishes, SelectLenses, SelectMountings, UpdateFinish, UpdateFinishDisplayOrder, UpdateLens, UpdateLensDisplayOrder, UpdateMounting, UpdateMountingDisplayOrder } from '../../data';
import { DisplayAlert } from '../../types';

type OptionsProps = {
  record: ChannelAssemblyRecord;
  displayAlert: DisplayAlert;
}

const Options = ({ record, displayAlert }: OptionsProps) => {

  return (
    <Card>
      <CardContent>
        <Typography>Options Configuration</Typography>
        <Divider sx={{ marginBottom: '15px' }} />
        <Box display='flex' flexDirection='column' gap={2} px={2}>
          <Box>
            <Typography>Lenses</Typography>
            <Divider sx={{ marginBottom: '15px' }} />
            <Box px={2}>
              <Option
                channelAssemblyId={record.id}
                selectValues={SelectLenses}
                updateDisplayOrder={UpdateLensDisplayOrder}
                insertValue={InsertLens}
                updateValue={UpdateLens}
                displayAlert={displayAlert}
              />
            </Box>
          </Box>
          <Box>
            <Typography>Mountings</Typography>
            <Divider sx={{ marginBottom: '15px' }} />
            <Box px={2}>
            <Option
                channelAssemblyId={record.id}
                selectValues={SelectMountings}
                updateDisplayOrder={UpdateMountingDisplayOrder}
                insertValue={InsertMounting}
                updateValue={UpdateMounting}
                displayAlert={displayAlert}
              />
            </Box>
          </Box>
          <Box>
            <Typography>Finishes</Typography>
            <Divider sx={{ marginBottom: '15px' }} />
            <Box px={2}>
            <Option
                channelAssemblyId={record.id}
                selectValues={SelectFinishes}
                updateDisplayOrder={UpdateFinishDisplayOrder}
                insertValue={InsertFinish}
                updateValue={UpdateFinish}
                displayAlert={displayAlert}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Options;