import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { SpecSheet } from "../types";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { insertEasySpecSheet, updateEasySpecSheet, uploadEasySpecSheet } from "../data";
import SpecSheetUpload from "./SpecSheetUpload";
import { getEasySpecSheetUrl } from "../../../../database/Products";

type SpecSheetListProps = {
  specSheets: SpecSheet[];
  reloadData: () => void;
  onSelectedTape?: (id: number) => void;
  onSelectedChannelAssembly?: (id: number) => void;
  displayAlert: (message: string, severity: "error" | "success") => void;
}

const SpecSheetList = (props: SpecSheetListProps) => {
  const { specSheets, reloadData, onSelectedTape, onSelectedChannelAssembly, displayAlert } = props;
  const colors = useTheme().colors;

  const [editSpecSheet, setEditSpecSheet] = useState<SpecSheet | null>(null);

  const handleDownloadImage = (fileName: string) => {
    getEasySpecSheetUrl(fileName).then(url => {
      if(!url) return;
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = url.slice(url.lastIndexOf('.'));
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  };

  const handleUpdateUploadClick = async (specSheet: SpecSheet, file: File) => {
    const fileName = await uploadEasySpecSheet(file);
    if(fileName === null) {
      displayAlert('Error uploading the image file.', 'error');
      return;
    }
    let success = false;
    if(specSheet.spec_sheet === ''){
      success = await insertEasySpecSheet(specSheet.ez_channe_assembly_id, specSheet.ez_led_tape_id, fileName);
    }else{
      success = await updateEasySpecSheet(specSheet.ez_channe_assembly_id, specSheet.ez_led_tape_id, fileName);      
    }
    if(success) {
      setEditSpecSheet(null);
      reloadData();
      displayAlert(`Successfully updated the spec sheet.`, 'success');
    }else{
      displayAlert(`Error updating the spec sheet.`, 'error');
    }
  };

  const handleOnSelectedTape = (id: number) => onSelectedTape ? onSelectedTape(id) : null;
  const handleOnSelectedChannel = (id: number) => onSelectedChannelAssembly ? onSelectedChannelAssembly(id) : null;

  const hover = {
    '&:hover': {
      color: colors.blueAccent[500], // Change 'blue' to your desired color
      cursor: 'pointer'
    }
  };

  return (
    <>
    {
      specSheets.map((specSheet, i) => (
        <TableRow key={i}>
          <TableCell
            onClick={() => handleOnSelectedTape(specSheet.ez_led_tape_id)}
            sx={hover}
          >
            <Typography>{specSheet.ledTape}</Typography>
          </TableCell>
          <TableCell 
            onClick={() => handleOnSelectedChannel(specSheet.ez_channe_assembly_id)}
            sx={hover}
          >
            <Typography>{specSheet.channelAssembly}</Typography>
          </TableCell>
          {
            specSheet.spec_sheet === '' &&
            <>
              <TableCell>
                <Box display="flex" flexDirection='row' gap={1} px="4px" color={colors.redAccent[500]}>
                  <CancelOutlinedIcon />
                  <Typography>Missing</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Button 
                  variant="contained"
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => setEditSpecSheet(specSheet)}
                >
                  Upload
                </Button>
              </TableCell>
            </>
          }
          {
            specSheet.spec_sheet !== '' &&
            <>
              <TableCell>
                <Button 
                  color="success" 
                  startIcon={<CheckCircleOutlinedIcon />}
                  onClick={() => handleDownloadImage(specSheet.spec_sheet)}
                >
                  Download
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined" 
                  color="info" 
                  size="small" 
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => setEditSpecSheet(specSheet)}
                >
                  Update
                </Button>
              </TableCell>
            </>
          }
        </TableRow>
      ))
    }
    <Dialog
      open={editSpecSheet !== null}
      onClose={() => setEditSpecSheet(null)}
    >
      <DialogTitle>{editSpecSheet?.spec_sheet !== undefined && editSpecSheet.spec_sheet !== '' ? 'Update' : 'Upload'} Spec Sheet</DialogTitle>
      <DialogContent sx={{ minWidth: '500px'}}>
        {
          editSpecSheet &&
          <SpecSheetUpload specSheet={editSpecSheet} handleUpload={handleUpdateUploadClick} />
        }
      </DialogContent>
    </Dialog>  
    </>
  );
};

export default SpecSheetList;