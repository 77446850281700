import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { supabase_products } from '../../../../../database/supabaseClient';
import { ChannelRecord } from '../../../../../database/Products';
import { ClickableTextCell, HeadCell, StyledTableRowHover, TextCell } from '../../components/table';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { TextField } from '../../components/TextField';
import AddProduct from '../../components/AddProduct';

type ChannelListProps = {
  displayAlert: (message: string, severity: "success" | "error") => void;
}
const ChannelList = ({ displayAlert }: ChannelListProps) => {
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [data, setData] = useState<Partial<ChannelRecord>[] | null>(null);
  const [filter, setFilter] = useState<string>('');

  const queryData = async () => {
    const { data, error } = await supabase_products.from('channel')
    .select('id, name, assembly_sku_code, length_m, width_internal_mm, height_internal_mm, width_external_mm, height_external_mm')
    .order('name', { ascending: true });
    if(error) console.error(error);
    setData(data ?? []);
  };

  useEffect(() => {
    queryData();
  }, []);

  const filteredData = data?.filter(d => d.name?.toLowerCase()?.includes(filter.toLowerCase()));

  return (
    <>
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Box>{/* Filters */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}>
          <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField 
            id="filter-input" 
            label="Search Name"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Box>
      <Box>{/* Add Product */}
        <Button 
          startIcon={<AddOutlinedIcon />} 
          color='success' 
          variant='contained' 
          onClick={() => setAddProduct(true)}
        >
          Add Product
        </Button>
      </Box>
    </Box>
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <HeadCell>Name</HeadCell>
            <HeadCell>Assembly SKU Code</HeadCell>
            <HeadCell>Length</HeadCell>
            <HeadCell>Width Int.</HeadCell>
            <HeadCell>Height Int.</HeadCell>
            <HeadCell>Width Ext.</HeadCell>
            <HeadCell>Height Ext.</HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredData?.map((d, i) => (
              <StyledTableRowHover key={i}>
                <ClickableTextCell clickTo={`channel/${d.id}`}>{d.name}</ClickableTextCell>
                <TextCell>{d.assembly_sku_code}</TextCell>
                <TextCell>{d.length_m} M</TextCell>
                <TextCell>{d.width_internal_mm} mm</TextCell>
                <TextCell>{d.height_internal_mm} mm</TextCell>
                <TextCell>{d.width_external_mm} mm</TextCell>
                <TextCell>{d.height_external_mm} mm</TextCell>
              </StyledTableRowHover>
            ))
          }
        {
          !data &&
          <StyledTableRowHover>
            <TableCell sx={{ border: 'none' }}>
              <CircularProgress />
            </TableCell>
          </StyledTableRowHover>
        }
        </TableBody>
      </Table>
    </TableContainer>
    {
      addProduct &&
      <AddProduct 
        productType='Channel' 
        onClose={() => setAddProduct(false)}
        displayAlert={displayAlert}
      />
    }
    </>
  );
};

export default ChannelList;