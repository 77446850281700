import { LedTapeOptionType as LedTapeOptionType, LedTapeOptionValuePair, LedTapeWithOptionsAndSpecs, SlugValuePair, extractVariableNames } from "../../../database/Products";
import { EzBom, EzLedTape, EzChannelAssembly, EzBomComplete } from "./types";

export const getChannelCode = (ezArray: string[]) => ezArray[0];
export const getLocationCode = (ezArray: string[]) => ezArray[1];
export const getTapeCode = (ezArray: string[]) => ezArray[2];
export const getCctCode = (ezArray: string[]) => ezArray[3];
export const getLumensCode = (ezArray: string[]) => ezArray[4];
export const getLensCode = (ezArray: string[]) => ezArray[5];
export const getMountingCode = (ezArray: string[]) => ezArray[6];
export const getFinishCode = (ezArray: string[]) => ezArray[7];

export const setLocationCode = (ezArray: string[], newValue: string) => {
  ezArray[1] = newValue;
  return ezArray;
};
export const setTapeCode = (ezArray: string[], newValue: string) => {
  ezArray[2] = newValue;
  return ezArray;
};
export const setCctCode = (ezArray: string[], newValue: string) => {
  ezArray[3] = newValue;
  return ezArray;
};
export const setLumensCode = (ezArray: string[], newValue: string) => {
  ezArray[4] = newValue;
  return ezArray;
};
export const setLensCode = (ezArray: string[], newValue: string) => {
  ezArray[5] = newValue;
  return ezArray;
};
export const setMountingCode = (ezArray: string[], newValue: string) => {
  ezArray[6] = newValue;
  return ezArray;
};
export const setFinishCode = (ezArray: string[], newValue: string) => {
  ezArray[7] = newValue;
  return ezArray;
};

export const decodeEasySpec = (ezCode: string): EzBom => {  
  ezCode = ezCode.toUpperCase();
  if(ezCode.startsWith("EZ")) ezCode = ezCode.slice(2);
  if(ezCode.startsWith("-")) ezCode = ezCode.slice(1);
  const ezArr = ezCode.split("-");
  const channelCode = getChannelCode(ezArr);
  const locationCode = getLocationCode(ezArr);
  const tapeCode = getTapeCode(ezArr);
  const cctCode = getCctCode(ezArr);
  const lumensCode = getLumensCode(ezArr);
  const lensCode = getLensCode(ezArr);
  const mountingCode = getMountingCode(ezArr);
  const finishCode = getFinishCode(ezArr);
  return {
    channel: channelCode ? { code: channelCode, name: '' } : null,
    location: locationCode ? { code: locationCode, name: '' } : null,
    tape: tapeCode ? { code: tapeCode, name: '' } : null,
    cct: cctCode ? { code: cctCode, name: '' } : null,
    lumens: lumensCode ? { code: lumensCode, name: '' } : null,
    lens: lensCode ? { code: lensCode, name: '' } : null,
    mounting: mountingCode ? { code: mountingCode, name: '' } : null,
    finish: finishCode ? { code: finishCode, name: '' } : null
  };
}

export const findMatchingEzChannelAssembly = (ezBom: EzBomComplete, ezChannelAssemblyData: EzChannelAssembly[]) => {
  if(!Object.values(ezBom).every(i => i !== null)) return null;
  const channelCode = ezBom.channel.code;
  const lensCode = ezBom.lens.code;
  const mountingCode = ezBom.mounting.code;
  const finishCode = ezBom.finish.code;
  const filteredChannel = ezChannelAssemblyData.filter(cha => {
    return cha.product.channel.assembly_sku_code === channelCode;
  });  
  const filteredLens = filteredChannel.filter(cha => {
    return checkForMatchingAttributeValuesOfEzChannelAssembly(cha, cha.lens_formula, lensCode);
  });  
  const filteredMounting = filteredLens.filter(cha => {
    return checkForMatchingAttributeValuesOfEzChannelAssembly(cha, cha.mounting_formula, mountingCode);
  });
  const filteredFinish = filteredMounting.filter(cha => {
    return checkForMatchingAttributeValuesOfEzChannelAssembly(cha, cha.finish_formula, finishCode);
  });
  const filteredData = (filteredFinish.length > 0) ? filteredFinish[0] : null;
  return filteredData;
}

export const findMatchingEzLedTape = (ezBom: EzBomComplete, ezLedTapeData: EzLedTape[]) => {
  if(!Object.values(ezBom).every(i => i !== null)) return null;
  const seriesCode = ezBom.tape.code;
  const locationCode = ezBom.location.code;
  const cctCode = ezBom.cct.code;
  const lumensCode = ezBom.lumens.code;
  const filteredSeries = ezLedTapeData.filter(ltd => {
    return checkForMatchingOptionValues(ltd.product, ltd.series_formula, seriesCode);
  });  
  const filteredLocation = filteredSeries.filter(ltd => {
    return checkForMatchingOptionValues(ltd.product, ltd.location_formula, locationCode);
  });
  const filteredCct = filteredLocation.filter(ltd => {
    return checkForMatchingOptionValues(ltd.product, ltd.cct_formula, cctCode);
  });
  const filteredLumens = filteredCct.filter(ltd => {
    return checkForMatchingOptionValues(ltd.product, ltd.lumen_formula, lumensCode);
  });
  const filteredData = (filteredLumens.length > 0) ? filteredLumens[0] : null;
  return filteredData;
}

export function checkForMatchingOptionValues(
  ledTape: LedTapeWithOptionsAndSpecs,
  formula: string,
  codeToMatch: string
): boolean {
  const formulaVars: string[] = extractVariableNames(formula);
  const formulaVar = formulaVars.length > 0 ? formulaVars[0] : '';
  const filteredOptions = ledTape.options.filter(option => option.type === formulaVar);
  if(filteredOptions.length === 0) return formula === codeToMatch;
  return filteredOptions.some(option => formula.replace(`{${formulaVar}}`, option.easy_spec_code) === codeToMatch);
}

export function checkForMatchingAttributeValuesOfEzChannelAssembly(
  ezChannelAssembly: EzChannelAssembly,
  ezFormula: string,
  codeToMatch: string
): boolean {
  const formulaVars: string[] = extractVariableNames(ezFormula);
  const formulaVar = formulaVars.length > 0 ? formulaVars[0] : '';
  if (formulaVar == '') return ezFormula === codeToMatch;
  switch(formulaVar){
    case 'lens':
      return ezChannelAssembly.product.options.lenses.some(lens => ezFormula.replace(`{lens}`, lens.easy_spec_code) === codeToMatch);
    case 'mounting':
      return ezChannelAssembly.product.options.mountings.some(mounting => ezFormula.replace(`{mounting}`, mounting.easy_spec_code) === codeToMatch);
    case 'finish':
      return ezChannelAssembly.product.options.finishes.some(finish => ezFormula.replace(`{finish}`, finish.easy_spec_code) === codeToMatch);
    default:
      return false;
  }
}

export function generateSlugValuePairsForEzLedTape( ezLedTape: EzLedTape, ezBom: EzBomComplete ): LedTapeOptionValuePair[] {
  const seriesSlugValuePairs: SlugValuePair[] = extractSlugValuePairs(ezLedTape.series_formula, ezBom.tape.code);
  const locationSlugValuePairs: SlugValuePair[] = extractSlugValuePairs(ezLedTape.location_formula, ezBom.location.code);
  const cctSlugValuePairs: SlugValuePair[] = extractSlugValuePairs(ezLedTape.cct_formula, ezBom.cct.code);
  const lumensSlugValuePairs: SlugValuePair[]  = extractSlugValuePairs(ezLedTape.lumen_formula, ezBom.lumens.code);
  const allSlugValuePairs: SlugValuePair[] = [
    ...seriesSlugValuePairs, 
    ...locationSlugValuePairs,
    ...cctSlugValuePairs,
    ...lumensSlugValuePairs
  ];
  const ledTapeOptionValuePair: (LedTapeOptionValuePair | undefined)[] = allSlugValuePairs.map(svp => {
    if(isLedTapeOptionSlug(svp.slug)) return svp as LedTapeOptionValuePair;
  });
  return ledTapeOptionValuePair.filter((ltovp): ltovp is LedTapeOptionValuePair => ltovp !== undefined);
}

function isLedTapeOptionSlug (value: string): value is LedTapeOptionType {
  const validOptions: LedTapeOptionType[] = ['Color', 'Kelvin Temp', 'Lumens', 'SDCM'];
  return validOptions.includes(value as LedTapeOptionType);
}

export function generateSlugValuePairsForEzChannelAssembly( ezChannelAssembly: EzChannelAssembly, ezBom: EzBom ): SlugValuePair[] {
  const lensSlugValuePairs: SlugValuePair[] = extractSlugValuePairs(ezChannelAssembly.lens_formula, ezBom.lens?.code ?? '');
  const mountingSlugValuePairs: SlugValuePair[] = extractSlugValuePairs(ezChannelAssembly.mounting_formula, ezBom.mounting?.code ?? '');
  const finishSlugValuePairs: SlugValuePair[]  = extractSlugValuePairs(ezChannelAssembly.finish_formula, ezBom.finish?.code ?? '');
  const allLedTapeSlugValuePairs: SlugValuePair[] = [
    ...lensSlugValuePairs, 
    ...mountingSlugValuePairs,
    ...finishSlugValuePairs
  ];
  return allLedTapeSlugValuePairs;
}

export function getNonNullEzBom (ezBom: EzBom): EzBomComplete {
  return {
    channel: { name: ezBom.channel?.name ?? '', code: ezBom.channel?.code ?? '' },
    location: { name: ezBom.location?.name ?? '', code: ezBom.location?.code ?? '' },
    tape: { name: ezBom.tape?.name ?? '', code: ezBom.tape?.code ?? '' },
    cct: { name: ezBom.cct?.name ?? '', code: ezBom.cct?.code ?? '' },
    lumens: { name: ezBom.lumens?.name ?? '', code: ezBom.lumens?.code ?? '' },
    lens: { name: ezBom.lens?.name ?? '', code: ezBom.lens?.code ?? '' },
    mounting: { name: ezBom.mounting?.name ?? '', code: ezBom.mounting?.code ?? '' },
    finish: { name: ezBom.finish?.name ?? '', code: ezBom.finish?.code ?? '' },
  }
}

/* EXAMPLE:
formula -> "{year}-{month}-{day}"
targetCode -> "2024-05-22"

The following function will return:
[
  { slug: "year", value: "2024" },
  { slug: "month", value: "05" },
  { slug: "day", value: "22" }
]
*/
export const extractSlugValuePairs = (formula: string, targetCode: string): SlugValuePair[] => {
  const isOnlyVariable = formula === `{${formula.match(/\{(.+?)\}/)?.[1]}}`;

  // Replace spaces in variable names with underscores for regex pattern
  const regexPattern = formula.replace(/(\{[^}]+\})/g, (match) => {
    const varName = match.slice(1, -1).replace(/\s+/g, '_'); // Replace spaces with underscores
    return isOnlyVariable ? `(?<${varName}>.+)` : `(?<${varName}>\\S+)`;
  });

  const regex = new RegExp(`^${regexPattern}$`);
  const match = targetCode.match(regex);

  // Revert underscore to spaces in the result
  return match && match.groups
    ? Object.entries(match.groups).map(([key, value]) => ({
        slug: key.replace(/_/g, ' '), // Revert underscores to spaces
        value
      }))
    : [];
}