import React from "react";
import { Chip, TableCell, Typography } from "@mui/material";

interface LookupDataItem {
  id: string | number;
  name: string;
}

interface ColumnOptions {
  showIfTrue?: boolean;
  asCount?: boolean;
  valueIfTrue?: string;
  valueIfFalse?: string;
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  colorIfTrue?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  colorIfFalse?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  displayAsChip?: boolean;
}

interface Column {
  field: string;
  lookupData?: LookupDataItem[];
  options?: ColumnOptions;
}

interface Record {
  [key: string]: any; // Assuming `record` can have any shape, but key values are accessed via strings.
}

interface RecordCellProps {
  record: Record;
  column: Column;
}

const RecordCell = ({ record, column }: RecordCellProps) => {
  const { field, lookupData, options } = column;

  const recordValue = record[field];

  let displayValue = recordValue?.toString();
  if (options?.showIfTrue && !recordValue) displayValue = "";

  if (lookupData?.length && lookupData?.length > 0) {
    displayValue = lookupData.find((data) => data.id === recordValue)?.name;
  }

  if (options?.asCount) displayValue = recordValue?.length;

  if (options?.valueIfTrue && recordValue) displayValue = options.valueIfTrue;
  if (options?.valueIfFalse && !recordValue)
    displayValue = options.valueIfFalse;

  let color= options?.color ? options.color : "info";
  if (options?.colorIfTrue && recordValue) color = options.colorIfTrue;
  if (options?.colorIfFalse && !recordValue) color = options.colorIfFalse;

  displayValue =
    displayValue === null || displayValue === undefined ? "" : displayValue;

  function getCell() {
    if (options?.showIfTrue && !recordValue) return <></>;
    if (options?.displayAsChip) {
      return color ? (
        <Chip label={displayValue} color={color} size="small" />
      ) : (
        <Chip label={displayValue} size="small" />
      );
    }
    return <Typography>{displayValue}</Typography>;
  }

  return (
    <TableCell sx={{ minWidth: "75px", maxWidth: "300px" }}>
      {getCell()}
    </TableCell>
  );
};

export default RecordCell;
