import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const QbLocalLinkInstructions = () => {
  const colors = useTheme().colors;

  return (
    <Box maxWidth="800px" margin="20px">
      <Typography>
        This tool utilizes "<strong>QBLocalLink</strong>", an application
        created by Kelvix. Please ensure this application is running as a
        Windows Service on your machine, in parallel with an active Quickbooks
        client.
      </Typography>
      <Box margin="10px">
        <Typography fontWeight="bold">1. Download QBLocalLink</Typography>
        <Typography>
          The latest version of the installer can be downloaded here:{" "}
          <a
            href="https://storage.googleapis.com/qblocallink/QBLocalLink_Setup.exe"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: colors.greenAccent[500],
              fontWeight: "bold",
            }}
          >
            QBLocalLink_Setup.exe
          </a>
        </Typography>
      </Box>
      <hr />
      <Box margin="10px">
        <Typography>
          <strong>2. Install QBLocalLink</strong> (Admin permissions required!)
        </Typography>
        <Typography>
          Run the installer, "QBLocalLink_Setup.exe". This program is not
          "signed" and therefore Windows will warn you about it being an
          untrustworthy application. Please click the "
          <strong>More Info</strong>" link, and then you should see the "
          <strong>Run Anyway</strong>" option (as imaged below).
        </Typography>
        <Typography>
          It's best to install in the default directory to ensure there are no
          issues when automatically launching the service.
        </Typography>
        <img
          src="../assets/img/QbLocalLink_RunAnyway.png"
          alt="Click 'More Info', then 'Run Anyway'."
          width="700px"
          style={{ margin: "15px" }}
        />
      </Box>
      <hr />
      <Box margin="10px">
        <Typography fontWeight="bold">
          3. Ensure QBLocalLink is running
        </Typography>
        <Typography>
          <u>This should happen automatically after installing!</u> However, if
          you are ever unsure, you can check your Window Services via the Task
          Manager. Look for "<strong>QBLocalLink</strong>", as well as "
          <strong>QBLocalLink.Updater</strong>". Both need to be in a "Running"
          state, as imaged below.
        </Typography>
        <img
          src="../assets/img/QbLocalLink_Services.png"
          alt="Ensure both services have a 'Running' state."
          width="500px"
          style={{ margin: "15px" }}
        />
      </Box>
    </Box>
  );
};

export default QbLocalLinkInstructions;
