import React from "react";
import { TextField, useTheme } from "@mui/material";

type TextInputProps = {
  label: string;
  value: string;
  updateValue: (v: string) => void;
  disabled: boolean;
  onEnterPress?: () => void;
}

const TextInput = ({ label, value, updateValue, disabled, onEnterPress }: TextInputProps) => {
  const colors = useTheme().colors;
  const v = value ? value : "";

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && onEnterPress) {
      onEnterPress(); // Perform the action when Enter is pressed
    }
  }

  return (
    <TextField
      label={label}
      value={v}
      onChange={(e) => {
        updateValue(e.target.value);
      }}
      onKeyDown={handleOnKeyDown}
      disabled={disabled}
      sx={{
        "& label.Mui-focused": {
          color: colors.blueAccent[500],
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: colors.blueAccent[500],
          },
        },
      }}
    />
  );
};

export default TextInput;
