import { supabase } from "../../database/supabaseClient";
import { LeadTimeRecord } from "../../database/PublicTypes";
import { LeadTime } from "./types";

export const getLeadTimes = async (): Promise<LeadTime[]> => {
  const { data, error } = await supabase.from("lead_times")
    .select("*, profile:profiles (full_name)")
    .order("display_order", { ascending: true });
  if(error) console.error(error);
  if(data){
    const leadTimes: LeadTime[] = data.map((d) => ({
      ...d,
      profile: d.profile as { full_name: string | null },
      new: null
    }));
    return leadTimes;
  }else{
    return [];
  }
}

export const insertRecord = async (leadTime: LeadTime): Promise<number> => {
  const { id:_, created_at: __, ...record } = convertToRecord(leadTime);
  const { status, error } = await supabase.from("lead_times").insert(record);
  if (error) console.error(error);
  return status;
};

export const updateRecord = async (leadTime: LeadTime): Promise<number> => {
  const record = convertToRecord(leadTime);
  const { status, error } = await supabase.from("lead_times").update(record).match({ id: record.id });
  if (error) console.error(error);
  return status;
};

export const deleteRecord = async (leadTime: LeadTime): Promise<number> => {
  const record = convertToRecord(leadTime);
  const { status, error } = await supabase.from("lead_times").delete().match({ id: record.id });
  if (error) console.error(error);
  return status;
};

const convertToRecord = (leadTime: LeadTime): LeadTimeRecord => {
  const { new: _, profile: __, ...record } = leadTime;
  return record;
}