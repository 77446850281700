import React from "react";
import { useTheme } from "@mui/material";

type KelvixLogoProps = {
  color?: string;
}

const KelvixLogo = ({ color }: KelvixLogoProps) => {
  const colors = useTheme().colors;

  const logoColor = color ? color : colors.svgLogo["solid"];

  return (
    <svg
      width="125"
      height="81.7"
      viewBox="0 0 76 49"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={logoColor}>
        <path d="M56.3 17.9a19.7 19.7 0 0 0-37.3.3.7.7 0 0 0 1.4.4v-.2a18.2 18.2 0 0 1 34.5 0v.2a.7.7 0 0 0 1.4-.7ZM55.6 29.9c-.3 0-.5 0-.6.3l-.1.2a18.2 18.2 0 0 1-34.4 0l-.1-.2a.7.7 0 0 0-1.3.7 19.7 19.7 0 0 0 37.2-.3c0-.4-.3-.7-.7-.7Z"></path>
        <path d="M61 18A24.3 24.3 0 0 0 37.7.1a24.3 24.3 0 0 0-23.4 18 .7.7 0 0 0 1.3.4v-.2a22.9 22.9 0 0 1 22-16.8c10.3 0 19.3 7 22 16.8l.2.3a.7.7 0 0 0 1.2-.8ZM60.3 29.9c-.2 0-.4 0-.5.3l-.1.2a22.9 22.9 0 0 1-22 16.8c-10.3 0-19.3-6.9-22-16.8l-.2-.2a.7.7 0 0 0-1.2.7 24.3 24.3 0 0 0 23.4 17.8 24.3 24.3 0 0 0 23.4-18c0-.5-.4-.8-.8-.8ZM10.3 26.5l-.5-.5h-.1a36.2 36.2 0 0 0-2.3-1.6 36.8 36.8 0 0 0 3-2.2c.1-.2.2-.4 0-.6l-.2-.3a.4.4 0 0 0-.6-.1l-.6.5c-1.3 1-2.4 2-3.9 2H1.6v-2c0-.3-.1-.5-.4-.5H1c-.2 0-.4.2-.4.4V27c0 .3.2.5.4.5h.3c.3 0 .4-.2.4-.5v-1.9l.2-.1H5c1.5 0 2.6 1 3.9 2a53.9 53.9 0 0 0 .9.6l.3-.1.2-.4c.2-.2.1-.4 0-.6ZM24.4 26l-.1-.2a.6.6 0 0 0-.5-.1c-3.1.9-7.4.2-8.6 0l-.1-.2V25H22c.3 0 .5-.2.5-.5v-.3c0-.2-.2-.4-.4-.4h-6.8l-.1-.1V23h.2l.3-.1h.2l.6-.2a22.3 22.3 0 0 1 3.2-.2 16.4 16.4 0 0 1 3.8.4 81.9 81.9 0 0 1 .8 0c.2 0 .2-.1.2-.2l.1-.3c0-.2 0-.4-.3-.5-1.3-.3-5.6-1.3-10 0l-.3.6v3.6c0 .4.3.5.5.6h.2c.8.2 3 .5 5.3.5 1.6 0 3-.1 4.3-.5.1 0 .2 0 .3-.2V26ZM72.7 26.8l-.2-.3-2.4-1.5-1.1-.5-.6-.1a9.3 9.3 0 0 0 1.7-.7l2.4-1.5a.4.4 0 0 0 .1-.5l-.2-.4a.4.4 0 0 0-.5-.1l-2.4 1.5a5 5 0 0 1-4.8 0l-2.4-1.5H62l-.2.1-.3.4a.4.4 0 0 0 .2.5l2.4 1.5 1.2.5.4.2-.5.1-1.1.5-2.4 1.5c-.2.2-.3.4-.2.6l.3.3.3.2 2.6-1.6c.6-.4 1.5-.6 2.4-.6.9 0 1.7.2 2.4.6l2.4 1.5h.5l.2-.4v-.3ZM51.7 21.4h-.3l-.3.1-3.6 4.9H47.2l-3.6-4.9-.2-.2-.4.1-.2.2-.2.3.1.3 3.3 4.4.3.4s.4.5 1 .5c.7 0 1.1-.4 1.1-.4l.4-.5 3.3-4.4a.4.4 0 0 0-.1-.6l-.3-.2ZM39.8 25.8v-.2H39.1a21 21 0 0 1-8.2 0h-.4l-.1-.2v-3.5c0-.2-.2-.4-.4-.4h-.4c-.2 0-.4.2-.4.4v4.2c0 .1 0 .4.4.5H30c.1 0 2.5.6 5.4.6a29.2 29.2 0 0 0 4.5-.7v-.7ZM57 21.4c0-.2-.3-.4-.6-.4a.6.6 0 0 0-.6.7 18 18 0 0 1 0 5.4.6.6 0 0 0 1.3.2v-.1a19.5 19.5 0 0 0 0-5.7Z"></path>
        <path
          d="M75.9 22c0 .6-.4 1-1 1a1 1 0 0 1-1-1c0-.5.5-.9 1-.9.6 0 1 .5 1 1Zm-1.7 0c0 .5.3.8.7.8.5 0 .7-.3.7-.7 0-.4-.3-.8-.7-.8-.4 0-.7.4-.7.8Zm.3.5v-1h.5c.2 0 .3.2.3.4l-.1.2.1.2v.2h-.2v-.2l-.2-.1h-.1v.3h-.3Zm.3-.5h.1l.2-.1-.2-.1h-.1v.2Z"
          fill="#231F20"
        ></path>
      </g>
    </svg>
  );
};

export default KelvixLogo;
