import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { getEasySpecLedTape, updateLedTapeRecord } from "../../data";
import { AlertUi } from "../../types";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ImageConfig from "../../components/ImageConfig";
import AttributeConfig from "./AttributeConfig";
import { EasySpecLedTapeRecord, getLedTapeProduct } from "../../../../../database/Products";
import { LedTapeProductWithEasySpec } from "../../../../../database/EasySpec";
import EnableConfig from "../../components/EnableConfig";
import SpecSheetConfig from "../../components/SpecSheetConfig";

const LedTapeConfig = () => {

  const { ezLedTapeId: ezLedTapeIdParam } = useParams<{ ezLedTapeId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [ledTape, setLedTape] = useState<LedTapeProductWithEasySpec | null>(null);
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const navigate = useNavigate();

  const ezLedTapeId = isNaN(Number(ezLedTapeIdParam)) ? 0 : Number(ezLedTapeIdParam);  

  const queryTapeData = useCallback(async () => {    
    if(ezLedTapeId === 0) return;
    setLoading(true);
    const easySpecRecord = await getEasySpecLedTape(ezLedTapeId);
    if(!easySpecRecord?.led_tape_id){
      setLoading(false);
      setLedTape(null);
      return;
    }
    getLedTapeProduct(easySpecRecord.led_tape_id).then(async (ledTapeProduct) => {
      if(!ledTapeProduct){
        setLoading(false);
        setLedTape(null);
        return;
      }
      const ledTapeWithEzSpec: LedTapeProductWithEasySpec = ledTapeProduct;
      
      if(easySpecRecord) ledTapeWithEzSpec.easySpec = easySpecRecord;
      setLoading(false);
      setLedTape(ledTapeProduct);
    });
  }, []);

  useEffect(() => {
    queryTapeData();
  }, []);

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplyAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  const handleUpdateProductImageDetails = async (fileName?: string, altText?: string) => {
    if (!ledTape) return false;
    const values: Partial<EasySpecLedTapeRecord> = {};
    if(fileName) values.product_image = fileName;
    if(altText) values.product_image_alt_text = altText;
    const successful = await updateLedTapeRecord(ledTape.id, values);
    return successful;
  }

  const easySpec = ledTape?.easySpec;
  const canEnable = (
    easySpec &&
    easySpec.product_image && 
    easySpec.series_formula &&
    easySpec.location_formula && 
    easySpec.lumen_formula
  ) ? true : false;

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap="15px">
        <Button 
          startIcon={<ArrowBackIosNewOutlinedIcon color="inherit" />}
          onClick={() => navigate(`../`)}
        >
          Product List
        </Button>
        {ledTape && <Typography variant="h3">{ledTape.name}</Typography>}
      </Box>
      {
        loading && <CircularProgress />
      }
      <Box pt="20px" display="flex" flexDirection="column" gap={2}>
        {
          !loading && !ledTape && <Typography color="error">Error loading LED Tape product.</Typography>
        }
        {
          !loading && ledTape && 
          <>
            <EnableConfig 
              canEnable={canEnable} 
              ledTape={ledTape} 
              reloadData={queryTapeData} 
              displayAlert={handleDisplyAlert} 
            />
            <ImageConfig
              title="Product Image"
              imageName={ledTape.easySpec?.product_image ?? ''}
              imageAltText={ledTape.easySpec?.product_image_alt_text ?? ''}
              width={245}
              height={150}
              updateImageDetails={handleUpdateProductImageDetails}
              displayAlert={handleDisplyAlert}
            />
            <AttributeConfig 
              ledTape={ledTape}
              reloadTapeData={queryTapeData} 
              displayAlert={handleDisplyAlert} 
            />
            <SpecSheetConfig 
              ezLedTapeId={ezLedTapeId} 
              displayAlert={handleDisplyAlert}
            />
          </>
        }
      </Box>
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LedTapeConfig;