import React, { useCallback, useEffect, useState } from "react";
import { Alert, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { getAllEzChannelAssemblies, getAllEzLedTape, selectAllSpecSheets } from "../../data";
import { AlertUi, SpecSheet } from "../../types";
import { useNavigate } from "react-router-dom";
import SpecSheetList from "../../components/SpecSheetList";

const FullSpecSheetList = () => {
  const colors = useTheme().colors;

  const [specSheets, setSpecSheets] = useState<SpecSheet[]>([]);
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const navigate = useNavigate();

  const queryData = useCallback(async () => {
    Promise.all([
      selectAllSpecSheets(),
      getAllEzLedTape(),
      getAllEzChannelAssemblies()
    ]).then( ([specSheets, ledTape, channelAssemblies]) => {
      ledTape.forEach(lt => {
        channelAssemblies.forEach(cha => {
          const foundSheet = specSheets.find(s => s.ledTape === lt.name && s.channelAssembly === cha.name);
          if(!foundSheet){
            specSheets.push({
              channelAssembly: cha.name,
              ledTape: lt.name,
              ez_channe_assembly_id: cha.id,
              ez_led_tape_id: lt.id,
              spec_sheet: ''
            })
          }
        });
      });
      setSpecSheets(specSheets);
    });
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const onSelectedLedTapeConfig = (ezLedTapeId: number) => {
    navigate(`led-tape/${ezLedTapeId}`);
  }

  const onSelectedChannelConfig = (ezChannelId: number) => {
    navigate(`channel/${ezChannelId}`);
  }

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplyAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  return (
    <Table sx={{ maxWidth: '850px' }} size="small">
      <TableHead>
        <TableRow sx={{ bgcolor: colors.primary[400] }}>
          <TableCell><Typography><b>Led Tape</b></Typography></TableCell>
          <TableCell><Typography><b>Channel Assembly</b></Typography></TableCell>
          <TableCell><Typography><b>Spec Sheet</b></Typography></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <SpecSheetList 
          specSheets={specSheets}
          reloadData={queryData}
          onSelectedTape={onSelectedLedTapeConfig}
          onSelectedChannelAssembly={onSelectedChannelConfig}
          displayAlert={handleDisplyAlert}
        />
      </TableBody>
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Table>
  );
};

export default FullSpecSheetList;