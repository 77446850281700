import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { TextField } from '../../../components/TextField';
import { ChannelAssemblyRecord, ChannelRecord } from '../../../../../../database/Products';
import { supabase_products } from '../../../../../../database/supabaseClient';
import { useNavigate } from 'react-router-dom';

type NewChannelAssemblyProps = {
  onCancel: () => void;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const NewChannelAssembly = ({ onCancel, displayAlert }: NewChannelAssemblyProps) => {
  const colors = useTheme().colors;
  const navigate = useNavigate();

  const [nameInput, setNameInput] = useState<string>('');
  const [selectedChannelName, setSelectedChannelName] = useState<string | null>(null);
  const [channelSearchInput, setChannelSearchInput] = useState<string>('');
  const [channelList, setChannelList] = useState<Pick<ChannelRecord, 'id' | 'name'>[] | null>(null);


  const queryChannel = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('channel').select('id, name');
    if(error) console.error(error.message);
    setChannelList(data ?? []);
  }, []);

  useEffect(() => {
    queryChannel();
  }, []);

  const channelNameList: string[] = channelList?.map((channel) => channel.name) ?? [];

  const canAdd = (
    nameInput !== '' &&
    selectedChannelName !== null &&
    selectedChannelName !== ''
  );

  const handleAdd = async () => {
    if(!canAdd) return;
    const foundChannel = channelList?.find(channel => channel.name === selectedChannelName);
    if(!foundChannel) return;
    const newRecord: Pick<ChannelAssemblyRecord, 'name' | 'channel_id' | 'sku_formula'> = {
      name: nameInput,
      channel_id: foundChannel.id,
      sku_formula: nameInput // Intentionally setting the name as the default SKU Formula
    }
    const { data, error } = await supabase_products
      .from('channel_assemblies').insert({...newRecord}).select('id').single();
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    if(!data) return;
    navigate(`channel-assembly/${data.id}`);
  }

  return (
    <>
    <DialogTitle id="import-dialog-title">
      Add a new Channel Assembly
    </DialogTitle>
    <DialogContent>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' flexDirection='row' gap={2}>
          <TextField 
            label='Assembly Name'
            value={nameInput} 
            onChange={(e) => setNameInput(e.target.value)}
          />
          <Autocomplete
            disablePortal
            id="channels"
            options={channelNameList}
            renderInput={(params) => (
              <TextField
                label="Channel"
                {...params}
              />
            )}
            value={selectedChannelName}
            onChange={(event, newValue) => setSelectedChannelName(newValue)}
            inputValue={channelSearchInput}
            onInputChange={(event, newValue) => setChannelSearchInput(newValue)}
            size="small"
            sx={{
              minWidth: '200px',
              "& label.Mui-focused": {
                color: colors.blueAccent[500],
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: colors.blueAccent[500],
                },
              },
            }}
          />
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={handleAdd} variant='contained' color='success' disabled={!canAdd}>
        Add
      </Button>
    </DialogActions>
    </>
  );
};

export default NewChannelAssembly;