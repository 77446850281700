import React from "react";
import { TableCell, TableRow, Typography, styled, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const HeadCell = ({ children }: { children: React.ReactNode; }) => {
  const colors = useTheme().colors;
  return(
    <TableCell scope="row"sx={{ bgcolor: colors.primary[400] }}>
      <Typography><b>{ children }</b></Typography>
    </TableCell>
  );
};

export const TextCell = ({ children }: { children: React.ReactNode; }) => {
  return(
    <TableCell scope="row">
      <Typography>{ children }</Typography>
    </TableCell>
  );
};

export const ClickableTextCell = ({ children, clickTo }: { children: React.ReactNode; clickTo: string }) => {
  const colors = useTheme().colors;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(clickTo);
  };  
  return(
    <TableCell scope="row">
      <Typography
        onClick={handleClick}
        sx={{ '&:hover': { color: colors.blueAccent[500], cursor: 'pointer' } }}
      >
        { children }
      </Typography>
    </TableCell>
  );
};

export const StyledTableRowHover = styled(TableRow)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover }
}));