import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Divider, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ChannelAssemblyRecord } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import BasicDetails from './BasicDetails';
import Options from './Options';
import SkuFormula from './SkuFormula';
import StyledTabPanel from '../../../../../components/StyledTabPanel';
import QbItemsConfig from './QbItemsConfig/QbItemsConfig';
import { AlertUi } from '../../types';

const ChannelAssemblyConfig = () => {
  const navigate = useNavigate();
  const { id: idParam } = useParams<{ id: string }>();

  const [channelAssembly, setChannelAssembly] = useState<ChannelAssemblyRecord | null>(null);
  const [queryError, setQueryError] = useState<string>('');
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});
  const [tabValue, setTabValue] = useState<number>(0);

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('channel_assemblies').select('*').eq('id', idParam).single();
    if(error) console.error(error);
    if(!data){
      setQueryError('Unable to query data.');
      return;
    }
    setChannelAssembly(data);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseAlert = () => {
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplayAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap="15px" mb={2}>
        <Button 
          startIcon={<ArrowBackIosNewOutlinedIcon color="inherit" />}
          onClick={() => navigate(`../?type=channel-assemblies`)}
        >
          Channel Assemblies List
        </Button>
        {channelAssembly && <Typography variant="h3">{channelAssembly.name}</Typography>}
      </Box>
      { 
        !channelAssembly && queryError === '' &&
        <CircularProgress />
      }
      {
        queryError !== '' &&
        <Typography color='red'>{queryError}</Typography>
      }
      {
        channelAssembly &&
        <>
        <Tabs
          value={tabValue}
          onChange={changeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Details & Options" value={0} />
          <Tab label="QB Items" value={1} />
        </Tabs>
        <Divider />
        <StyledTabPanel value={tabValue} index={0}>
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='column' gap={2} maxWidth={'800px'}>
              <BasicDetails 
                record={channelAssembly} 
                reloadRecord={queryData}
                displayAlert={handleDisplayAlert} 
              />
              <SkuFormula 
                record={channelAssembly}
                reloadRecord={queryData}
                displayAlert={handleDisplayAlert}
              />
            </Box>
            <Box display='flex' flexDirection='column' gap={2} maxWidth={'800px'}>
              <Options record={channelAssembly} displayAlert={handleDisplayAlert} />
            </Box>
          </Box>
        </StyledTabPanel>
        <StyledTabPanel value={tabValue} index={1}>
          <QbItemsConfig 
            channelAssemblyId={channelAssembly.id}
            displayAlert={handleDisplayAlert}
          />
        </StyledTabPanel>
        </>
      }
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChannelAssemblyConfig;