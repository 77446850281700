import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QBLLState, QBLLStatus } from "../../qbll/types";

const initialState: QBLLState = {
  querying: false,
  query: "",
  status: {
    connection: "",
    version: "",
    appName: "",
    qbFileName: "",
    errorMsg: "",
  }
};

type SetQueryingPayload = {
  query: string;
  querying: boolean;
};

export const qbllSlice = createSlice({
  name: "qbll",
  initialState,
  reducers: {
    setQuerying: (state, action: PayloadAction<SetQueryingPayload>) => {
      state.querying = action.payload.querying;
      state.query = action.payload.query;
    },
    setStatus: (state, action: PayloadAction<QBLLStatus>) => {
      state.status = action.payload;
    }
  },
});

export const { setQuerying, setStatus } = qbllSlice.actions;
export default qbllSlice.reducer;