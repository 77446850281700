import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

type HeaderProps = {
  title: string;
  subtitle: string;
  align?: "left" | "right";
}

const Header = ({title, subtitle, align = "left"}: HeaderProps) => {
  const theme = useTheme();
  const colors = theme.colors;

  return (
    <Box mb="15px" textAlign={align}>
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
