import React from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { updateChannelAssemblyEnabled, updateLedTapeEnabled } from "../data";
import { ChannelAssemblyProductWithEasySpec, LedTapeProductWithEasySpec } from "../../../../database/EasySpec";

type EnableConfigProps = {
  canEnable: boolean;
  ledTape?: LedTapeProductWithEasySpec;
  channelAssembly?: ChannelAssemblyProductWithEasySpec;
  reloadData: () => Promise<void>;
  displayAlert: (message: string, severity: "error" | "success") => void;
}

const EnableConfig = ({ canEnable, ledTape, channelAssembly, reloadData, displayAlert }: EnableConfigProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!canEnable) return;
    const checked = event.target.checked;
    if(ledTape) updateLedTape(checked);
    else if(channelAssembly) updateChannelAssembly(checked);
  };

  const updateLedTape = async (enabled: boolean) => {
    if(!ledTape) return;
    const successful = await updateLedTapeEnabled(ledTape.id, enabled);
    if(successful)
      reloadData().then(() => {
        displayAlert('Successfully enabled!', 'success');
    });
    else
      displayAlert('Error enabling.', 'error');
  }

  const updateChannelAssembly = async (enabled: boolean) => {
    if(!channelAssembly) return;
    const successful = await updateChannelAssemblyEnabled(channelAssembly.id, enabled);
    if(successful)
      reloadData().then(() => {
        displayAlert('Successfully enabled!', 'success');
    });
    else
      displayAlert('Error enabling.', 'error');
  }

  let checked = canEnable;
  if(canEnable && ledTape && !channelAssembly){
    checked = ledTape.easySpec?.enabled ?? false;
  }else if(canEnable && !ledTape && channelAssembly){
    checked = channelAssembly.easySpec?.enabled ?? false;
  }

  return (
    <FormGroup>
      <FormControlLabel 
        control={
          <Switch 
            checked={checked} 
            onChange={handleChange}
            disabled={!canEnable}
            color="secondary"
          />
        }
        label="Enabled"
      />
    </FormGroup>
  );
};

export default EnableConfig;