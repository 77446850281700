import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { getEasySpecChannelAssembly, updateChannelAssemblyRecord } from "../../data";
import { AlertUi } from "../../types";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { EasySpecChannelAssemblyRecord, getChannelAssmeblyProduct } from "../../../../../database/Products";
import ImageConfig from "../../components/ImageConfig";
import AttributeConfig from "./AttributeConfig";
import { ChannelAssemblyProductWithEasySpec } from "../../../../../database/EasySpec";
import EnableConfig from "../../components/EnableConfig";
import SpecSheetConfig from "../../components/SpecSheetConfig";

const ChannelConfig = () => {

  const { channelId: ezChannelAssemblyIdParam } = useParams<{ channelId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [channelAssembly, setChannelAssembly] = useState<ChannelAssemblyProductWithEasySpec | null>(null);
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const navigate = useNavigate();

  const ezChannelAssemblyId = isNaN(Number(ezChannelAssemblyIdParam)) ? 0 : Number(ezChannelAssemblyIdParam); 

  const queryChannelData = useCallback(async () => {
    if(ezChannelAssemblyId === 0) return;
    setLoading(true);
    const easySpecRecord = await getEasySpecChannelAssembly(ezChannelAssemblyId);
    if(!easySpecRecord?.channel_assembly_id){
      setLoading(false);
      setChannelAssembly(null);
      return;
    }
    getChannelAssmeblyProduct(easySpecRecord.channel_assembly_id).then(async (channelAssemblyProduct) => {
      if(!channelAssemblyProduct){
        setLoading(false);
        setChannelAssembly(null);
        return;
      }
      const channelWithEzSpec: ChannelAssemblyProductWithEasySpec = channelAssemblyProduct;
      channelWithEzSpec.easySpec = easySpecRecord;      
      setLoading(false);
      setChannelAssembly(channelAssemblyProduct);
    });
  }, []);

  useEffect(() => {
    queryChannelData();
  }, []);

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplyAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  // Higher-order function to create specific handlers
  const createUpdateImageDetailsHandler = (type: 'product' | 'specs' | 'profile') => {
    return async (fileName?: string, altText?: string) => {
      if (!channelAssembly) return false;
      const values: Partial<EasySpecChannelAssemblyRecord> = {};

      switch (type) {
        case 'product':
          if(fileName) values.product_image = fileName;
          if(altText) values.product_image_alt_text = altText;
          break;
        case 'specs':
          if(fileName) values.specs_image = fileName;
          if(altText) values.specs_image_alt_text = altText;
          break;
        case 'profile':
          if(fileName) values.profile_image = fileName;
          if(altText) values.profile_image_alt_text = altText;
          break;
      }

      const successful = await updateChannelAssemblyRecord(channelAssembly.id, values);
      return successful;
    };
  }

  // Create specific handlers using the higher-order function
  const handleUpdateProductImageDetails = createUpdateImageDetailsHandler('product');
  const handleUpdateSpecImageDetails = createUpdateImageDetailsHandler('specs');
  const handleUpdateProfileImageDetails = createUpdateImageDetailsHandler('profile');

  const easySpec = channelAssembly?.easySpec;
  const canEnable = (
    easySpec &&
    easySpec.product_image && 
    easySpec.profile_image &&
    easySpec.specs_image &&
    easySpec.lens_formula &&
    easySpec.mounting_formula && 
    easySpec.finish_formula
  ) ? true : false;

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap="15px">
        <Button 
          startIcon={<ArrowBackIosNewOutlinedIcon color="inherit" />}
          onClick={() => navigate(`../`)}
        >
          Product List
        </Button>
        {channelAssembly && <Typography variant="h3">{channelAssembly.name}</Typography>}
      </Box>
      {
        loading && <CircularProgress />
      }
      <Box pt="20px">
        {
          !loading && !channelAssembly && <Typography color="error">Error loading Channel product.</Typography>
        }
        {
          !loading && channelAssembly && 
            <>
              <EnableConfig 
                canEnable={canEnable} 
                channelAssembly={channelAssembly}
                reloadData={queryChannelData} 
                displayAlert={handleDisplyAlert} 
              />
              <ImageConfig
                title="Product Image"
                imageName={channelAssembly.easySpec?.product_image ?? ''}
                imageAltText={channelAssembly.easySpec?.product_image_alt_text ?? ''}
                width={245}
                height={150}
                updateImageDetails={handleUpdateProductImageDetails}
                displayAlert={handleDisplyAlert}
              />
              <ImageConfig
                title="Specs Image"
                imageName={channelAssembly.easySpec?.specs_image ?? ''}
                imageAltText={channelAssembly.easySpec?.specs_image_alt_text ?? ''}
                width={250}
                height={142}
                updateImageDetails={handleUpdateSpecImageDetails}
                displayAlert={handleDisplyAlert}
              />
              <ImageConfig
                title="Profile Image"
                imageName={channelAssembly.easySpec?.profile_image ?? ''}
                imageAltText={channelAssembly.easySpec?.profile_image_alt_text ?? ''}
                width={71}
                height={35}
                updateImageDetails={handleUpdateProfileImageDetails}
                displayAlert={handleDisplyAlert}
              />
              <AttributeConfig channelAssembly={channelAssembly} reloadTapeData={queryChannelData} displayAlert={handleDisplyAlert} />
              <SpecSheetConfig 
                ezChannelAssemblyId={ezChannelAssemblyId} 
                displayAlert={handleDisplyAlert}
              />
            </>
        }
      </Box>
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChannelConfig;