import React, { useState } from "react";
import { Box, FormControlLabel, Switch, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { default as TextInput } from "./ConfigTextInput";
import { ProjectTypeRecord } from "../../../../database/AppsDescGenToolTypes";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { insertRecord, updateRecord } from "../data";

export type ConfigProjectTypeFormProps = {
  record: ProjectTypeRecord;
  isNew: boolean;
  onCancel: () => void;
  onSave: (record: ProjectTypeRecord, updateDatabase: () => Promise<PostgrestSingleResponse<null> | undefined>) => Promise<void>;
}

const ConfigProjectTypeForm = ({ record: recordData, isNew, onCancel, onSave }: ConfigProjectTypeFormProps) => {
  const cleanedRecordData = { ...recordData };
  const [record, setRecord] = useState(cleanedRecordData);
  const [modified, setModified] = useState(false);

  function updateField(fieldName: keyof(ProjectTypeRecord), fieldValue: string | number | boolean) {
    const newRecord = {...record, [fieldName]: fieldValue};
    setRecord(newRecord);
    setModified(true);
  }

  const handleInsert = async () => {
    return insertRecord(record, "project_types");
  }

  const handleUpdate = async () => {
    return updateRecord(record, "project_types");
  }

  return (
    <Box sx={{ m: "25px 0", width: "50%" }}>
      <Box marginBottom="20px" sx={{ "& > *": { marginRight: "10px" } }}>
        <Button
          variant="outlined"
          color="info"
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          startIcon={<SaveOutlinedIcon />}
          disabled={!modified}
          onClick={() => onSave(record, isNew ? handleInsert : handleUpdate)}
        >
          Save
        </Button>
      </Box>

      {record !== null && (
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Switch
                checked={record.active ? true : false}
                color="secondary"
              />
            }
            label={record.active ? "Enabled" : "Disabled"}
            onChange={(e, checked) => updateField("active", checked)}
            sx={{ marginBottom: "15px" }}
          />
          <TextInput
            label="Name"
            value={record.name}
            fieldName={"name"}
            updateValue={updateField}
          />
          <TextInput
            label="Display Index"
            value={record.display_index}
            fieldName={"display_index"}
            updateValue={updateField}
          />
        </Box>
      )}
    </Box>
  );
};

export default ConfigProjectTypeForm;
