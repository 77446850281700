import { EasySpecChannelAssemblyRecord, EasySpecLedTapeRecord, getAllChannelAssembliesWithOptions, GetAllLedTapesWithOptionsAndSpecs } from "../../../database/Products";
import { supabase_products } from "../../../database/supabaseClient";
import { EzChannelAssembly, EzLedTape } from "./types";

const getAllEzLedTape = async (): Promise<EasySpecLedTapeRecord[]> => {
  const {data, error} = await supabase_products.from('easy_spec_led_tape_configs').select('*').eq('enabled', true);
  if(error) console.error(error.message);
  return data ?? [];
}

export const GetAllEzLedTape = async (): Promise<EzLedTape[]> => {
  const [ezData, prodData] = await Promise.all([
    getAllEzLedTape(),
    GetAllLedTapesWithOptionsAndSpecs()
  ]);
  const ledTape: (EzLedTape | undefined)[] = ezData.map(ezLedTape => {
    const matchedProd = prodData.find(p => p.id === ezLedTape.led_tape_id);
    if(matchedProd) return { ...ezLedTape, product: matchedProd}
    console.error(`Product is null for EZ Spec LED Tape ID: ${ezLedTape.id}`);
    return;
  });
  return ledTape.filter((lt): lt is EzLedTape => lt !== undefined);
}

const getAllEzChannelAssemblies = async (): Promise<EasySpecChannelAssemblyRecord[]> => {
  const {data, error} = await supabase_products.from('easy_spec_channel_assembly_configs').select('*').eq('enabled', true);
  if(error) console.error(error.message);
  return data ?? [];
}

export const GetAllEzChannelAssemblies = async (): Promise<EzChannelAssembly[]> => {
  const [ezChannels, prodData] = await Promise.all([
    getAllEzChannelAssemblies(),
    getAllChannelAssembliesWithOptions()
  ]);
  const channelAssemblies: (EzChannelAssembly | undefined)[] = ezChannels.map(ezChannelAssembly => {
    const matchedProd = prodData.find(p => p.id === ezChannelAssembly.channel_assembly_id);
    if(matchedProd) return { ...ezChannelAssembly, product: { ...matchedProd } };
    console.error(`Product is null for EZ Spec Channel Assembly ID: ${ezChannelAssembly.id}`);
    return;
  });
  return channelAssemblies.filter((ezChA): ezChA is EzChannelAssembly => ezChA !== undefined);
}