import { PermissionRecord, ProfileRecord, UserPermissionRecord } from "../../../database/PublicTypes";
import { supabase } from "../../../database/supabaseClient";

export const getPermissions = async (): Promise<PermissionRecord[]> => {
  let response;
  try {
    response = await supabase.from("permissions").select("*");
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  if(!response?.data) return [];
  return response.data;
}

export const getUserPermissionRecords = async (): Promise<UserPermissionRecord[]> => {
  let response;
  try {
    response = await supabase.from("user_permissions").select("*");
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  if(!response?.data) return [];
  return response.data;
}

export const getProfileRecords = async (): Promise<ProfileRecord[]> => {
  let response;
  try {
    response = await supabase.from("profiles").select("*");
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  if(!response?.data) return [];
  return response.data;
}

export const getUserPermissionsRecords = async (): Promise<UserPermissionRecord[]> => {
  let response;
  try {
    response = await supabase.from("user_permissions").select("*");
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  if(!response?.data) return [];
  return response.data;
}

export type NewUserPermission = {
  user_id: string;
  permission_name: string;
}

export const insertUserPermissionRecord = async (record: NewUserPermission) => {
  let response;
  try {
    response = await supabase.from("user_permissions").insert(record);
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteUserPermissionRecord = async (record: UserPermissionRecord) => {
  let response;
  try {
    response = await supabase.from("user_permissions").delete().match({ id: record.id });
    if (response.error) throw response.error;
  } catch (error) {
    console.error(error);
  }
  return response;
};