import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { default as TabPanel } from "../../../../../components/StyledTabPanel";
import EasySpecProductList from "./EasySpecProductList";
import FullSpecSheetList from "./FullSpecSheetList";

const MainView = () => {

  const [tabValue, setTabVal] = useState<number>(0);

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabVal(newValue);
  };

  return (
    <Box>
      <Box>
        <Tabs
          value={tabValue}
          onChange={changeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Product List" />
          <Tab label="Spec Sheets" />
        </Tabs>
        <Divider />
      </Box>
      <TabPanel value={tabValue} index={0}>
        <EasySpecProductList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FullSpecSheetList />
      </TabPanel>
    </Box>
  );
};

export default MainView;