import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import TextCell from "../../components/TextCell";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { getEasySpecProducts } from "../../data";
import StyledTextField from "../../../../../components/StyledTextField";
import EnabledChip from "../../components/ActiveChip";
import AddProductDialog from "../../components/AddProductDialog";
import { ProductType, Products } from "../../types";
import CircularProgress from '@mui/material/CircularProgress';

const EasySpecProductList = () => {
  const colors = useTheme().colors;

  const [products, setProducts] = useState<Products>({ledTape: [], channel: []});
  const [addingProduct, setAddingProduct] = useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<'all' | 'tape' | 'channel'>('all');
  const [loadingList, setLoadingList] = useState<boolean>(true);
  const navigate = useNavigate();

  const queryData = useCallback(async () => {
    const { led_tape, channel_assemblies } = await getEasySpecProducts();
    const prods: Products = { ledTape: led_tape, channel: channel_assemblies };
    setProducts(prods);
  }, [])

  useEffect(() => {
    queryData().then(() => setLoadingList(false));
  }, []);

  const handleAddingProduct = (adding: boolean) => {
    setAddingProduct(adding);
  }

  const handleAddProduct = (type: ProductType, productId: number) => {
    setAddingProduct(false);
    if(type === "tape")
      onSelectedLedTapeConfig(productId);
    else
      onSelectedChannelConfig(productId);
  }

  const onSelectedLedTapeConfig = (ezLedTapeId: number) => {
    navigate(`led-tape/${ezLedTapeId}`);
    return;
  }

  const onSelectedChannelConfig = (ezChannelId: number) => {
    navigate(`channel/${ezChannelId}`);
    return;
  }

  const filteredLedTape = products.ledTape.filter(p => 
    (typeFilter === 'tape' || typeFilter === 'all') &&
    p.product_data.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  const filteredChannel = products.channel.filter(p => 
    (typeFilter === 'channel' || typeFilter === 'all') &&
    p.product_data.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <>
      <Box display="flex" flexDirection="row" gap={2} alignItems="end" marginBottom={2}>
        <Box>
          <InputLabel id="typeLabel">Type</InputLabel>
          <Select 
            labelId="typeLabel" 
            size="small" 
            value={typeFilter} 
            onChange={(e) => setTypeFilter(e.target.value as ProductType & 'all')}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="tape">Tape</MenuItem>
            <MenuItem value="channel">Channel</MenuItem>
          </Select>
        </Box>
        <StyledTextField
          label="Filter"
          variant="outlined"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table size="small" sx={{ maxWidth: '850px'}}>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TextCell><b>Prouct</b></TextCell>
              <TextCell><b>Enabled on Easy Spec</b></TextCell>
              <TextCell align="right">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddingProduct(true)}
                >
                  Add Product
                </Button>
              </TextCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredLedTape.map(p => (
                <TableRow key={"tape" + p.id}>
                  <TextCell>{p.product_data.name}</TextCell>
                  <TableCell><EnabledChip enabled={p.enabled} /></TableCell>
                  <TextCell align="right" >
                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      startIcon={<SettingsIcon />}
                      onClick={() => onSelectedLedTapeConfig(p.id)}
                    >
                      Edit
                    </Button>
                  </TextCell>
                </TableRow>
              ))
            }
            {
              filteredChannel.map(p => (
                <TableRow key={"channel" + p.id}>
                  <TextCell>{p.product_data.name}</TextCell>
                  <TableCell><EnabledChip enabled={p.enabled} /></TableCell>
                  <TextCell align="right" >
                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      startIcon={<SettingsIcon />}
                      onClick={() => onSelectedChannelConfig(p.id)}
                    >
                      Edit
                    </Button>
                  </TextCell>
                </TableRow>
              ))
            }
          </TableBody> 
        </Table>
      </TableContainer>
      <AddProductDialog
        open={addingProduct}
        onClose={() => handleAddingProduct(false)}
        ledTapeIdExclusions={products.ledTape.map(p => p.led_tape_id)}
        channelIdExclusions={products.channel.map(p => p.channel_assembly_id)}
        onSelectedProduct={handleAddProduct}
      />
      {
        loadingList && <CircularProgress />
      }
    </>
  );
};

export default EasySpecProductList;