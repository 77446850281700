import React, { ReactNode } from "react";
import { TableCell, Typography } from "@mui/material";

type TextCellProps = {
  children: ReactNode;
  align?: "left" | "center" | "right";
}

const TextCell = ({ children, align = "left"}: TextCellProps) => {
  return (
    <TableCell
      align={align}
      sx={{ maxWidth: "250px" }}
    >
      <Typography>{children}</Typography>
    </TableCell>
  );
};

export default TextCell;