import React, { Dispatch, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardContent, Divider, Fade, List, ListItem, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { updateLedTapeAttributeDetails } from "../../data";
import { LedTapeProductWithEasySpec } from "../../../../../database/EasySpec";
import { IsLedTapeOptionType, extractVariableNames } from "../../../../../database/Products";
import AttributesList, { Content } from "../../../../../components/AttributesList";

const formulaValidation = (value: string) => {
  if(value === '') return 'Pleaes provide a formula for each.';
  const match = value.match(/\{([^}]+)\}/);
  if(!match) return; // No inner params detected.
  const param = match[1];
  if(IsLedTapeOptionType(param)) return;
  return `Cannot find option or attribute slug with name: ${param}`;
};

type PAttributeConfigProps = {
  ledTape: LedTapeProductWithEasySpec | null;
  reloadTapeData: () => Promise<void>;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const AttributeConfig = ({ ledTape, reloadTapeData, displayAlert }: PAttributeConfigProps) => {
  const colors = useTheme().colors;

  const [seriesFormulaInput, setSeriesFormulaInput] = useState<string>(ledTape?.easySpec?.series_formula ?? '');
  const [locationFormulaInput, setLocationFormulaInput] = useState<string>(ledTape?.easySpec?.location_formula ?? '');
  const [cctFormulaInput, setCctFormulaInput] = useState<string>(ledTape?.easySpec?.cct_formula ?? '');
  const [lumensFormulaInput, setLumenFormulaInput] = useState<string>(ledTape?.easySpec?.lumen_formula ?? '');
  const [attributeConfigChanged, setAttributeConfigChanged] = useState<boolean>(false);
  const [copiedSlug, setCopiedSlug] = useState<boolean>(false);

  const handleFormulaInputChange = (newValue: string, setter: Dispatch<React.SetStateAction<string>>) => {
    if (/^[a-z0-9{} ]+$/i.test(newValue) || newValue === "") {
      setter(newValue);
      if(!attributeConfigChanged) setAttributeConfigChanged(true);
    }
  };  

  const allFormulasProvided = (
    seriesFormulaInput !== '' &&
    locationFormulaInput !== '' &&
    cctFormulaInput !== '' &&
    lumensFormulaInput !== ''
  );

  const handleSaveClick = async () => {
    if(!attributeConfigChanged || errors.size > 0 || !allFormulasProvided) return;
    if(!ledTape) return;
    const successful = await updateLedTapeAttributeDetails(ledTape.id, {
      series_formula: seriesFormulaInput,
      location_formula: locationFormulaInput,
      cct_formula: cctFormulaInput,
      lumen_formula: lumensFormulaInput
    });
    if(successful)
      reloadTapeData().then(() => {
        displayAlert('Successfully updated the attribute formulas!', 'success');
    });
    else
      displayAlert('Error updating attribute formulas.', 'error');
  }

  const handleOnClick = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopiedSlug(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedSlug(false);
  };

  let hasDuplicateSlugs = false;
  const hasSlugs: Set<string> = new Set();
  const allInputSlugs = [
    ...extractVariableNames(seriesFormulaInput),
    ...extractVariableNames(locationFormulaInput),
    ...extractVariableNames(cctFormulaInput),
    ...extractVariableNames(lumensFormulaInput)
  ];
  allInputSlugs.forEach(slug => {
    if(hasSlugs.has(slug)){
      hasDuplicateSlugs = true;
    }else{
      hasSlugs.add(slug)
    }
  });

  const missingRequiredSlugs: Set<string> = new Set();
  const requiredSlugs: Set<string> = new Set();
  ledTape?.options.forEach(opt => { requiredSlugs.add(opt.type) });
  requiredSlugs.forEach(slug => { if(!hasSlugs.has(slug)) missingRequiredSlugs.add(slug) });

  const errors: Set<string> = new Set();
  if(hasDuplicateSlugs) errors.add('Formulas contain duplicate option slugs.');
  if(missingRequiredSlugs.size > 0) errors.add(`Missing required option slugs: ${[...missingRequiredSlugs].join(', ')}`)
  errors.add(formulaValidation(seriesFormulaInput) ?? '');
  errors.add(formulaValidation(locationFormulaInput) ?? '');
  errors.add(formulaValidation(cctFormulaInput) ?? '');
  errors.add(formulaValidation(lumensFormulaInput) ?? '');
  errors.delete('');
  const formulaErrorsArr = [ ...errors ];

  const options = ledTape?.options ?? [];
  const optionsList: Content = {
    headers: {
      attributeLabel: 'Option',
      valuesLabel: 'Values (Label / Easy Spec Code)',
      align: 'left'
    },
    attributes: [
      {
        name: 'Color',
        label: 'Colors',
        values: 
          options.filter(opt => opt.type === 'Color')?.map(c => ({ 
            name: c.display_name,
            code: c.easy_spec_code
          })) ?? []        
      },
      {
        name: 'SDCM',
        label: 'SDCM',
        values: 
          options.filter(opt => opt.type === 'SDCM')?.map(sdcm => ({ 
            name: sdcm.display_name,
            code: sdcm.easy_spec_code
          })) ?? []        
      },
      {
        name: 'Lumens',
        label: 'Lumens',
        values: 
          options.filter(opt => opt.type === 'Lumens')?.map(lumens => ({ 
            name: lumens.display_name,
            code: lumens.easy_spec_code
          })) ?? []        
      },
      {
        name: 'Kelvin Temp',
        label: 'Kelvin Temps',
        values: 
          options.filter(opt => opt.type === 'Kelvin Temp')?.map(temmp => ({ 
            name: temmp.display_name,
            code: temmp.easy_spec_code
          })) ?? []        
      },
    ]
  }
  optionsList.attributes = optionsList.attributes.filter(attr => attr.values.length > 0);

  const hover = {
    "&:hover": {
      cursor: "pointer",
      color: colors.blueAccent[500],
    }
  };

  const missingEasySpecCodes: { option: string; value: string; }[] = [];
  optionsList.attributes.forEach(attr => {
    attr.values.forEach(value => { if(value.code === '') missingEasySpecCodes.push({ option: attr.name, value: value.name })})
  });

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Attribute Config</Typography>
        <Divider />
        <Box p="10px" maxWidth="950px">
          <Typography>Each LED Tape product needs to have code output for Series, Location (Indoor/Outdoor), and Lumen Output.</Typography>
          <Box my={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                Available Options
              </AccordionSummary>
              <AccordionDetails>
                <AttributesList content={optionsList} />
              </AccordionDetails>
            </Accordion>
            {
              missingEasySpecCodes.length > 0 &&
              <Alert severity="warning" sx={{ marginTop: '15px'}}>
                <Typography>The following Option Values do not have an Easy Spec code.</Typography>
                {
                  missingEasySpecCodes.map((opt, i) => (
                    <Typography key={i}>{opt.option}: {opt.value}</Typography>
                  ))
                }
              </Alert>
            }
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <Box width="100%" padding={1}>
              <Typography>Series</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={seriesFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setSeriesFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>Location (Indoor/Outdoor)</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={locationFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setLocationFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>CCT</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={cctFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setCctFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" padding={1}>
              <Typography>Lumen Output</Typography>
              <Divider />
              <TextField
                label="Code Formula"
                color="info"
                required
                fullWidth
                value={lumensFormulaInput}
                onChange={(e) => handleFormulaInputChange(e.target.value, setLumenFormulaInput)}
                sx={{ marginTop: "10px" }}
              />
            </Box>
            <Box width="100%" sx={{ paddingTop: "30px" }}>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                disabled={!attributeConfigChanged || errors.size > 0  || !allFormulasProvided}
                onClick={handleSaveClick}
                sx={{ marginTop: "10px" }}
              >
                Save
              </Button>
            </Box>
          </Box>
          {
            errors.size > 0 &&
            <List dense={true} >
              {
                formulaErrorsArr.map((err, i) => (
                  <ListItem key={i} sx={{ color: 'red'}}>• {err}</ListItem>
                ))
              }
            </List>
          }
          <Box mt={4}>
            <Typography>LED Tape Option slugs:</Typography>
            <List dense={true}>
              {
                (ledTape?.options.filter(opt => opt.type === 'SDCM').length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleOnClick(`{SDCM}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{SDCM}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{SDCM}`}</ListItem>
              }
              {
                (ledTape?.options.filter(opt => opt.type === 'Kelvin Temp').length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleOnClick(`{Kelvin Temp}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{Kelvin Temp}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{Kelvin Temp}`}</ListItem>
              }
              {
                (ledTape?.options.filter(opt => opt.type === 'Lumens').length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleOnClick(`{Lumens}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{Lumens}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{Lumens}`}</ListItem>
              }
              {
                (ledTape?.options.filter(opt => opt.type === 'Color').length ?? 0) > 0 ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleOnClick(`{Color}`)} sx={hover}><span style={{color: 'red'}}>*</span>{`{Color}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{Color}`}</ListItem>
              }
            </List>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttributeConfig;