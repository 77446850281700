import { ChannelAssemblyRecord, LedTapeRecord, EasySpecChannelAssemblyRecord, EasySpecLedTapeRecord } from "../../../database/Products";
import { EnsureSingle, RemoveNullsAndUndefineds } from "../../../database/Utils";
import { supabase, supabase_products } from "../../../database/supabaseClient";
import { GetUniqueFileName } from "../../../utils/UniqueFileName";
import { ChannelAssembly, InsertResponse, LedTape } from "./types";

export const getEasySpecProducts = async (): Promise<{led_tape: LedTape[], channel_assemblies: ChannelAssembly[]}> => {
  const [ ledTapeConfig, channelConfig ] = await Promise.all([
    supabase_products.from('easy_spec_led_tape_configs').select('*, product:led_tape(*)'),
    supabase_products.from('easy_spec_channel_assembly_configs').select('*, product:channel_assemblies(*)'),
  ]);
  const ezLedTapeData = ledTapeConfig.data ?? [];
  const ezLedTape: (LedTape | undefined)[] = ezLedTapeData.map(d => {
    const tapeRecord = EnsureSingle(d.product);
    if(!tapeRecord) return;
    return { ...d, product_data: tapeRecord };
  });
  const ezChannelAssemblyData = channelConfig.data ?? [];
  const ezChannelAssemblies: (ChannelAssembly | undefined)[] = ezChannelAssemblyData.map(d => {
    const channelAssemblyRecord = EnsureSingle(d.product);
    if(!channelAssemblyRecord) return;
    return {...d, product_data: channelAssemblyRecord}
  });

  return {led_tape: RemoveNullsAndUndefineds(ezLedTape), channel_assemblies: RemoveNullsAndUndefineds(ezChannelAssemblies)}
}

export const getLedProducts = async (): Promise<LedTapeRecord[]> => {
  const {data, error} = await supabase_products
  .from('led_tape').select('*').order("name", { ascending: true });
  if (error) console.error(error);
  return data ?? [];
}

export const getEasySpecLedTape = async (id: number): Promise<EasySpecLedTapeRecord | null> => {
  const { data, error } = await supabase_products.from('easy_spec_led_tape_configs').select('*').eq('id', id).single();
  if(error) console.error(error);
  return data;
}

export const getChannelProducts = async (): Promise<ChannelAssemblyRecord[]> => {
  const {data, error} = await supabase_products
  .from('channel_assemblies').select('*').order("name", { ascending: true });
  if (error) console.error(error);
  return data ?? [];
}

export const getEasySpecChannelAssembly = async (recordId: number): Promise<EasySpecChannelAssemblyRecord | null> => {
  const { data, error } = await supabase_products.from('easy_spec_channel_assembly_configs').select('*').eq('id', recordId).single();
  if(error) console.error(error);
  return data;
}

export const getAllEzChannelAssemblies = async () => {
  const { data, error } = await supabase_products
    .from('easy_spec_channel_assembly_configs')
    .select(`id, enabled, channelAssembly:channel_assemblies( name )`);
  if(error) console.error(error);
  const transformedData = !data ? [] : data.map(d => {
    const channelAssembly = EnsureSingle(d.channelAssembly);
    return { id: d.id, name: channelAssembly?.name ?? '', enabled: d.enabled };
  });
  return transformedData;
}

export const getAllEzLedTape = async () => {
  const { data, error } = await supabase_products
    .from('easy_spec_led_tape_configs')
    .select(`id, enabled, ledTape:led_tape( name )`);
  if(error) console.error(error);
  const transformedData = !data ? [] : data.map(d => {
    const ledTape = EnsureSingle(d.ledTape);
    return { id: d.id, name: ledTape?.name ?? '', enabled: d.enabled };
  });
  return transformedData;
}

export const uploadEasySpecSheet = async (imageFile: File): Promise<string | null> => {
  const fileName = GetUniqueFileName(imageFile);
  const filePath = `${fileName}`; // Saving at the root of the bucket
  const { error } = await supabase.storage
    .from('easy-spec-sheets')
    .upload(filePath, imageFile);

  if (error) console.error(error.message);
  return fileName;
}

export const updateEasySpecSheet = async (ezChannelAssemblyId: number, ezLedTapeId: number, specSheet: string): Promise<boolean> => {
  const { error } = await supabase_products
    .from('easy_spec_sheets')
    .update({ spec_sheet: specSheet })
    .eq('ez_channe_assembly_id', ezChannelAssemblyId)
    .eq('ez_led_tape_id', ezLedTapeId);
  if (error) console.error(error);
  return error === null;
}

export const insertEasySpecSheet = async (ezChannelAssemblyId: number, ezLedTapeId: number, specSheet: string): Promise<boolean> => {
  const { error } = await supabase_products
    .from('easy_spec_sheets')
    .insert({
      ez_channe_assembly_id: ezChannelAssemblyId,
      ez_led_tape_id: ezLedTapeId,
      spec_sheet: specSheet
    });
  if (error) console.error(error);
  return error === null;
}

export const uploadProductImage = async (imageFile: File): Promise<string | null> => {
  const fileName = GetUniqueFileName(imageFile);
  const filePath = `${fileName}`; // Saving at the root of the bucket
  const { error } = await supabase.storage
    .from('easy-spec-images')
    .upload(filePath, imageFile);

  if (error) console.error(error.message);
  return fileName;
}

export const updateChannelAssemblyRecord = async (id: number, values: Partial<ChannelAssemblyRecord>): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_channel_assembly_configs')
  .update(values).eq('channel_assembly_id', id);
  if(response.error) console.error(response.error);
  return response.error === null;
}

export const updateLedTapeRecord = async (id: number, values: Partial<LedTapeRecord>): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_led_tape_configs')
  .update(values).eq('led_tape_id', id);
  if(response.error) console.error(response.error);
  console.log(response)
  return response.error === null;
}

export const updateLedTapeEnabled = async (
  ledTapeId: number, 
  enabled: boolean
): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_led_tape_configs')
  .update({'enabled': enabled}).eq('led_tape_id', ledTapeId);
  return response.error === null;
}

export const updateLedTapeAttributeDetails = async (
  id: number, 
  fields: Pick<EasySpecLedTapeRecord, 'series_formula' | 'location_formula' | 'lumen_formula' | 'cct_formula'>
): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_led_tape_configs')
  .update(fields).eq('led_tape_id', id);
  console.log(response);
  return response.error === null;
}

export const updateChannelAssemblyEnabled = async (
  channelAssemblyId: number, 
  enabled: boolean
): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_channel_assembly_configs')
  .update({'enabled': enabled}).eq('channel_assembly_id', channelAssemblyId);
  return response.error === null;
}

export const updateChannelAssemblyAttributeDetails = async (
  id: number, 
  fields: Pick<EasySpecChannelAssemblyRecord, 'lens_formula' | 'mounting_formula' | 'finish_formula' >
): Promise<boolean> => {
  const response = await supabase_products.from('easy_spec_channel_assembly_configs')
  .update(fields).eq('channel_assembly_id', id);
  return response.error === null;
}

export const insertNewEasySpecTape = async (tapeRecordId: number): Promise<InsertResponse> =>{
  const newRecord = {
    led_tape_id: tapeRecordId,
    enabled: false
  }
  const { data, error } = await supabase_products.from("easy_spec_led_tape_configs").insert(newRecord).select('id');
  if (error) console.error(error);
  return data;
}

export const insertNewEasySpecChannel = async (channelRecordId: number): Promise<InsertResponse> =>{
  const newRecord = {
    channel_assembly_id: channelRecordId,
    enabled: false
  }
  const { data, error } = await supabase_products.from("easy_spec_channel_assembly_configs").insert(newRecord).select('id');
  if (error) console.error(error);
  return data;
}

export const selectSpecSheets = async (ezLedTapeId?: number, ezChannelAssemblyId?: number) => {
  const query = supabase_products
    .from('easy_spec_sheets')
    .select(`
      *, 
      ezLedTape:easy_spec_led_tape_configs (
        ledTape:led_tape ( name )
      ),
      ezChannelAssembly:easy_spec_channel_assembly_configs (
        channelAssembly:channel_assemblies ( name )
      )
      `);
  if(ezLedTapeId) query.eq('ez_led_tape_id', ezLedTapeId);
  if(ezChannelAssemblyId) query.eq('ez_channe_assembly_id', ezChannelAssemblyId);
  const { data, error } = await query;
  if(error) console.error(error);
  const transformedData = !data ? [] : data.map(d => {
    const ledTape = EnsureSingle(EnsureSingle(d.ezLedTape)?.ledTape)?.name;
    const channelAssembly = EnsureSingle(EnsureSingle(d.ezChannelAssembly)?.channelAssembly)?.name;
    return {
      ez_channe_assembly_id: d.ez_channe_assembly_id,
      ez_led_tape_id: d.ez_led_tape_id,
      spec_sheet: d.spec_sheet,
      ledTape: ledTape ?? '',
      channelAssembly: channelAssembly ?? ''
    }
  });
  return transformedData ?? [];
}

export const selectAllSpecSheets = async () => {
  const query = supabase_products
    .from('easy_spec_sheets')
    .select(`
      *, 
      ezLedTape:easy_spec_led_tape_configs (
        ledTape:led_tape ( name )
      ),
      ezChannelAssembly:easy_spec_channel_assembly_configs (
        channelAssembly:channel_assemblies ( name )
      )
      `);
  const { data, error } = await query;
  if(error) console.error(error);
  const transformedData = !data ? [] : data.map(d => {
    const ledTape = EnsureSingle(EnsureSingle(d.ezLedTape)?.ledTape)?.name;
    const channelAssembly = EnsureSingle(EnsureSingle(d.ezChannelAssembly)?.channelAssembly)?.name;
    return {
      ez_channe_assembly_id: d.ez_channe_assembly_id,
      ez_led_tape_id: d.ez_led_tape_id,
      spec_sheet: d.spec_sheet,
      ledTape: ledTape ?? '',
      channelAssembly: channelAssembly ?? ''
    }
  });
  return transformedData ?? [];
}