import React from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

type EditButtonProps = {
  onClickEdit: React.MouseEventHandler<HTMLButtonElement> | undefined,
};

const EditButton = ({ onClickEdit }: EditButtonProps) => {
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={onClickEdit}
      startIcon={<EditIcon />}
    >
      Edit
    </Button>
  );
};

export default EditButton;
