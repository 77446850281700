import { Chip } from "@mui/material";
import React from "react";

type StateChipProps = {
  label: "Normal" | "Longer Than Usual";
  color: "success" | "default" | "primary" | "secondary" | "error" | "info" | "warning";
}

const StateChip = ({ label, color }: StateChipProps) => {
  return (
    <Chip
      label={label}
      color={color}
      variant="outlined"
      size="small"
    />
  );
};

export default StateChip;
