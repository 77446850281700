import { Profile } from "./Profile";
import { supabase } from "./supabaseClient";

export type PermissionName = 
"GOD_MODE" | 
"CHECK_INVENTORY" |
"LEAD_TIMES" |
"LEAD_TIMES_CONFIG" |
"MODIFY_PERMISSIONS" |
"TOOL_COMMISSION_CHECKER" |
"TOOL_HLS_XML" |
"TOOL_PROG_PRICE_DESC" |
"TOOL_PROG_PRICE_DESC_CONFIG" |
"TOOL_TAPETOOL" |
"TOOL_TAPETOOL_CONFIG" |
"EASY_SPEC_TOOL" |
"EASY_SPEC_CONFIG"|
"PRODUCT_CONFIG";

export const Permissions = {
  GOD_MODE: "GOD_MODE",
  CHECK_INVENTORY: "CHECK_INVENTORY",
  LEAD_TIMES: "LEAD_TIMES",
  LEAD_TIMES_CONFIG: "LEAD_TIMES_CONFIG",
  MODIFY_PERMISSIONS: "MODIFY_PERMISSIONS",
  TOOL_COMMISSION_CHECKER: "TOOL_COMMISSION_CHECKER",
  TOOL_HLS_XML: "TOOL_HLS_XML",
  TOOL_PROG_PRICE_DESC: "TOOL_PROG_PRICE_DESC",
  TOOL_PROG_PRICE_DESC_CONFIG: "TOOL_PROG_PRICE_DESC_CONFIG",
  TOOL_TAPETOOL: "TOOL_TAPETOOL",
  TOOL_TAPETOOL_CONFIG: "TOOL_TAPETOOL_CONFIG",
  EASY_SPEC_CONFIG: "EASY_SPEC_CONFIG",
  PRODUCT_CONFIG: "PRODUCT_CONFIG",
}

export async function queryPermissions(userId: string): Promise<string[]>{
  const { data, error } = await supabase.from("user_permissions").select("*").eq("user_id", userId).order("user_id", { ascending: true });
  if(!data){
    console.error(error);
    return [];
  }
  const permissions = data.map((d) => d.permission_name ?? "");
  return permissions;
}

export const hasPermission = (profile: Profile, permission: PermissionName ): boolean => {
  const hasGodMode = profile.permissions.includes("GOD_MODE");
  return hasGodMode || profile.permissions.includes(permission);
}