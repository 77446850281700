import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { supabase_products } from '../../../../../database/supabaseClient';
import { LedTapeRecord } from '../../../../../database/Products';
import { ClickableTextCell, HeadCell, StyledTableRowHover, TextCell } from '../../components/table';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { TextField } from '../../components/TextField';
import AddProduct from '../../components/AddProduct';

type LedTapeListProps = {
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const LedTapeList = ({ displayAlert }: LedTapeListProps) => {
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [data, setData] = useState<Partial<LedTapeRecord>[] | null>(null);
  const [filter, setFilter] = useState<string>('');

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products.from('led_tape')
    .select('slug, active, family_id, name, led_type, location, input_voltage, conductors')
    .order('family_id', { ascending: true })
    .order('led_type', { ascending: true })
    .order('name', { ascending: true })
    .order('location', { ascending: true });
    if(error) console.error(error);
    setData(data ?? []);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const filteredData = data?.filter(d => d.name?.toLowerCase()?.includes(filter.toLowerCase()));

  return (
    <>
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Box>{/* Filters */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}>
          <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField 
            id="filter-input" 
            label="Search Name"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>{/* Add Product */}
        <Button 
          startIcon={<AddOutlinedIcon />} 
          color='success' 
          variant='contained' 
          onClick={() => setAddProduct(true)}
        >
          Add Product
        </Button>
      </Box>
    </Box>
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <HeadCell>Active</HeadCell>
            <HeadCell>Name</HeadCell>
            <HeadCell>Type</HeadCell>
            <HeadCell>Location</HeadCell>
            <HeadCell>Input Voltage</HeadCell>
            <HeadCell>Conductors</HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredData?.map((d, i) => (
              <StyledTableRowHover key={i}>
                <TableCell>
                  {
                    d.active ?
                    <Chip label="Active" color='success' />
                    :
                    <Chip label="Inactive" color='error' />
                  }
                </TableCell>
                <ClickableTextCell clickTo={`led-tape/${d.slug}`}>{d.name}</ClickableTextCell>
                <TextCell>{d.led_type}</TextCell>
                <TextCell>{d.location}</TextCell>
                <TextCell>{d.input_voltage}</TextCell>
                <TextCell>{d.conductors}</TextCell>
              </StyledTableRowHover>
            ))
          }
        {
          !data &&
          <StyledTableRowHover>
            <TableCell sx={{ border: 'none' }}>
              <CircularProgress />
            </TableCell>
          </StyledTableRowHover>
        }
        </TableBody>
      </Table>
    </TableContainer>
    {
      addProduct &&
      <AddProduct 
        productType='LED Tape' 
        onClose={() => setAddProduct(false)}
        displayAlert={displayAlert}
      />
    }
    </>
  );
};

export default LedTapeList;