import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Container, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Cell, CodeCell, HeadCell } from "./components/table";
import Header from "../../../components/Header";
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import { decodeEasySpec, findMatchingEzChannelAssembly, findMatchingEzLedTape, generateSlugValuePairsForEzLedTape, getNonNullEzBom, setCctCode, setFinishCode, setLensCode, setLocationCode, setLumensCode, setMountingCode, setTapeCode } from "./utils";
import { EzBom, EzChannelAssembly, EzLedTape } from "./types";
import { GetAllEzChannelAssemblies, GetAllEzLedTape } from "./data";
import { BuildChannelAssemblyFromEzSpecCodes, BuildLedTapeFromOptionCodes, ChannelAssembly, ChannelAssemblyOptionType, GetLedTapePartNumber, LedTape, LedTapeOptionType, LedTapeOptionValuePair, getEasySpecSheetUrl, selectEasySpecSheets } from "../../../database/Products";
import { In2Mm, Mm2Ft, Mm2In, Mm2M } from "../../../global_functions/UomConversion";
import { CalculateRuns, CutDistribution, ForceRounding, LedTapeRun } from "../../../global_functions/RunCalculations";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import LedTapeSpecs from "./components/LedTapeSpecs";
import ChannelAssemblySpecs from "./components/ChannelAssemblySpecs";
import Description from "./components/Description";
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';

type EzRunLineInput = {
  inputQty: number; 
  inputFeet: number; 
  inputInches: number;
};

const EasySpec = () => {
  
  const [ledTapeData, setLedTapeData] = useState<EzLedTape[]>([]);
  const [ezChannelAssembliesData, setEzChannelAssembliesData] = useState<EzChannelAssembly[]>([]);
  const [ezInput, setEzInput] = useState<string>('');
  const [rounding, setRounding] = useState<ForceRounding | 'Auto'>('Up');
  const [calcType, setCalcType] = useState<CutDistribution>('Balanced');
  const [ignoreMaxRunLen, setIgnoreMaxRunLen] = useState<boolean>(false);
  const [runInputs, setRunInputs] = useState<EzRunLineInput[]>([{inputQty: 1, inputFeet: 0, inputInches: 0}]);

  const queryData = useCallback(async () => {
    const [ezLedTapeDataResponse, ezChannelResponse] = await Promise.all([
      GetAllEzLedTape(),
      GetAllEzChannelAssemblies()
    ]);
    setLedTapeData(ezLedTapeDataResponse);
    setEzChannelAssembliesData(ezChannelResponse);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  useEffect(() => {
    const lastInputEl = runInputs[runInputs.length - 1];
    if(lastInputEl.inputQty > 0 && (lastInputEl.inputFeet !== 0 || lastInputEl.inputInches !== 0)){
      setRunInputs([...runInputs, {inputQty: 0, inputFeet: 0, inputInches: 0}]);
    }
  }, [runInputs]);

  const handleInputChange = (newValue: string) => {
    setEzInput(newValue);
  }
  
  const handleChangeQty = (index: number, newValue: string) => {
    const forceNumber = Number(newValue) >= 1 ? Number(newValue) : 1;
    const forceInt = ((forceNumber % 1) > 0)  ? Math.floor(forceNumber) : forceNumber;
    const newQty = !isNaN(forceInt) ? forceInt : 1;
    const inputs = [...runInputs];
    inputs[index].inputQty = newQty;
    setRunInputs(inputs);
  }

  const handleChangeLengthFt = (index: number, newValue: string) => {
    const forceNumber = Number(newValue) >= 0 ? Number(newValue) : 0;
    const newFeet = !isNaN(forceNumber) ? forceNumber : 1;
    const inputs = [...runInputs];
    inputs[index].inputFeet = newFeet;
    setRunInputs(inputs);
  }

  const handleChangeLengthIn = (index: number, newValue: string) => {
    const forceNumber = Number(newValue) >= 0 ? Number(newValue) : 0;
    const newInches = !isNaN(forceNumber) ? forceNumber : 1;
    const inputs = [...runInputs];
    inputs[index].inputInches = newInches;
    setRunInputs(inputs);
  }

  const handlePasteButtonClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      handleInputChange(text.toUpperCase());
    } catch (error) {
      console.error('Failed to read from clipboard: ', error);
    }
  }

  const handleCalcTypeChange = (event: SelectChangeEvent) => {
    const targetValue = event.target.value;
    switch(targetValue){
      case 'Balanced': {
        setCalcType('Balanced');
        break;
      }
      case 'Even': {
        setCalcType('Even');
        break;
      }
      default: {
        setCalcType('Maximized');
        break;
      }
    }
  }
  const handleRoundingChange = (event: SelectChangeEvent) => {
    const targetValue = event.target.value;
    if(targetValue === 'Up') setRounding('Up');
    if(targetValue === 'Down') setRounding('Down');
    if(targetValue === 'Auto') setRounding('Auto');
  }

  const ezBom: EzBom = decodeEasySpec(ezInput);
  let ezLedTape: EzLedTape | null = null;
  let ledTape: LedTape | null = null;
  let ezChannelAssembly: EzChannelAssembly | null = null;
  let channelAssembly: ChannelAssembly | null = null;
  
  const allCodesParsed: boolean = Object.values(ezBom).every((value) => value !== null);
  if (allCodesParsed) {
    const ezBomComp = getNonNullEzBom(ezBom);
    ezLedTape = findMatchingEzLedTape(ezBomComp, ledTapeData);
    if (ezLedTape) {
      const slugValuePairsForLedTape: LedTapeOptionValuePair[] = generateSlugValuePairsForEzLedTape(ezLedTape, ezBomComp);
      const optionCodes = {
        sdcmValue: slugValuePairsForLedTape.find(svp => svp.slug === 'SDCM')?.value ?? '',
        kelvinTempValue: slugValuePairsForLedTape.find(svp => svp.slug === 'Kelvin Temp')?.value ?? '',
        lumensValue: slugValuePairsForLedTape.find(svp => svp.slug === 'Lumens')?.value ?? '',
        colorValue: slugValuePairsForLedTape.find(svp => svp.slug === 'Color')?.value ?? ''
      }
      ledTape = BuildLedTapeFromOptionCodes(ezLedTape.product, optionCodes, true);
    }
  
    ezChannelAssembly = findMatchingEzChannelAssembly(ezBomComp, ezChannelAssembliesData);
    if (ezChannelAssembly) {
      const channelCodes = { lens: ezBomComp.lens.code, mounting: ezBomComp.mounting.code, finish: ezBomComp.finish.code };
      channelAssembly = BuildChannelAssemblyFromEzSpecCodes(ezChannelAssembly.product, channelCodes);
    }
  }
  const ledTapePartNumber = ledTape ? GetLedTapePartNumber(ledTape) : '';

  const totalsRequested = runInputs.map(inputs => {
    const totalReqFeet = Number((inputs.inputFeet + (inputs.inputInches / 12)).toFixed(2));
    const totalReqInches = Number(((inputs.inputFeet * 12) + inputs.inputInches).toFixed(2));
    const totalReqMm = In2Mm(totalReqInches);
    return {
      qty: inputs.inputQty,
      totalReqFeet: totalReqFeet, 
      totalReqInches: totalReqInches, 
      totalReqMm: totalReqMm
    };
  })
  
  const lines: (LedTapeRun & { quantity: number })[] = ledTape ? totalsRequested.map(req => {
    return {
      ...CalculateRuns(ledTape as LedTape, req.totalReqMm, calcType, rounding === 'Auto' ? undefined : rounding, ignoreMaxRunLen), 
      quantity: req.qty
    };
  }) : [];

  const totalLengthFeet = lines.reduce((acc, curVal) => {return acc + (Mm2Ft(curVal.totalLengthMm ?? 0) * curVal.quantity)}, 0);
  const channelRequired = lines.reduce((acc, curVal) => {return acc + (Math.ceil(Mm2M(curVal.totalLengthMm ?? 0) / 2) * curVal.quantity)}, 0);

  const handleDownloadEasySpecSheet = () => {
    if(!ezLedTape || !ezChannelAssembly) return;
    selectEasySpecSheets(ezLedTape.id, ezChannelAssembly.id).then(data => {
      if(data.length <= 0) return;
      const record = data[0];
      if(!record || !record.spec_sheet || record.spec_sheet === '') return;
      getEasySpecSheetUrl(record.spec_sheet).then(url => {
        if(!url) return;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = url.slice(url.lastIndexOf('.'));
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }); 
    });
  }

  const handleChangeLedTapeOption = (option: LedTapeOptionType, ezCode: string) => {
    let input = ezInput.toUpperCase();
    let removedStartingInput = '';
    if(input.startsWith("EZ")){
      input = input.slice(2);
      removedStartingInput = 'EZ';
    }
    if(input.startsWith("-")){
      input = input.slice(1);
      removedStartingInput += '-';
    }
    let ezArr = input.split("-");
    if(!ezLedTape) return;
    const optionStr = `{${option}}`;
    if(ezLedTape.series_formula.includes(optionStr)){
      const newSeriesCode =  ezLedTape.series_formula.replace(optionStr, ezCode);
      ezArr = setTapeCode(ezArr, newSeriesCode);
    }
    if(ezLedTape.location_formula.includes(optionStr)){
      const newLocationCode = ezLedTape.location_formula.replace(optionStr, ezCode);
      ezArr = setLocationCode(ezArr, newLocationCode);
    }
    if(ezLedTape.cct_formula.includes(optionStr)){
      const newCctCode = ezLedTape.cct_formula.replace(optionStr, ezCode);
      ezArr = setCctCode(ezArr, newCctCode);
    }
    if(ezLedTape.lumen_formula.includes(optionStr)){
      const newLumensCode = ezLedTape.lumen_formula.replace(optionStr, ezCode);
      ezArr = setLumensCode(ezArr, newLumensCode);
    }
    setEzInput(removedStartingInput + ezArr.join('-'));
  }

  const handleChangeChannelAssemblyOption = (option: ChannelAssemblyOptionType, ezCode: string) => {
    let input = ezInput.toUpperCase();
    let removedStartingInput = '';
    if(input.startsWith("EZ")){
      input = input.slice(2);
      removedStartingInput = 'EZ';
    }
    if(input.startsWith("-")){
      input = input.slice(1);
      removedStartingInput += '-';
    }
    let ezArr = input.split("-");
    if(!ezChannelAssembly) return;
    const optionStr = `{${option}}`;
    if(ezChannelAssembly.lens_formula.includes(optionStr)){
      const newLensCode =  ezChannelAssembly.lens_formula.replace(optionStr, ezCode);
      ezArr = setLensCode(ezArr, newLensCode);
    }
    if(ezChannelAssembly.mounting_formula.includes(optionStr)){
      const newMountingCode = ezChannelAssembly.mounting_formula.replace(optionStr, ezCode);
      ezArr = setMountingCode(ezArr, newMountingCode);
    }
    if(ezChannelAssembly.finish_formula.includes(optionStr)){
      const newFinishCode = ezChannelAssembly.finish_formula.replace(optionStr, ezCode);
      ezArr = setFinishCode(ezArr, newFinishCode);
    }
    setEzInput(removedStartingInput + ezArr.join('-'));
  }

  const channelAssemblyLensQbItems = channelAssembly ? channelAssembly.lens.qb_items.filter(QbItem => {
    if(QbItem.dependencies){
      const { mountings, finishes } = QbItem.dependencies;
      if(mountings.length > 0 && !mountings.some(m => m === channelAssembly?.mounting.id)) return false;
      if(finishes.length > 0 && !finishes.some(f => f === channelAssembly?.finish.id)) return false;
    }
    if(QbItem.conflicts){
      const { mountings, finishes } = QbItem.conflicts;
      if(mountings.length > 0 && mountings.some(m => m === channelAssembly?.mounting.id)) return false;
      if(finishes.length > 0 && finishes.some(f => f === channelAssembly?.finish.id)) return false;
    }
    return true;
   }) : null;
  const channelAssemblyMountingQbItems = channelAssembly ? channelAssembly.mounting.qb_items.filter(QbItem => {
    if(QbItem.dependencies){
      const { lenses, finishes } = QbItem.dependencies;
      if(lenses.length > 0 && !lenses.some(l => l === channelAssembly?.lens.id)) return false;
      if(finishes.length > 0 && !finishes.some(f => f === channelAssembly?.finish.id)) return false;
    }
    if(QbItem.conflicts){
      const { lenses, finishes } = QbItem.conflicts;
      if(lenses.length > 0 && lenses.some(l => l === channelAssembly?.lens.id)) return false;
      if(finishes.length > 0 && finishes.some(f => f === channelAssembly?.finish.id)) return false;
    }
    return true;
   }) : null;
  const channelAssemblyFinishQbItems = channelAssembly ? channelAssembly.finish.qb_items.filter(QbItem => {
    if(QbItem.dependencies){
      const { mountings, lenses } = QbItem.dependencies;
      if(mountings.length > 0 && !mountings.some(m => m === channelAssembly?.mounting.id)) return false;
      if(lenses.length > 0 && !lenses.some(l => l === channelAssembly?.lens.id)) return false;
    }
    if(QbItem.conflicts){
      const { mountings, lenses } = QbItem.conflicts;
      if(mountings.length > 0 && mountings.some(m => m === channelAssembly?.mounting.id)) return false;
      if(lenses.length > 0 && lenses.some(l => l === channelAssembly?.lens.id)) return false;
    }
    return true;
   }) : null;

  const ledTapeOptionConflicts: { valueId: number, reason: string }[] = [];
  if(ledTape?.lumens?.conflicts) 
    ledTapeOptionConflicts.push(
      ...ledTape.lumens.conflicts.map(c => ({
        valueId: c.option_value_id_2, 
        reason: `Not compatible with Lumens: ${ledTape?.lumens?.display_name ?? ''}`
      }))
    );
  if(ledTape?.kelvinTemp?.conflicts) 
    ledTapeOptionConflicts.push(
      ...ledTape.kelvinTemp.conflicts.map(c => ({
        valueId: c.option_value_id_2, 
        reason: `Not compatible with Kelvin Temp: ${ledTape?.kelvinTemp?.display_name ?? ''}`
      }))
    );
  if(ledTape?.sdcm?.conflicts) 
    ledTapeOptionConflicts.push(
      ...ledTape.sdcm.conflicts.map(c => ({
        valueId: c.option_value_id_2, 
        reason: `Not compatible with SDCM: ${ledTape?.sdcm?.display_name ?? ''}`
      }))
    );
  if(ledTape?.color?.conflicts) 
    ledTapeOptionConflicts.push(
      ...ledTape.color.conflicts.map(c => ({
        valueId: c.option_value_id_2, 
        reason: `Not compatible with Color: ${ledTape?.color?.display_name ?? ''}`
      }))
    );

  const currentConfigConflictWarning: string[] = [];
  if(ledTape != null){
    ledTapeOptionConflicts.forEach(optConflict => {
      if(ledTape?.sdcm?.id === optConflict.valueId)
        currentConfigConflictWarning.push(`Selected SDCM: ${ledTape?.sdcm.display_name} - ${optConflict.reason}`);
      if(ledTape?.lumens?.id === optConflict.valueId)
        currentConfigConflictWarning.push(`Selected Lumens: ${ledTape?.lumens.display_name} - ${optConflict.reason}`);
      if(ledTape?.kelvinTemp?.id === optConflict.valueId)
        currentConfigConflictWarning.push(`Selected Kelvin Temp: ${ledTape?.kelvinTemp.display_name} - ${optConflict.reason}`);
      if(ledTape?.color?.id === optConflict.valueId)
        currentConfigConflictWarning.push(`Selected Color: ${ledTape?.color.display_name} - ${optConflict.reason}`);
    });
  }

  return (
    <Box m="20px">
      <Header
        title="EASY SPEC"
        subtitle="A tool for deciphering Easy Spec part numbers and generating a detailed description."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Box display="flex" alignItems='flex-end' gap={2}>
          <TextField 
            color="info" 
            label="Easy Spec Part Number"
            value={ezInput}
            onChange={(e) => handleInputChange(e.target.value)}
            sx={{ minWidth: "400px" }}
            inputProps={{style: { textTransform: 'uppercase'}}}
          />
          <Button 
            variant="outlined" 
            color="info" 
            startIcon={<ContentPasteGoOutlinedIcon sx={{ transform: 'scaleX(-1)'}} />}
            onClick={handlePasteButtonClick}
          >
            Paste
          </Button>
        </Box>
        
        <Box display="flex" flexDirection="column" gap={2} my={2}>
          <Table size="small" sx={{ maxWidth: "250px"}}>
            <TableBody>
              <TableRow>
                <HeadCell>Channel</HeadCell>
                <HeadCell>Location</HeadCell>
                <HeadCell>Tape</HeadCell>
                <HeadCell>CCT</HeadCell>
                <HeadCell>Lumens</HeadCell>
                <HeadCell>Lens</HeadCell>
                <HeadCell>Mounting</HeadCell>
                <HeadCell>Finish</HeadCell>
              </TableRow>
              <TableRow>
                <CodeCell red={!ezBom.channel && !(ezInput === '')}>
                  {ezBom.channel?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.location && !(ezInput === '')}>
                  {ezBom.location?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.tape && !(ezInput === '')}>
                  {ezBom.tape?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.cct && !(ezInput === '')}>
                  {ezBom.cct?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.lumens && !(ezInput === '')}>
                  {ezBom.lumens?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.lens && !(ezInput === '')}>
                  {ezBom.lens?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.mounting && !(ezInput === '')}>
                  {ezBom.mounting?.code ?? '-'}
                </CodeCell>
                <CodeCell red={!ezBom.finish && !(ezInput === '')}>
                  {ezBom.finish?.code ?? '-'}
                </CodeCell>
              </TableRow>
            </TableBody>
          </Table>
          {
            currentConfigConflictWarning.length > 0 &&
            <Box>
              <Typography color='red' fontWeight='bold'>Warning!</Typography>
              <ul>
                {
                  currentConfigConflictWarning.map(warning => (
                    <li key={warning}>
                      <Typography color='red' fontWeight='bold'>{warning}</Typography>
                    </li>
                  ))
                }
              </ul>
            </Box>
          }
          {
            ledTape &&
            <Box display="flex" alignItems="flex-start" gap={2} mt={2}>
              <Box>
                <Box display="flex" alignItems="flex-start" gap={2}>
                  <FormControl variant="outlined">
                    <InputLabel id="calc-type-label">Rounding</InputLabel>
                    <Select
                      label="Rounding"
                      labelId="calc-type-label"
                      value={rounding}
                      onChange={handleRoundingChange}
                      color="info"
                      size="small"
                    >
                      <MenuItem value='Up'>
                        <Box display='flex' gap={1}><ArrowUpwardOutlinedIcon /> Up</Box>
                      </MenuItem>
                      <MenuItem value='Down'>
                        <Box display='flex' gap={1}><ArrowDownwardOutlinedIcon /> Down</Box>
                      </MenuItem>
                      <MenuItem value='Auto'>
                        <Box display='flex' gap={1}><AutoAwesomeOutlinedIcon /> Auto</Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel id="calc-type-label">Distribution</InputLabel>
                    <Select
                      label="Distribution"
                      labelId="calc-type-label"
                      value={calcType}
                      onChange={handleCalcTypeChange}
                      color="info"
                      size="small"
                    >
                      <MenuItem value='Balanced'>
                        <Box display='flex' gap={1}><BalanceOutlinedIcon /> Balanced</Box>
                      </MenuItem>
                      <MenuItem value='Even'>
                        <Box display='flex' gap={1}><CalendarViewWeekOutlinedIcon /> Even</Box>
                      </MenuItem>
                      <MenuItem value='Maximized'>
                        <Box display='flex' gap={1}><ViewQuiltOutlinedIcon /> Maximized</Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <Switch
                    checked={ignoreMaxRunLen}
                    onChange={(e) => setIgnoreMaxRunLen(e.target.checked)}
                  />
                  Ignore Max Run Length
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                {
                  runInputs.map((inputs, index) => (
                    <Box key={'inputsLine' + index} display='flex' flexDirection='row' gap={2}>
                      <TextField 
                        color="info"
                        label="Quantity"
                        type="number"
                        value={inputs.inputQty > 0 ? inputs.inputQty.toString() : ''}
                        onChange={(e) => handleChangeQty(index, e.target.value)}
                        sx={{ maxWidth: "75px" }}
                      />
                      <TextField  
                        color="info" 
                        label="Feet"
                        type="number"
                        value={inputs.inputFeet > 0 ? inputs.inputFeet.toString() : ''}
                        onChange={(e) => handleChangeLengthFt(index, e.target.value)}
                        sx={{ maxWidth: "75px" }}
                      />
                      <TextField 
                        color="info" 
                        label="Inches"
                        type="number"
                        value={inputs.inputInches > 0 ? inputs.inputInches.toString() : ''}
                        onChange={(e) => handleChangeLengthIn(index, e.target.value)}
                        sx={{ maxWidth: "75px" }}
                      />
                      {
                        totalsRequested[index].totalReqFeet > 0 &&
                        <Box>
                          <Typography fontWeight="bold" fontSize={16}
                            sx={{
                              color: ignoreMaxRunLen && totalsRequested[index].totalReqFeet > (ledTape?.specs.max_run_ft ?? 0) ? 'red' : ''
                            }}
                          >
                            {` = ${totalsRequested[index].totalReqFeet}' (${totalsRequested[index].totalReqInches}")`}
                          </Typography>
                          <Typography
                            sx={{
                              color: ignoreMaxRunLen && totalsRequested[index].totalReqFeet > (ledTape?.specs.max_run_ft ?? 0) ? 'red' : ''
                            }}
                          >
                            Delta: 
                            {(Mm2Ft(lines[index].totalLengthMm) - totalsRequested[index].totalReqFeet) > 0 ? ' +' : ' '}
                            {`${(Mm2Ft(lines[index].totalLengthMm) - totalsRequested[index].totalReqFeet).toFixed(2)}' (${(Mm2In(lines[index].totalLengthMm) - totalsRequested[index].totalReqInches).toFixed(2)}")`}
                          </Typography>
                        </Box>
                      }
                    </Box>
                  ))
                }   
              </Box>
            </Box>
          }
          <Box display="flex" flexDirection='row' alignItems='flex-start'>
            {
              ezInput !== '' &&
              <Box width='100%'>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <HeadCell>Quantity</HeadCell>
                      <HeadCell>UoM</HeadCell>
                      <HeadCell>Item</HeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100px"><Typography>1</Typography></TableCell>
                      <TableCell width="100px"><Typography>Each</Typography></TableCell>
                      <TableCell><Typography>{ezInput.toUpperCase()}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      {
                        ezLedTape !== null ?
                        <>
                        <TableCell width="100px"><Typography>{lines.reduce((acc, curVal) => {return acc + (curVal.strips.length * curVal.quantity)}, 0)}</Typography></TableCell>
                        <TableCell width="100px"><Typography>Each</Typography></TableCell>
                        <Cell minWidth="250px" pl="15px">{`FML-${ezLedTape.product.conductors ?? 2}C-6`}</Cell>
                        </>
                        :
                        <>
                        <Cell red={true}>-</Cell>
                        <Cell red={true}>-</Cell>
                        <Cell red={true} pl="15px">-</Cell>
                        </>
                      }
                    </TableRow>
                    <TableRow>
                      {
                        ledTapePartNumber !== '' ?
                        <>
                        <TableCell width="100px"><Typography>{isNaN(totalLengthFeet) ? '-' : totalLengthFeet.toFixed(2)}</Typography></TableCell>
                        <TableCell width="100px"><Typography>Ft</Typography></TableCell>
                        <Cell minWidth="250px" pl="15px">{ledTapePartNumber}</Cell>
                        </>
                        :
                        <>
                        <Cell red={true}>-</Cell>
                        <Cell red={true}>-</Cell>
                        <Cell red={true} pl="15px">-</Cell>
                        </>
                      }
                    </TableRow>
                    {
                      channelAssembly?.channel.qb_items.map(item => (
                        <TableRow key={'channel' + (item.list_id ?? '')}>
                          <TableCell width="100px"><Typography>{item.quantity * channelRequired}</Typography></TableCell>
                          <TableCell width="100px"><Typography>Each</Typography></TableCell>
                          <Cell minWidth="250px" pl="15px">{item.full_name ?? ''}</Cell>
                        </TableRow>
                      ))
                    }
                    {
                      channelAssemblyLensQbItems?.map(item => (
                        <TableRow key={'lens' + (item.list_id ?? '')}>
                          <TableCell width="100px"><Typography>{(item.quantity * channelRequired).toFixed(2)}</Typography></TableCell>
                          <TableCell width="100px"><Typography>Each</Typography></TableCell>
                          <Cell minWidth="250px" pl="15px">{item.full_name ?? ''}</Cell>
                        </TableRow>
                      ))
                    }
                    {
                      channelAssemblyMountingQbItems?.map(item => (
                        <TableRow key={'mounting' + (item.list_id ?? '')}>
                          <TableCell width="100px"><Typography>{item.quantity * channelRequired}</Typography></TableCell>
                          <TableCell width="100px"><Typography>Each</Typography></TableCell>
                          <Cell minWidth="250px" pl="15px">{item.full_name ?? ''}</Cell>
                        </TableRow>
                      ))
                    }
                    {
                      channelAssemblyFinishQbItems?.map(item => (
                        <TableRow key={'finish' + (item.list_id ?? '')}>
                          <TableCell width="100px"><Typography>{item.quantity * channelRequired}</Typography></TableCell>
                          <TableCell width="100px"><Typography>Each</Typography></TableCell>
                          <Cell minWidth="250px" pl="15px">{item.full_name ?? ''}</Cell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </Box>
            
            }
            <Box width='100%'></Box> {/* Adds blank space */}
          </Box>
          <Box width='100%'>
            {
              ledTape && channelAssembly &&
              <Description 
                ledTape={ledTape} 
                channelAssembly={channelAssembly}
                channelRequired={!isNaN(channelRequired) ? channelRequired : 0} 
                lines={lines.filter(line => line.requestedLengthMM > 0)}
                ezBom={ezBom}
                ledTapePartNumber={ledTapePartNumber}
              />
            }
          </Box>
        </Box>
        
        {
          ezLedTape && ezChannelAssembly &&
          <Button 
            color="info" 
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleDownloadEasySpecSheet}
          >
            Download Easy Spec Sheet
          </Button>
        }
        <Box display="flex" flexDirection="row" gap={4} alignItems='flex-start' my={2}>
          {
            ezLedTape && ledTape &&
            <LedTapeSpecs 
              ledTape={ledTape}
              conflicts={ledTapeOptionConflicts}
              options={ezLedTape.product.options}
              productImage={ezLedTape.product_image ?? ''}
              altText={ezLedTape.product_image_alt_text ?? ''}
              onChangeOption={handleChangeLedTapeOption}
            />
          }
          {
            ezChannelAssembly && channelAssembly &&
            <ChannelAssemblySpecs 
              channelAssembly={channelAssembly}
              options={ezChannelAssembly.product.options}
              profileImage={ezChannelAssembly.profile_image ?? ''}
              altText={ezChannelAssembly.profile_image_alt_text ?? ''}
              onChangeOption={handleChangeChannelAssemblyOption}
            />
          }
        </Box>
      </Container>
    </Box>
  );
};

export default EasySpec;