import React from "react";
import { TextFieldProps, useTheme } from "@mui/material";
import StyledTextField from "../../../../components/StyledTextField";

type Props = TextFieldProps & {
  updated?: boolean;
  minWidth?: string;
}

type TextFieldSx = { 
  minWidth?: string;
  '& .MuiInputBase-input': {
    backgroundColor?: string;
  }
}

export const TextField = (props: Props) => {
  const colors = useTheme().colors;
  const { updated, minWidth, ...rest } = props;
  const styles: TextFieldSx = { '& .MuiInputBase-input': { } };
  if(minWidth) styles.minWidth = minWidth;
  if(updated) styles["& .MuiInputBase-input"].backgroundColor = colors.updatedValue.yellow;
  return <StyledTextField {...rest} sx={styles} />
}

export const SelectField = (props: Props) => {
  return <TextField select {...props} />
}