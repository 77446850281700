import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Box, Divider, Snackbar, Tab, Tabs } from "@mui/material";
import { default as TabPanel } from "../../../../../components/StyledTabPanel";
import LedTapeList from "./LedTapeList";
import ChannelAssembliesList from "./ChannelAssembliesList";
import ChannelList from "./ChannelList";
import { AlertUi } from "../../types";

const MainView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const prodTypeParam = searchParams.get('type') ?? '';
  const prodTypes = ['led-tape', 'channel-assemblies', 'channel'];
  const tabIndexLookup = prodTypes.findIndex(type => type === prodTypeParam);
  const initialTabValue = tabIndexLookup >= 0 ? tabIndexLookup : 0;

  const [tabValue, setTabValue] = useState<number>(initialTabValue);
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setSearchParams({ type: prodTypes[newValue] });
  };

  const handleCloseAlert = () => {
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplyAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={changeTab}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="LED Tape" value={0} />
        <Tab label="Channel Assemblies" value={1} />
        <Tab label="Channel" value={2} />
      </Tabs>
      <Divider />
      <TabPanel value={tabValue} index={0}>
        <LedTapeList displayAlert={handleDisplyAlert} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ChannelAssembliesList displayAlert={handleDisplyAlert} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ChannelList displayAlert={handleDisplyAlert} />
      </TabPanel>
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MainView;
